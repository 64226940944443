
import { mapActions, mapGetters } from 'vuex';
import PortfolioIcon from '~/components/ui/icons/PortfolioIcon.vue';
import AddIcon from '~/components/ui/icons/AddIcon.vue';
import ChevronIcon from '~/components/ui/icons/ChevronIcon.vue';
import PortfolioInfo from '@/components/ui/molecule/PortfolioInfo';
import { _maxPortfolioLimit } from '@/utils/portfolio-settings';
import {
  canCreatePortfolio,
  cannotCreatePortfolioReason,
} from '~/utils/behavior/portfolio-creation';
import { inAppRedirect } from '~/utils/route-redirects';

export default {
  name: 'HeaderPortfolios',
  components: { PortfolioIcon, AddIcon, PortfolioInfo, ChevronIcon },
  fetchOnServer: false,
  data() {
    return {
      showPortfolioDropdownList: false,
      portfoliosInDropdown: [],
    };
  },
  computed: {
    ...mapGetters('portfolio', [
      'getMyPortfoliosRes',
      'myOwnedPortfolios',
      'mySharedPortfolios',
    ]),
    ...mapGetters('authentication', ['isEmailConfirmed']),
    maxPortfolioLimit() {
      return _maxPortfolioLimit;
    },
    canCreatePortfolio() {
      return canCreatePortfolio(
        this.isEmailConfirmed,
        this.myOwnedPortfolios.length
      );
    },
    cannotCreatePortfolioReason() {
      return cannotCreatePortfolioReason(
        this.isEmailConfirmed,
        this.myOwnedPortfolios.length
      );
    },
  },
  methods: {
    ...mapActions('modals', ['showCreatePortfolioModal']),
    getDropdownListStyle() {
      return 'width: fit-content; top:55px;';
    },
    handleNavigateToPortfolio(portfolioId) {
      inAppRedirect(`/portfolios/${portfolioId}`);
    },
    async handlePortfolioDropdownHover() {
      if (!this.showPortfolioDropdownList) {
        this.portfoliosInDropdown = [];
        this.showPortfolioDropdownList = true;

        if (this.getMyPortfoliosRes?.data?.portfolios)
          for (const portfolio of [
            ...this.myOwnedPortfolios,
            ...this.mySharedPortfolios,
          ]) {
            this.portfoliosInDropdown.push(portfolio);
            await new Promise((r) => setTimeout(r, 20));
          }
      }
    },
    handleAddPortfolio() {
      this.showCreatePortfolioModal();
    },
  },
};
