// This file should have all the environment variables accessible through
// exported constants, so that the code isn't polluted by process.env and eslint ignore directives.

/**
 * Throw an error describing that an environment variable was not set.
 * @param envionrmentVariable The environment variable (name).
 * @returns {never}
 */
const throwUnsetEnvironmentVariableError = (
  envionrmentVariable: string
): never => {
  throw new Error(`${envionrmentVariable} is not set.`);
};

/**
 * The URL to the OIDC server/authority used for auth.
 */
export const OIDCHost =
  process.env.NUXT_ENV_API_OIDC ??
  throwUnsetEnvironmentVariableError('NUXT_ENV_API_OIDC');

/**
 * The OIDC client ID used for auth.
 */
export const OIDCClient =
  process.env.NUXT_ENV_OIDC_CLIENT ??
  throwUnsetEnvironmentVariableError('NUXT_ENV_OIDC_CLIENT');

/**
 * The OIDC redirect specific to the OIDC client.
 */
export const OIDCRedirect =
  process.env.NUXT_ENV_OIDC_REDIRECT ??
  throwUnsetEnvironmentVariableError('NUXT_ENV_OIDC_REDIRECT ');

/**
 * The OIDC scope(s) specific to the OIDC client.
 */
export const OIDCScope =
  process.env.NUXT_ENV_OIDC_SCOPE ??
  throwUnsetEnvironmentVariableError('NUXT_ENV_OIDC_SCOPE');

/**
 * The URL to redirect anonymous users to.
 */
export const AnonymousRedirectURL =
  process.env.NUXT_ENV_INDEX_PAGE_REDIRECT ??
  throwUnsetEnvironmentVariableError('NUXT_ENV_INDEX_PAGE_REDIRECT');

/**
 * The URL to redirect the user to after logout is complete.
 */
export const PostLogoutRedirectURL =
  process.env.NUXT_ENV_POST_LOGOUT_REDIRECT_URL ??
  throwUnsetEnvironmentVariableError('NUXT_ENV_POST_LOGOUT_REDIRECT_URL');
