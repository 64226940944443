import { EPromiseState } from '~/store/types';
import { MutationTree } from 'vuex';
import { SearchHistory } from '~/types/api/search';
import { SearchResult } from '~/types/models/search';
import { SearchState } from './types';

export default {
  SET_SEARCH_POPUP_SHOWN(state: SearchState, shown: boolean) {
    state.searchPopupShown = shown;
  },
  SET_SHOW_SEARCH_HISTORY(state: SearchState, shown: boolean) {
    state.showSearchHistory = shown;
  },
  RESET_USER_SEARCH_RESULTS(state: SearchState): void {
    state.showSearchHistory = true;
    state.query = '';
    state.userSearch = {
      data: null,
      error: null,
      state: EPromiseState.None,
    };
  },
  SEARCH_PUSH_SEARCH_FOR_QUERY_BEGIN(
    state: SearchState,
    { query }: { query: string }
  ): void {
    state.query = query;
    state.userSearch = {
      ...state.userSearch,
      state: EPromiseState.Loading,
    };
  },
  SEARCH_PUSH_RESULT_FOR_QUERY(
    state: SearchState,
    { query, result }: { query: string; result: SearchResult }
  ) {
    state.query = query;
    state.userSearch = {
      ...state.userSearch,
      data: result,
      state: EPromiseState.Done,
      error: null,
    };
    state.showSearchHistory = false;
  },
  SEARCH_ERROR_FOR_QUERY(
    state: SearchState,
    { query, error }: { query: string; error: object }
  ) {
    state.query = query;
    state.userSearch = {
      ...state.userSearch,
      data: null,
      state: EPromiseState.Error,
      error,
    };
  },

  /** Overrides the current user history, setting a new one. */
  SEARCH_HISTORY_SET(state: SearchState, history: SearchHistory) {
    state.searchHistory = {
      data: history,
      state: EPromiseState.Done,
      error: null,
    };
  },

  /** Sets an error related to search history. */
  SEARCH_HISTORY_ERROR(state: SearchState, error: object) {
    state.searchHistory = { data: [], state: EPromiseState.Error, error };
  },

  /**
   * @deprecated Not typesafe and uses the deprecated `search` state property.
   * @see search.state.search
   * @see SEARCH_PUSH_RESULT_FOR_QUERY
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  SET_SEARCH(state: SearchState, payload: any) {
    state.search.data = payload;
  },
  /**
   * @deprecated Not typesafe and uses the deprecated `search` state property.
   * @see search.state.search
   * @see SEARCH_ERROR_FOR_QUERY
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  SET_SEARCH_ERROR(state: SearchState, error: any) {
    console.error('Error in search', error);

    // @ts-ignore TODO
    window.$nuxt.$notify.error('Error in search');
    state.search.error = error;
  },
} as MutationTree<SearchState>;
