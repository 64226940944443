
import video from '@/utils/video';
import VideoPlayer from '@/components/ui/atom/VideoPlayer';

/**
 * A video component that can display both direct vidos (.mp4, .mov, ...) and YouTube videos
 */
export default {
  name: 'Video',
  components: {
    VideoPlayer,
  },
  props: {
    /**
     * The raw video source
     */
    src: {
      required: true,
      type: String,
    },
    /**
     * Additional classes to add to the video element
     */
    classNames: {
      type: String,
      default: '',
    },
  },
  computed: {
    /**
     * Whether to use an iframe for displaying the video or not
     * @returns {boolean}
     */
    useIFrame() {
      return video.isYoutubeVideo(this.src);
    },
    /**
     * The prepared video URL
     * @description
     * This is the video URL that is actually used in the iframe or video player.
     * It is the same as the raw "raw" URL, except for YouTube videos, where the `preparedSrc` is a YouTube embed URL.
     * @returns {string}
     */
    preparedUrl() {
      return video.isYoutubeVideo(this.src)
        ? video.getYoutubeEmbedUrl(this.src)
        : this.src;
    },
    /**
     * The video options to pass to the video player
     * @returns {Object}
     * @see https://videojs.com/guides/options/
     */
    videoOptions() {
      return {
        autoplay: false,
        controls: true,
        sources: [
          {
            src: this.src,
            type: video.getVideoMimeType(this.src),
          },
        ],
      };
    },
  },
};
