
import { inAppRedirect } from '~/utils/route-redirects';

export default {
  Name: 'UITrendingStockWidget.vue',
  props: {
    value: {
      type: Number,
      required: true,
    },
    // TODO make this required
    profileId: {
      type: String,
      default: '',
      required: false,
    },
    name: {
      type: String,
      required: true,
    },
    // TODO make this required
    companyAvatar: {
      type: String,
      default: '',
      required: false,
    },
    change: {
      type: Number,
      required: true,
    },
    currencySymbol: {
      type: String,
      required: true,
    },
    showImg: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    // TODO replace with utils/behavior/images.ts:2
    return { defaultUri: require('@/assets/img/unknow-company.jpg') };
  },
  methods: {
    inAppRedirect,
  },
};
