
import InfoFilledIcon from '~/components/ui/icons/InfoFilledIcon.vue';
export default {
  name: 'InfoIcon',
  components: {
    InfoFilledIcon,
  },
  props: {
    tooltip: {
      type: String,
      default: '',
    },
    iconSize: {
      type: String,
      default: '16',
    },
  },
  data() {
    return {};
  },
};
