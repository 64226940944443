
import UserImgInfo from '@/components/ui/molecule/UserImgInfo';
import SocialInteraction from '@/components/ui/molecule/SocialInteraction';
import ProfileTags from '@/components/ui/molecule/ProfileTags';
import IndustryTags from '@/components/ui/molecule/IndustryTags';
import { getMedia } from '@/utils/mixins';
import PostMore from '@/components/ui/molecule/PostMore';
import video from '@/utils/video';

export default {
  name: 'UINewsPost',
  components: {
    UserImgInfo,
    SocialInteraction,
    ProfileTags,
    IndustryTags,
    PostMore,
  },
  mixins: [getMedia],
  props: {
    post: {
      type: Object,
      default: () => null,
    },
    isPostPage: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
    isReply: {
      type: Boolean,
      default: false,
    },
    disableHover: {
      type: Boolean,
      default: false,
    },
    body: {
      type: String,
      default: '',
    },
    media: {
      type: Array,
      default: () => [],
    },
    separator: {
      type: Boolean,
      default: false,
    },
    showSocialInteraction: {
      type: Boolean,
      default: true,
    },
    isSocialInteractionDisabled: {
      type: Boolean,
      default: false,
    },
    showOptions: {
      type: Boolean,
      default: true,
    },
    showCard: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      htmlBody: '',
      mentionIds: [],
      mentionedPost: null,
      icoRegex: /([a-zA-Z0-9\s_\\.\-():])+(.ico)$/i,
      currentDisplayLanguage: this.$i18n.locale,
    };
  },
  computed: {
    fromNow() {
      const fromNow = this.$dayjs(this.post.createdDate)
        .subtract('1', 's')
        .startOf('s')
        .fromNow();
      return fromNow;
    },
    shouldShowTranslated() {
      return this.currentDisplayLanguage !== this.newsArticleDetail.language;
    },
    locale() {
      const locale = this.$i18n.locale;
      return locale;
    },
    newsArticleDetail() {
      return this.post.newsArticleDetail;
    },
    hasTranslation() {
      return Boolean(this.newsArticleDetail.translations[this.$i18n.locale]);
    },
    localeSpecificContent() {
      const translation =
        this.newsArticleDetail.translations[this.currentDisplayLanguage];

      // fallback to default language if translation is not available
      if (!translation)
        return {
          title: this.newsArticleDetail.title ?? '',
          description: this.newsArticleDetail.description ?? '',
          snippet: this.newsArticleDetail.snippet ?? '',
        };

      return {
        ...translation,
        description: translation.description ?? '',
        snippet: translation.snippet ?? '',
        title: translation.title ?? '',
      };
    },
  },
  mounted() {
    //
  },
  created() {
    this.buildBody();
  },
  methods: {
    getNewsArticleBody() {
      const { description, snippet } = this.localeSpecificContent ?? {
        title: '',
        description: '',
        snippet: '',
      };

      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const stringSimilarity = require('string-similarity');
      return snippet
        ? stringSimilarity.compareTwoStrings(description, snippet) > 0.75
          ? snippet
          : description + (description ? '<br><br>' : '') + snippet
        : description;
    },
    buildBody() {
      //TODO : TBD : This is handled in the API when creating the news post, still there for old news posts
      this.htmlBody = this.getNewsArticleBody();
    },
    isVideo() {
      const exernalUri = this.newsArticleDetail.externalUri;
      return video.isVideo(exernalUri);
    },
    getVideoUrl() {
      return this.newsArticleDetail.externalUri;
    },
    switchToOriginalLanguage() {
      this.currentDisplayLanguage = this.newsArticleDetail.language;
      this.buildBody();
    },
    switchToUserLanguage() {
      this.currentDisplayLanguage = this.$i18n.locale;
      this.buildBody();
    },
  },
};
