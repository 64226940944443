import { MutationsCreator } from '@/store/utils';

export default {
  INIT: state => {
    // Profilename
    state.profileNameUpdate.success = false;
    state.profileNameUpdate.error = null;
    state.profileNameUpdate.isLoading = false;
    state.profileNameUpdate.isProfileNameTaken = false;

    // Email
    state.emailUpdate.success = false;
    state.emailUpdate.error = null;
    state.emailUpdate.isLoading = false;
    state.emailUpdate.isEmailTaken = false;
  },
  // Update Profilename
  SET_PROFILENAME_SUCCESS(state, value) {
    state.profileNameUpdate.success = value;
  },
  SET_PROFILENAME_ERROR(state, value) {
    state.profileNameUpdate.error = value;
  },
  SET_PROFILENAME_LOADING(state, value) {
    state.profileNameUpdate.isLoading = value;
  },
  SET_PROFILENAME_TAKEN(state, value) {
    state.profileNameUpdate.isProfileNameTaken = value;
  },
  // Update Email
  SET_EMAIL_TAKEN(state, value) {
    state.emailUpdate.isEmailTaken = value;
  }
};
