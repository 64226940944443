
import { mapActions, mapGetters } from 'vuex';
import { _maxPortfolioLimit } from '@/utils/portfolio-settings';
import { appURLs, inAppRedirect } from '~/utils/route-redirects';

export default {
  name: 'NewsUpdateModal',
  emits: ['onValidate'],
  computed: {
    ...mapGetters('portfolio', ['myOwnedPortfolios']),
    canCreatePortfolio() {
      return this.myOwnedPortfolios.length < _maxPortfolioLimit;
    },
  },
  methods: {
    ...mapActions('modals', ['showCreatePortfolioModal']),
    ...mapActions('authentication', ['validateNewsUpdate']),
    async handleClick() {
      await this.validateNewsUpdate();
      this.canCreatePortfolio && this.showCreatePortfolioModal();
    },
    async handleBack() {
      await this.validateNewsUpdate();
      inAppRedirect(this.localePath(appURLs.feed()));
    },
  },
};
