import { render, staticRenderFns } from "./HelpModals.vue?vue&type=template&id=521bbe56&scoped=true"
import script from "./HelpModals.vue?vue&type=script&lang=js"
export * from "./HelpModals.vue?vue&type=script&lang=js"
import style0 from "./HelpModals.vue?vue&type=style&index=0&id=521bbe56&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "521bbe56",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WButton: require('/skade-pwa/components/ui/atom/WButton/WButton.vue').default,Card: require('/skade-pwa/components/ui/atom/Card.vue').default})
