const recommendationLimit = 5;

export default {
  init(context) {
    context.commit('INIT');
  },
  getCompanyFollowers(context, companyProfileId) {
    context.commit('SET_COMPANY_FOLLOWERS_LOADING', true);
    return this.dispatch('api/getCompanyFollowersRecommendation', {
      limit: recommendationLimit,
      page: context.state.companyFollowersPage,
      companyProfileId: companyProfileId
    })
      .then(res => {
        context.commit('SET_COMPANY_FOLLOWERS', res.data.recommendations);
        res.data.reset && context.commit('RESET_COMPANY_FOLLOWERS_PAGE');
      })
      .catch(err => {
        context.commit('SET_COMPANY_FOLLOWERS_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_COMPANY_FOLLOWERS_LOADING', false);
      });
  },
  getNextCompanyFollowers(context, companyProfileId) {
    context.commit('INCREASE_COMPANY_FOLLOWERS_PAGE');
    return context.dispatch('getCompanyFollowers', companyProfileId);
  },

  // General
  followRecommendation(context, profileId) {
    return this.dispatch('api/followProfile', profileId).then(res => {
      context.commit('UPDATE_COMPANY_FOLLOWER', res.data);
    });
  }
};
