export function isPortfolioOwner(
  portfolioUserRole: string | null | undefined
): boolean {
  return portfolioUserRole === 'OWNER';
}

export function hasEditRights(
  portfolioUserRole: string | null | undefined
): boolean {
  return portfolioUserRole === 'OWNER' || portfolioUserRole === 'EDIT';
}

export function hasViewRights(
  portfolioUserRole: string | null | undefined
): boolean {
  return (
    portfolioUserRole === 'OWNER' ||
    portfolioUserRole === 'EDIT' ||
    portfolioUserRole === 'VIEW'
  );
}
