export default {
  /// TOP GAINERS
  GET_TOP_GAINER_PORTFOLIOS_INIT: (state) => {
    state.topGainerPortfolios = { data: null, loading: true, error: null };
    state.nextTopGainerPortfoliosLoading = false;
    state.topGainerPortfoliosPagination = {
      page: 1,
      limit: 20,
      showMore: false,
    };
  },
  GET_TOP_GAINER_PORTFOLIOS_SUCCESS: (state, data) => {
    state.topGainerPortfolios.data = data;
    state.topGainerPortfolios.loading = false;
    state.topGainerPortfoliosPagination.showMore == data &&
      data.count >= state.topGainerPortfoliosPagination.limit;
  },
  GET_TOP_GAINER_PORTFOLIOS_ERROR: (state, er) => {
    state.topGainerPortfolios = { data: null, error: er, loading: false };
    state.topGainerPortfoliosPagination.showMore = false;
  },
  GET_NEXT_TOP_GAINER_PORTFOLIOS_INIT: (state) => {
    state.nextTopGainerPortfoliosLoading = true;
    state.topGainerPortfoliosPagination.page++;
    state.topGainerPortfoliosPagination.shoMore = false;
  },
  APPEND_TOP_GAINER_PORTFOLIOS: (state, data) => {
    state.topGainerPortfolios.data = [
      ...state.topGainerPortfolios.data,
      ...data,
    ];
    state.nextTopGainerPortfoliosLoading = false;
    state.topGainerPortfoliosPagination.showMore == data &&
      data.count >= state.topGainerPortfoliosPagination.limit;
  },
  GET_NEXT_TOP_GAINER_PORTFOLIOS_ERROR: (state, er) => {
    state.topGainerPortfolios = {
      data: state.topGainerPortfolios.data,
      error: er,
      loading: false,
    };
    state.nextTopGainerPortfoliosLoading = false;
    state.topGainerPortfoliosPagination.showMore = false;
  },

  LIKE_TOP_GAINER_PORTFOLIO_SUCCESS: (state, payload) => {
    const index = state.topGainerPortfolios.data.findIndex(
      (e) => e.id == payload.id
    );
    if (index !== -1) {
      state.topGainerPortfolios.data[index].socials.isLiked =
        payload.socials.isLiked;
      state.topGainerPortfolios.data[index].socials.likeCount =
        payload.socials.likeCount;
    }
  },
  FAVORITE_TOP_GAINER_PORTFOLIO_SUCCESS: (state, payload) => {
    const index = state.topGainerPortfolios.data.findIndex(
      (e) => e.id == payload.id
    );
    if (index !== -1) {
      state.topGainerPortfolios.data[index].socials.isFavorited =
        payload.socials.isFavorited;
      state.topGainerPortfolios.data[index].socials.favoriteCount =
        payload.socials.favoriteCount;
    }
  },

  /// FOLLOWED USERS' TOP GAINERS
  GET_FOLLOWED_USERS_TOP_GAINER_PORTFOLIOS_INIT: (state) => {
    state.followedUsersTopGainerPortfolios = {
      data: null,
      loading: true,
      error: null,
    };
    state.followedUsersTopGainerPortfoliosPagination = {
      page: 1,
      limit: 20,
      showMore: false,
    };
    state.nextFollowedUsersTopGainerPortfoliosLoading = false;
  },
  GET_FOLLOWED_USERS_TOP_GAINER_PORTFOLIOS_SUCCESS: (state, data) => {
    state.followedUsersTopGainerPortfolios.data = data;
    state.followedUsersTopGainerPortfolios.loading = false;
    state.nextFollowedUsersTopGainerPortfoliosLoading = false;
    state.followedUsersTopGainerPortfoliosPagination.showMore =
      data &&
      data.count == state.followedUsersTopGainerPortfoliosPagination.limit;
  },
  GET_FOLLOWED_USERS_TOP_GAINER_PORTFOLIOS_ERROR: (state, er) => {
    state.followedUsersTopGainerPortfolios = {
      data: null,
      loading: true,
      error: er,
    };
    state.followedUsersTopGainerPortfoliosPagination.showMore = false;
  },
  GET_NEXT_FOLLOWED_USERS_TOP_GAINER_PORTFOLIOS_INIT: (state) => {
    state.nextFollowedUsersTopGainerPortfoliosLoading = true;
    state.followedUsersTopGainerPortfoliosPagination.page++;
    state.followedUsersTopGainerPortfoliosPagination.showMore = false;
  },
  APPEND_FOLLOWED_USERS_TOP_GAINER_PORTFOLIOS: (state, data) => {
    state.topGainerPortfolios.data = [
      ...state.topGainerPortfolios.data,
      ...data,
    ];
    state.nextFollowedUsersTopGainerPortfoliosLoading = false;
    state.followedUsersTopGainerPortfoliosPagination.showMore == data &&
      data.count >= state.followedUsersTopGainerPortfoliosPagination.limit;
  },
  GET_NEXT_FOLLOWED_USERS_TOP_GAINER_PORTFOLIOS_ERROR: (state, er) => {
    state.followedUsersTopGainerPortfolios = {
      data: state.followedUsersTopGainerPortfolios.data,
      error: er,
      loading: false,
    };
    state.nextFollowedUsersTopGainerPortfoliosLoading = false;
    state.followedUsersTopGainerPortfoliosPagination.showMore = false;
  },
  LIKE_FOLLOWED_USERS_TOP_GAINER_PORTFOLIO_SUCCESS: (state, payload) => {
    const index = state.followedUsersTopGainerPortfolios.data.findIndex(
      (e) => e.id == payload.id
    );
    if (index !== -1) {
      state.followedUsersTopGainerPortfolios.data[index].socials.isLiked =
        payload.socials.isLiked;
      state.followedUsersTopGainerPortfolios.data[index].socials.likeCount =
        payload.socials.likeCount;
    }
  },
  FAVORITE_FOLLOWED_USERS_TOP_GAINER_PORTFOLIO_SUCCESS: (state, payload) => {
    const index = state.followedUsersTopGainerPortfolios.data.findIndex(
      (e) => e.id == payload.id
    );
    if (index !== -1) {
      state.followedUsersTopGainerPortfolios.data[index].socials.isFavorited =
        payload.socials.isFavorited;
      state.followedUsersTopGainerPortfolios.data[index].socials.favoriteCount =
        payload.socials.favoriteCount;
    }
  },
};
