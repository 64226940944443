import { render, staticRenderFns } from "./Modal.vue?vue&type=template&id=74cd0f40&scoped=true"
import script from "./Modal.vue?vue&type=script&lang=ts"
export * from "./Modal.vue?vue&type=script&lang=ts"
import style0 from "./Modal.vue?vue&type=style&index=0&id=74cd0f40&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74cd0f40",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WButton: require('/skade-pwa/components/ui/atom/WButton/WButton.vue').default,Card: require('/skade-pwa/components/ui/atom/Card.vue').default})
