import * as Sentry from '@sentry/browser';

import { isSentryEnabled } from './sentry';

/**
 * Start a transaction with a given name and execute the operation.
 * @param name The name of the transaction
 * @param operation The operation to execute
 * @returns The result of the operation
 * @async
 */
export async function startTransactionAsync<T>(
  name: string,
  operation: () => Promise<T>
): Promise<T> {
  if (!isSentryEnabled()) return await operation();

  return Sentry.startSpan(
    {
      name,
    },
    operation
  );
}

/**
* Start a transaction with a given name and execute the operation.
 * @param name The name of the transaction
 * @param operation The operation to execute
 * @returns The result of the operation
 */
export function startTransaction<T>(name: string, operation: () => T): T {
  if (!isSentryEnabled()) return operation();

  return Sentry.startSpan(
    {
      name,
    },
    operation
  );
}
