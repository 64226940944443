
import ChevronIcon from '~/components/ui/icons/ChevronIcon.vue';
export default {
  name: 'PortfolioInfo',
  components: {
    ChevronIcon,
  },
  props: {
    showImg: {
      type: Boolean,
      default: true,
    },
    imgSrc: {
      type: String,
      default: '',
    },
    imgDisplayName: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
    showReturn: {
      type: Boolean,
      default: true,
    },
    hasDropdown: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
      required: true,
    },
    itemCount: {
      type: Number,
      default: 0,
    },
    lastUpdated: {
      type: String,
      required: true,
    },
    dailyReturn: {
      type: Number,
      default: 0,
    },
    isRounded: {
      type: Boolean,
      default: true,
    },
    hasBorder: {
      type: Boolean,
      default: true,
    },
    dropdownOpen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fromNow() {
      return this.$dayjs(this.lastUpdated)
        .subtract('1', 's')
        .startOf('s')
        .fromNow();
    },
  },
  methods: {
    handleDropdownClick() {
      this.$emit('onDropdownClick');
    },
  },
};
