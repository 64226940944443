// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ui-m-multiple-image-display{display:grid;grid-template-columns:repeat(2,auto);grid-gap:10px;grid-auto-rows:150px}@media screen and (max-width:768px){.ui-m-multiple-image-display{grid-auto-rows:75px}}.ui-m-multiple-image-display .carousel-img{max-height:70vh}.ui-m-multiple-image-display .image-container{align-items:center;background:var(--color-gray-light);border-radius:10px;display:flex;justify-content:center;overflow:hidden;position:relative}.ui-m-multiple-image-display .image-container .remove-img-btn{background:var(--color-transparent-light);border-radius:50%;cursor:pointer;padding:3px;position:absolute;right:10px;top:10px}.ui-m-multiple-image-display .image-container .remove-img-btn:hover{background:#fff}.ui-m-multiple-image-display .image-container .remove-img-btn svg>*{stroke:var(--color-gray-dark)}.ui-m-multiple-image-display .image-container img{height:100%;max-width:none}.ui-m-multiple-image-display .image-0{grid-column:1;grid-row:1}.ui-m-multiple-image-display .image-0.total-1{grid-column:1/3;grid-row:1/3}.ui-m-multiple-image-display .image-0.total-2{grid-row:1/3}.ui-m-multiple-image-display .image-1{grid-column:2;grid-row:1/3}.ui-m-multiple-image-display .image-1.total-4{grid-row:1}.ui-m-multiple-image-display .image-2{grid-column:1;grid-row:2}.ui-m-multiple-image-display .image-3{grid-column:2;grid-row:2}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"a": "#5a80fb",
	"b": "#6b88fc",
	"c": "#7b91fc",
	"d": "#8899fd",
	"e": "#95a2fd",
	"f": "#a1abfe",
	"g": "#adb4fe",
	"h": "#b8bdfe",
	"i": "#c3c6ff",
	"j": "#cdcfff",
	"k": "#d8d9ff",
	"l": "#e2e2ff",
	"m": "#ececff",
	"n": "#f5f5ff",
	"o": "#fff"
};
module.exports = ___CSS_LOADER_EXPORT___;
