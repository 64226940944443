import Vue from 'vue';

const clickOutside = {
  bind(el, binding) {
    el.__vueClickOutside__ = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event);
      }
    };
    document.addEventListener('mousedown', el.__vueClickOutside__);
    document.addEventListener('touchstart', el.__vueClickOutside__);
  },
  unbind(el) {
    document.removeEventListener('mousedown', el.__vueClickOutside__);
    document.removeEventListener('touchstart', el.__vueClickOutside__);
    delete el.__vueClickOutside__;
  },
};

Vue.directive('click-outside', clickOutside);
