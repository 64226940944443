import * as getters from './getters';

import actions from './actions';
import mutations from './mutations';

const state = () => ({
  // Authentication
  decodedToken: null,
  loginError: null,
  registerError: null,
  isEmailConfirmed: null,
  me: null,
  profile: null,
  profileId: null,
  loginUsername: null,
  loginProfileId: null,
  shouldRefreshAccessToken: false,
  // Login/Register page
  showRegister: false,
  showLogin: true,

  // Profile avatar
  uploadProfileAvatarLoading: false,
  uploadProfileAvatarError: null,
  uploadProfileAvatarSuccess: null,

  // Profile header
  uploadProfileHeaderLoading: false,
  uploadProfileHeaderError: null,
  uploadProfileHeaderSuccess: null,

  // Profile News languages
  newsSuscribedLanguages: null,
  newsSuscribedLanguagesLoading: false,
  newsSuscribedLanguageserror: null,

  // Profile News languages
  updateProfileLoading: false,
  updateProfileError: null,
  deleteUser: { data: null, loading: false, error: null },
});

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
