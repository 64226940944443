import { localeKey as localeCookieKey } from './cookieKeys';

// NOTE: add more properties to the Cookie object if needed

/**
 * Cookie object.
 * This object is used to represent a cookie.
 */
export interface Cookie {
  value: string;
}

/**
 * Window object with the `cookieStore` property.
 * This object is used to represent the `window` object with the `cookieStore` property.
 * The `cookieStore` property is used to access the cookie store.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/CookieStore
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/cookieStore
 */
export interface CookieStoreWindow extends Window {
  cookieStore: {
    get: (key: string) => Promise<Cookie | null>;
  };
}

// NOTE: we do this to avoid the error "Property 'cookieStore' does not exist on type 'Window & typeof globalThis'"

/**
 * Window object with the `cookieStore` property.
 * This object is used to represent the `window` object with the `cookieStore` property.
 */
declare let window: CookieStoreWindow;

/**
 * Get the locale from the cookie.
 * This function is used to get the locale from the cookie.
 * @returns The locale from the cookie, or `null` if the locale is not found.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/CookieStore
 */
export async function getLocaleFromCookieStore(): Promise<string | null> {
  const cookie = await window.cookieStore.get(localeCookieKey);

  return cookie ? cookie.value : null;
}

/**
 * Get the locale from the cookie.
 * This function is used to get the locale from the cookie.
 * This function is used as a fallback when the `cookieStore` property is not available.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
 * @returns The locale from the cookie, or `null` if the locale is not found.
 */
export function getLocaleFromCookie(): string | null {
  const cookie = document.cookie
    .split(';')
    .map((cookie) => cookie.split('='))
    .find((cookie) => {
      const [key] = cookie;
      return key === localeCookieKey;
    });

  return cookie ? cookie[1] : null;
}
