
import WButton from '@/components/ui/atom/WButton/WButton';
import ChevronIcon from '~/components/ui/icons/ChevronIcon.vue';
import HomeIcon from '~/components/ui/icons/HomeIcon.vue';
import HelpIcon from '~/components/ui/icons/HelpIcon.vue';
import PortfolioIcon from '~/components/ui/icons/PortfolioIcon.vue';
import SettingsIcon from '~/components/ui/icons/SettingsIcon.vue';
import { inAppRedirect } from '~/utils/route-redirects';

export default {
  name: 'UnrollerList',
  components: {
    WButton,
    ChevronIcon,
    HomeIcon,
    HelpIcon,
    PortfolioIcon,
    SettingsIcon,
  },
  props: {
    content: {
      type: Array,
      required: true,
      validator: (v) => {
        if (v.length < 1) {
          return false;
        }
        const mandatoryFields = ['name', 'description', 'icon', 'emit', 'path'];
        for (const element of v) {
          for (const field of mandatoryFields) {
            if (!(field in element)) {
              return false;
            }
          }
        }
        return true;
      },
    },
  },
  emits: [
    'onHelpWalkthrough',
    'onHelpPortfolio',
    'onHelpFeed',
    'onHelpSettings',
  ],
  data() {
    return {
      selected: -1,
    };
  },
  methods: {
    select(newIndex) {
      if (this.selected === newIndex) {
        this.selected = -1;
        this.$emit('onCloseSection');
      } else {
        this.selected = newIndex;
        this.$emit('onOpenSection', newIndex);
      }
    },
    getChevronStyle() {
      return 'transform: translateY(-50%) ';
    },
    buttonMethod(emit, path) {
      if (path.length > 0) {
        inAppRedirect(this.localePath(path));
      }
      this.$emit(emit);
    },
  },
};
