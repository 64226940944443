
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

/**
 * A video player component
 * @see [Video.js themes](https://github.com/videojs/themes)
 * @see [Video.js options](https://videojs.com/guides/options)
 */
export default {
  name: 'VideoPlayer',
  props: {
    /**
     * The video player options
     */
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    /**
     * Additional classes to add to the video element
     */
    classNames: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      player: null,
    };
  },
  mounted() {
    // instantiate Video.js
    this.player = videojs(this.$refs.videoPlayer, this.options);
  },
  beforeDestroy() {
    // destroy player on component unmount
    if (this.player) {
      this.player.dispose();
    }
  },
};
