
import HomeIcon from '~/components/ui/icons/HomeIcon.vue';
import PortfolioIcon from '~/components/ui/icons/PortfolioIcon.vue';
import BellIcon from '~/components/ui/icons/BellIcon.vue';
import NewsIcon from '~/components/ui/icons/NewsIcon.vue';
import MessageIcon from '~/components/ui/icons/MessageIcon.vue';
import { mapActions, mapGetters } from 'vuex';
import { appURLs, inAppRedirect } from '@/utils/route-redirects';

/**
 * The bottom navigation bar used on mobile.
 */
export default {
  name: 'NavbarMobile',
  components: {
    HomeIcon,
    PortfolioIcon,
    BellIcon,
    NewsIcon,
    MessageIcon,
  },
  beforeRouteLeave() {
    this.hideSearchPopup();
  },
  data() {
    return {
      appURLs,
      iconSize: '26',
    };
  },
  computed: {
    ...mapGetters('messaging', ['messageNotificationCount']),
    sanitizedNotificationCount() {
      return this.messageNotificationCount ?? 0;
    },
    feedUrl() {
      return this.localePath(appURLs.feed());
    },
  },
  methods: {
    ...mapActions('search', {
      _hideSearchPopup: 'hideSearchPopup',
    }),
    async reloadAndScrollUp() {
      this.hideSearchPopup();
      window.scrollTo({ top: 0, behavior: 'smooth' });
      await this.$store.dispatch('home/getFeed');
    },
    /**
     * Hides the search popup.
     * This method should be used within the scope of this component
     */
    hideSearchPopup() {
      this._hideSearchPopup();
    },
    hideSearchPopupAndNavigate(route) {
      this.hideSearchPopup();
      inAppRedirect(route);
    },
  },
};
