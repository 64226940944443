/**
 * Takes a JWT as a string and decodes its claims into an object that it returns.
 * @param jwt The JWT to decode
 * @returns The decoded JWT, as an object whose properties are the JWT claims
 */
export function decodeJWT(jwt: string): object {
  const jwtPayload = jwt.split('.')[1];
  const decodedTokenBuffer = Buffer.from(jwtPayload, 'base64').toString();
  const decodedToken = JSON.parse(decodedTokenBuffer);

  return decodedToken;
}
