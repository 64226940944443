
export default {
  name: 'Toggle',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 50,
    },
    fontSize: {
      type: Number,
      default: 12,
    },
    height: {
      type: Number,
      default: 22,
    },
    enabledText: {
      type: String,
      default: 'Enabled',
    },
    disabledText: {
      type: String,
      default: 'Disabled',
    },
    color: {
      type: String,
      default: '#2f54eb',
    },
  },
};
