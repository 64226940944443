export default {
  getStocks(context, payload) {
    context.commit('GET_STOCKS_INIT');
    return this.dispatch('api/searchStocks', payload)
      .then((res) => {
        context.commit('SET_STOCKS', res.data);
      })
      .catch((err) => {
        context.commit('SET_GET_STOCKS_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_GET_STOCKS_LOADING', false);
      });
  },
  getNextStocks(context, payload) {
    context.commit('GET_NEXT_STOCKS_INIT');
    return this.dispatch('api/searchStocks', payload)
      .then((res) => {
        context.commit('APPEND_STOCKS', res.data);
      })
      .catch((err) => {
        context.commit('SET_GET_NEXT_STOCKS_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_GET_NEXT_STOCKS_LOADING', false);
      });
  },
  followCompany(context, payload) {
    return this.dispatch('api/followProfile', payload.companyId)
      .then((res) => {
        context.commit('UPDATE_STOCK_IS_FOLLOWED', {
          company: res.data,
          symbolId: payload.symbolId,
        });
      })
      .catch((err) => {
        context.commit('SET_FOLLOW_STOCK_ERROR', err);
      });
  },
  followStockPriceChange(context, payload) {
    return this.dispatch('api/followStockChange', payload)
      .then(() => {
        context.commit('UPDATE_STOCK_PRICE_CHANGE_IS_FOLLOWED', payload);
      })
      .catch((err) => {
        context.commit('SET_FOLLOW_STOCK_ERROR', err);
      });
  },
};
