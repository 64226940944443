import { render, staticRenderFns } from "./ManageTeamPortfolioModal.vue?vue&type=template&id=98c9bfc4"
import script from "./ManageTeamPortfolioModal.vue?vue&type=script&lang=js"
export * from "./ManageTeamPortfolioModal.vue?vue&type=script&lang=js"
import style0 from "./ManageTeamPortfolioModal.vue?vue&type=style&index=0&id=98c9bfc4&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/skade-pwa/components/ui/atom/buttons/Button.vue').default,Modal: require('/skade-pwa/components/ui/atom/Modal.vue').default})
