
import ProfileItem from '@/components/ui/molecule/ProfileItem';

export default {
  name: 'UISelectProfileModal',
  components: { ProfileItem },
  methods: {
    onClickOutside() {
      this.$emit('onClose');
    }
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    profiles: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      defaultUri: require('@/assets/img/unknow-user.jpg')
    };
  }
};
