import { EPromiseState, StoreValue } from './types';
import Vuex, { Store } from 'vuex';

import accountManagement from './modules/settings/account-management';
import api from './modules/api';
import authentication from './modules/authentication';
import communityPortfolios from './modules/communityPortfolios';
import companyFollowersWidget from './modules/widgets/companyFollowers';
import companyProfile from './modules/companyProfile';
import crypto from './modules/crypto';
import cryptoProfile from './modules/cryptoProfile';
import favoritePosts from './modules/favoritePosts';
import hashtags from './modules/hashtags';
import home from './modules/home';
import industry from './modules/industry';
import layouts from './modules/layouts';
import leagues from './modules/leagues';
import market from './modules/market';
import messaging from './modules/messaging';
import modals from './modules/modals';
import mutualRecommendationWidget from './modules/widgets/mutualRecommendation';
import news from './modules/news';
import notifications from './modules/notifications';
import onboarding from './modules/onboarding';
import passwordReset from './modules/settings/password-reset';
import popularCryptosWidget from './modules/widgets/popularCryptos';
import portfolio from './modules/portfolio';
import post from './modules/post';
import profile from './modules/profile';
import recommendations from './modules/recommendations';
import search from './modules/search';
import searchPage from './modules/searchPage';
import shared from './modules/shared';
import similarCompaniesWidget from './modules/widgets/similarCompanies';
import similarCryptosWidget from './modules/widgets/similarCryptos';
import stocks from './modules/stocks';
import symbolProfile from './modules/symbolProfile';
import trendingCryptosWidget from './modules/widgets/trendingCryptos';
import trendingIndicesWidget from './modules/widgets/trendingIndices';
import trendingTickersWidget from './modules/widgets/trendingTickers';
import uiMessaging from './modules/ui-messaging';
import users from './modules/users';

export interface RootState {
  // This disable has been added to support legacy JS. TODO: Should disable
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  authentication: { [key: string]: any };
}

/**
 * Unwraps a given StoreValue into a concrete type if possible, null
 * otherwise.
 *
 * This will ensure it is not loading and that there is no error assigned.
 */
export function unwrap<T>(storeValue: StoreValue<T>): T | null {
  return !!storeValue.error || storeValue.state !== EPromiseState.Done
    ? null
    : storeValue.data;
}

export function unwrapWithLoading<T>(storeValue: StoreValue<T>): {
  data: T | null;
  loading: boolean;
  loaded: boolean;
} {
  const loaded = storeValue.state === EPromiseState.Done;
  const loading = storeValue.state === EPromiseState.Loading;
  return {
    data: storeValue.data,
    loading: loading,
    loaded: loaded,
  };
}

export default () =>
  new Vuex.Store({
    modules: {
      // @ts-ignore TODO I don't know, tried to fix, cost me my sanity.
      api,
      authentication,
      communityPortfolios,
      companyFollowersWidget,
      companyProfile,
      crypto,

      cryptoProfile,
      favoritePosts,
      home,
      industry,
      market,
      modals,
      mutualRecommendationWidget,
      // @ts-ignore TODO I don't know, tried to fix, cost me my sanity.
      news,
      notifications,
      messaging,
      onboarding,
      popularCryptosWidget,
      portfolio,
      post,
      profile,
      recommendations,
      search,
      searchPage,
      shared,
      similarCompaniesWidget,
      similarCryptosWidget,
      symbolProfile,
      trendingCryptosWidget,
      trendingIndicesWidget,
      trendingTickersWidget,
      'ui-messaging': uiMessaging,
      // @ts-ignore TODO I don't know, tried to fix, cost me my sanity.
      users,
      settings: {
        namespaced: true,
        modules: {
          passwordReset,
          accountManagement,
        },
      },
      stocks,
      hashtags,
      layouts,
      leagues,
    },
  }) as Store<RootState>;
