
export default {
  name: 'Banner',
  props: {
    type: {
      type: String,
      default: 'info',
    },
    slotClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    bannerClass() {
      let res = 'ui-banner flex flex-start pl-2 text-neutral-13';

      switch (this.type) {
        case 'info':
          res = res + ' bg-info-light border-info';
          break;
        case 'success':
          res = res + ' bg-success-light border-success';
          break;
        case 'warning':
          res = res + ' bg-warning-light border-warning';
          break;
        case 'danger':
          res = res + ' bg-danger-light border-danger';
          break;
      }
      return res;
    },
  },
};
