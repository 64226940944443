import actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

const state = () => ({
  feed: null,
  newPostNumber: null,
  feedLoading: false,
  nextPostsLoading: false,
  error: null
});

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
