import actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

const state = () => ({
  createAdminLeague: { data: null, loading: false, error: null },
  leagues: { data: null, loading: false, erorr: null },
  moreLeaguesLoading: false,
  leaderboard: { data: null, loading: false, erorr: null },
});

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
