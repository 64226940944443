
export default {
  name: 'UISearchProfile',
  components: {},
  props: {
    searchResults: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    inputError: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      query: '',
      pickerVisible: false,
      currentIndex: 0,
    };
  },
  methods: {
    handleClickOutside() {
      this.pickerVisible = false;
    },
    handleClickAdd(profile, index) {
      this.currentIndex = index;
      this.$emit('addProfile', profile);
    },
  },
};
