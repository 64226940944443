const recommendationLimit = 5;

export default {
  init(context) {
    context.commit('INIT');
  },
  getSimilarCryptos(context, cryptoProfileId) {
    context.commit('SET_SIMILAR_CRYPTOS_LOADING', true);
    return this.dispatch('api/getSimilarCryptos', {
      limit: recommendationLimit,
      page: context.state.similarCryptosPage,
      cryptoProfileId: cryptoProfileId
    })
      .then(res => {
        context.commit('SET_SIMILAR_CRYPTOS', res.data.recommendations);
        if (res.data.reset) {
          context.state.similarCryptosPage == 1 &&
            context.commit('SET_VIEW_MORE_VISIBLE', false);
          context.commit('RESET_SIMILAR_CRYPTOS_PAGE');
        }
      })
      .catch(err => {
        context.commit('SET_SIMILAR_CRYPTOS_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_SIMILAR_CRYPTOS_LOADING', false);
      });
  },
  getNextSimilarCryptos(context, cryptoProfileId) {
    context.commit('INCREASE_SIMILAR_CRYPTOS_PAGE');
    return context.dispatch('getSimilarCryptos', cryptoProfileId);
  },

  // General
  followRecommendation(context, profileId) {
    return this.dispatch('api/followProfile', profileId).then(res => {
      context.commit('UPDATE_SIMILAR_CRYPTO', res.data);
    });
  }
};
