
export default {
  name: 'MediaBar',
  props: {
    selected: {
      type: String,
      default: '',
    },
    disabled: {
      type: Array,
      default: () => [],
    },
    displayed: {
      type: Array,
      default: () => ['image', 'emoji', 'gif'],
    },
  },
};
