import 'dayjs/locale/fr';

import axios from 'axios';

// TODO adjust eslint config
/* eslint-disable no-undef */
const API_OIDC_BASE_URL = process.env.NUXT_ENV_API_OIDC;

/**
 * @deprecated
 * Commented for EsLint
 * TODO : remove in a future refactor
 */
// function readCookie(name, cookieString) {
//   if (!cookieString) {
//     return '';
//   }

//   const cookies = cookieString.split(';'),
//     length = cookies.length,
//     nameEQ = name + '=';
//   let i, cookie;
//   for (i = 0; i < length; i += 1) {
//     cookie = cookies[i];
//     while (cookie.charAt(0) === ' ') {
//       cookie = cookie.substring(1, cookie.length);
//     }
//     if (cookie.indexOf(nameEQ) === 0) {
//       return cookie.substring(nameEQ.length, cookie.length);
//     }
//   }
//   return null;
// }

export default {
  setDecodedToken: (context, decodedToken) => {
    context.commit('SET_DECODED_TOKEN', decodedToken);
  },
  // TODO WAL-640 Deprecated
  async login(context, payload) {
    context.commit('SET_LOGIN_ERROR', null);

    const params = new URLSearchParams();

    params.append('Username', payload.username);
    params.append('Password', payload.password);
    params.append('client_id', 'rop.client');
    params.append('grant_type', 'password');

    return axios
      .post(`${API_OIDC_BASE_URL}/connect/token`, params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((res) => {
        context.commit('SET_LOGIN_ERROR', null);
        context.commit('SET_ACCESS_TOKEN', res.data.access_token);
        context.commit('SET_REFRESH_TOKEN', res.data.refresh_token);
        context.commit('SET_LOGIN_ERROR', null);
      })
      .catch((e) => {
        if (e && e.response) {
          context.commit('SET_LOGIN_ERROR', e.response.data);
        }
        context.dispatch('logout');
      });
  },
  getMe(context) {
    return this.$api
      .getMe()
      .then((response) => {
        context.commit('SET_ME', response.data);
        this.dispatch('onboarding/init', {
          hasCompletedTutorial: response.data.profiles[0].hasCompletedTutorial,
          hasSeenTips: response.data.profiles[0].hasSeenTips,
          tutorialStep: response.data.profiles[0].tutorialStep,
          tipsStep: response.data.profiles[0].tipsStep,
        });

        this.$i18n.setLocale(response.data.profiles[0].displayLanguageCode);
        this.$dayjs.locale(response.data.profiles[0].displayLanguageCode);
      })
      .catch(() => {
        context.commit('SET_ME', null);
      });
  },
  getUserMe(context) {
    return this.$api
      .getUserMe()
      .then((response) => {
        if (response.data) context.commit('SET_USER_ME', response.data);
      })
      .catch(() => {
        context.commit('SET_USER_ME', null);
      });
  },

  // Get news languages with suscribed bool
  getNewsSuscribedLanguages(context, profileId) {
    this.dispatch('api/getNewsSuscribedLanguages', profileId).then((res) => {
      context.commit('SET_NEWS_SUSCRIBED_LANGUAGES', res.data);
    });
  },

  setProfileId: (context, profileId = null) => {
    if (!profileId) {
      if (context.state.me && context.state.me.profiles.length > 0)
        profileId = context.state.me.profiles[0].profileId;
    }
    context.commit('SET_PROFILE_ID', profileId);
  },
  async register(context, payload) {
    context.commit('SET_ACCESS_TOKEN', null);
    context.commit('SET_REGISTER_ERROR', null);
    return this.dispatch('api/register', payload);
  },
  async sendValidationMail(context, userId) {
    if (!userId) userId = context.state.me.userId;
    return this.dispatch('api/sendValidationMail', userId).then(() => {
      window.$nuxt.$notify.success(
        'We succesfully sent you a validation email!'
      );
      return;
    });
  },
  async getMailValidationStatus(context) {
    return this.dispatch(
      'api/getValidationMailStatus',
      context.state.me.userId
    ).then((res) => {
      if (res.data) context.commit('SET_EMAIL_CONFIRMED', res.data.validated);
    });
  },
  getUserFromProfileName(context, profilename) {
    if (profilename) {
      return this.dispatch(
        'api/publicGetUserFromProfilename',
        profilename
      ).then((res) => {
        if (res.data) context.commit('SET_LOGIN_USERNAME', res.data.username);
      });
    }
  },
  getProfileIdFromName(context, profilename) {
    if (profilename) {
      return this.dispatch('api/getProfileIdFromName', profilename).then(
        (res) => {
          if (res.data) context.commit('SET_LOGIN_PROFILE_ID', res.data);
        }
      );
    }
  },

  updateProfile(context, payload) {
    context.commit('SET_UPDATE_PROFILE_LOADING', true);
    return this.dispatch('api/updateProfile', payload)
      .then((res) => {
        context.commit('SET_PROFILE', res.data);
        this.$dayjs.locale(res.data.displayLanguageCode);
        this.$i18n.setLocale(res.data.displayLanguageCode);
      })
      .catch((err) => {
        context.commit('SET_UPDATE_PROFILE_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_UPDATE_PROFILE_LOADING', false);
      });
  },
  closeFeedWelcomeCard(context) {
    context.commit('SET_PROFILE_HAS_SEEN_FEED_WELCOME_CARD', true);
  },
  validateNewsUpdate(context) {
    context.commit('VALIDATE_UPDATE_NEWS');
    return this.dispatch('api/updateProfile', {
      profileId: context.state.profile.profileId,
      data: { hasSeenUpdateNews: true },
    })
      .then((res) => {
        context.commit('SET_PROFILE', res.data);
      })
      .catch((err) => {
        context.commit('SET_UPDATE_PROFILE_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_UPDATE_PROFILE_LOADING', false);
      });
  },

  updateUserBirthdate(context, birthdate) {
    context.commit('SET_USER_BIRTHDATE', birthdate);
  },
  uploadProfileAvatar(context, payload) {
    context.commit('SET_UPLOAD_PROFILE_AVATAR_INIT');
    return this.dispatch('api/uploadProfileAvatar', payload)
      .then((res) => {
        context.commit('SET_UPLOAD_PROFILE_AVATAR_SUCCESS', res.data);
      })
      .catch((err) => {
        context.commit('SET_UPLOAD_PROFILE_AVATAR_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_UPLOAD_PROFILE_AVATAR_LOADING', false);
      });
  },
  uploadProfileHeader(context, payload) {
    context.commit('SET_UPLOAD_PROFILE_HEADER_INIT');
    return this.dispatch('api/uploadProfileHeader', payload)
      .then((res) => {
        context.commit('SET_UPLOAD_PROFILE_HEADER_SUCCESS', res.data);
      })
      .catch((err) => {
        context.commit('SET_UPLOAD_PROFILE_HEADER_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_UPLOAD_PROFILE_HEADER_LOADING', false);
      });
  },
  updateProfileFollowingCount(context, factor) {
    context.commit('UPDATE_FOLLOWING_COUNT', factor);
  },
  setShouldRefreshAccessToken(context, shouldRefresh) {
    context.commit('SET_SHOULD_REFRESH_ACCESS_TOKEN', shouldRefresh);
  },
  deleteUser(context) {
    context.commit('DELETE_USER_INIT');
    return this.dispatch('api/deleteUser')
      .then(() => {
        context.commit('DELETE_USER_SUCCESS');
      })
      .catch((err) => {
        context.commit('DELETE_USER_ERROR', {
          err: err,
        });
        showErrorToast(window.$nuxt.$t('oops_error'));
        throw Error(err);
      });
  },
};
