import { MutationTree } from 'vuex';
import { UIActionIcon, UIMessagingState } from './types';

export default {
  SET_PAGE_TITLE(state: UIMessagingState, title: string) {
    state.pageTitle = title;
  },

  TRIGGER_CUSTOM_BACK_ACTION(state: UIMessagingState, trigger: boolean) {
    state.customBackActionEvent = trigger;
  },

  SET_PAGE_MENU_MODE(state: UIMessagingState, menuMode: string) {
    state.pageMenuMode = menuMode;
  },

  /** @see UIMessagingState.pageNested */
  SET_PAGE_NESTED(state: UIMessagingState, nested: boolean) {
    state.pageNested = nested;
  },

  /**
   * @see UIMessagingState.pageActionRoute
   * @see UIMessagingState.pageActionIcon
   */
  SET_PAGE_ACTION(
    state: UIMessagingState,
    { route, icon }: { route: string; icon: UIActionIcon }
  ) {
    state.pageActionIcon = icon;
    state.pageActionRoute = route;
  },

  /** @see UIMessagingState.pageActionRoute */
  SET_PAGE_ACTION_ROUTE(state: UIMessagingState, route: string) {
    state.pageActionRoute = route;
  },
} as MutationTree<UIMessagingState>;
