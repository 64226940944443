/**
 * Formats numbers into 3-tuples. Ie. 100_000_000 becomes "100 000 000".
 * Garbage function. Use `Number.prototype.toLocaleString()`.
 *
 * @deprecated This plugin has no support for internationalization. Do not use
 *   it.
 * @see display/index.ts
 */
export default function (_, inject) {
  // Inject to context as $colors
  const formatNumber = (x) => {
    const parts = x.toString().split('.');

    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
  };

  inject('formatNumber', formatNumber);
}
