/**
 * Embed object
 * @interface Embed
 * @property {string | null} url - The URL of the embed
 * @property {string | null} domain - The domain of the embed
 * @property {object | null} openGraphTags - The open graph tags of the embed
 * @property {boolean} shouldShow - Whether the embed should be shown
 * @property {string | null} title - The title of the embed
 * @property {string | null} description - The description of the embed
 */
export interface Embed {
  url: string | null;
  domain: string | null;
  openGraphTags: {
    title: string;
    description: string;
  } | null;
  shouldShow: boolean;
  title: string | null;
  description: string | null;
}

/**
 * Initial state for the embed object
 * @type {Embed}
 * @constant
 * @default
 * @export
 */
export const initialEmbedState: Embed = {
  url: null,
  domain: null,
  openGraphTags: null,
  shouldShow: false,
  title: null,
  description: null,
};

/**
 *
 * @param component
 * @param embed
 */
export function emitUpdateEmbed(
  component: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    $emit: (arg0: string, arg1: any) => void;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  embed: any
) {
  if (embed === initialEmbedState) component.$emit('update-embed', null);
  else component.$emit('update-embed', embed);
}

/**
 * Navigates to the given URL.
 * @param url The URL to navigate to.
 */
export function navigateToUrl(url: string): void {
  if (url) {
    window.open(url, '_blank');
  }
}
