
export default {
  name: 'Loader',
  props: {
    center: {
      type: Boolean,
      default: false
    }
  }
};
