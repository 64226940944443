
import UICreatePost from '@/components/ui/organism/UICreatePost';
import CloseIcon from '~/components/ui/icons/CloseIcon.vue';
import CustomModal from '~/components/ui/molecule/CustomModal.vue';

export default {
  name: 'UICreatePostModal',
  components: { UICreatePost, CloseIcon, CustomModal },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    profileImg: {
      type: String,
      required: true,
    },
    profileName: {
      type: String,
      required: true,
    },
    clear: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    uploadImages: {
      type: Function,
      required: true,
    },
    searchMentions: {
      type: Function,
      required: true,
    },
    searchHashtags: {
      type: Function,
      required: true,
    },
    targetType: {
      type: String,
      required: true,
    },
    target: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: 'create_post',
    },
    mentionedPost: {
      type: Object,
      default: () => null,
    },
    originalPost: {
      type: Object,
      default: () => null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      submitPost: false,
    };
  },
  watch: {
    show() {
      if (this.show) {
        document.body.classList.add('overflow-hidden');
      } else {
        document.body.classList.remove('overflow-hidden');
      }
    },
  },
  methods: {
    propagateSubmitPost() {
      this.submitPost = true;
    },
  },
};
