import { render, staticRenderFns } from "./EmailValidationBannerWrapper.vue?vue&type=template&id=5a645f1a"
import script from "./EmailValidationBannerWrapper.vue?vue&type=script&lang=js"
export * from "./EmailValidationBannerWrapper.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/skade-pwa/components/ui/atom/Icon.vue').default,Banner: require('/skade-pwa/components/ui/atom/Banner.vue').default})
