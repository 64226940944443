// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tooltip{z-index:999}@media (max-width:1023px){.ui-create-league-modal{flex-direction:column;justify-content:flex-start}.ui-create-league-modal .ui-card{background-color:#f6f6f6;border:none}}.ui-create-league-modal .formulate-input{margin-bottom:0}.custom-radio .formulate-input-help{color:#8c8c8c;font-size:14px;margin-bottom:.1em;margin-left:.6vh}.custom-radio .formulate-input-label{font-size:14px}.custom-radio .formulate-input{align-items:center;display:flex}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"a": "#5a80fb",
	"b": "#6b88fc",
	"c": "#7b91fc",
	"d": "#8899fd",
	"e": "#95a2fd",
	"f": "#a1abfe",
	"g": "#adb4fe",
	"h": "#b8bdfe",
	"i": "#c3c6ff",
	"j": "#cdcfff",
	"k": "#d8d9ff",
	"l": "#e2e2ff",
	"m": "#ececff",
	"n": "#f5f5ff",
	"o": "#fff"
};
module.exports = ___CSS_LOADER_EXPORT___;
