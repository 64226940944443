import { MutationsCreator } from '@/store/utils';
import { findAndReplace } from '@/utils';

export default {
  INIT: state => {
    state.popularCryptos = [];
    state.popularCryptosError = null;
    state.popularCryptosLoading = false;
  },

  SET_POPULAR_CRYPTOS_LOADING: (state, isLoading) => {
    state.popularCryptosLoading = isLoading;
  },

  SET_POPULAR_CRYPTOS: (state, payload) => {
    state.popularCryptos = payload;
  },

  SET_POPULAR_CRYPTOS_ERROR: (state, er) => {
    state.popularCryptosError = er;
  },
};
