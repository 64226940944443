export default {
  GET_STOCKS_INIT: (state) => {
    state.stocks = null;
    state.getStocks.loading = true;
    state.getStocks.error = null;
  },
  GET_NEXT_STOCKS_INIT: (state) => {
    state.getNextStocks.loading = true;
    state.getNextStocks.error = null;
  },
  SET_STOCKS: (state, payload) => {
    state.stocks = payload;
  },
  APPEND_STOCKS: (state, payload) => {
    state.stocks = [...state.stocks, ...payload];
  },
  SET_GET_STOCKS_ERROR: (state, payload) => {
    state.getStocks.error = payload;
  },
  SET_GET_STOCKS_LOADING: (state, payload) => {
    state.getStocks.loading = payload;
  },
  SET_GET_NEXT_STOCKS_ERROR: (state, payload) => {
    state.getNextStocks.error = payload;
  },
  SET_GET_NEXT_STOCKS_LOADING: (state, payload) => {
    state.getNextStocks.loading = payload;
  },
  UPDATE_STOCK_IS_FOLLOWED(state, payload) {
    if (state.stocks) {
      const index = state.stocks.findIndex(
        (c) => c.symbolId === payload.symbolId
      );

      if (index != -1) {
        state.stocks[index].isFollowed = payload.company.isFollowed;
      }
    }
  },
  UPDATE_STOCK_PRICE_CHANGE_IS_FOLLOWED(state, payload) {
    if (state.stocks) {
      const index = state.stocks.findIndex(
        (c) => c.symbolId === payload.symbolId
      );

      if (index != -1) {
        state.stocks[index].isFollowingPriceChange =
          !state.stocks[index].isFollowingPriceChange;
      }
    }
  },
  SET_FOLLOW_STOCK_ERROR(state, err) {
    state.followStock.error = err;
  },
};
