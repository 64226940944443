
import Vue from 'vue';
import { debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import v2SearchPopup from '~/components/ui/organism/SearchPopup/v2SearchPopup.vue';
import { SearchResult } from '~/types/models/search';
import { StoreValue } from '~/store/types';
import { unwrapWithLoading } from '~/store';
import SearchIcon from '~/components/ui/icons/SearchIcon.vue';
import { appURLs, inAppRedirect } from '~/utils/route-redirects';
import { getWindowInstance } from '~/plugins/windowInstance';

export default Vue.extend({
  components: {
    v2SearchPopup,
    SearchIcon,
  },
  props: {
    searchPopupShown: {
      type: Boolean,
      required: true,
    },
  },
  // @ts-ignore TODO: Figure out why emits doesn't work with ts.
  emits: ['input', 'popup-shown'],

  data(): {
    /** The current search query */
    searchQuery: string;
    /** Debounced function which actually performs the search to the backend. */
    performSearch: (arg0: string) => void;
    /** Whether the search input is focused */
    inputFocused: boolean;
    /** Whether the user has their cursor clicked down on a popup link. */
    searchPopupNavigationSelected: boolean;
  } {
    return {
      searchQuery: '',
      // @ts-ignore TODO fix TS error
      performSearch: debounce(this.searchForQuery, 200),
      inputFocused: false,
      searchPopupNavigationSelected: false,
    };
  },
  async fetch(): Promise<void> {
    // @ts-ignore TODO Figure out why TS doesn't see this.
    await this.fetchSearchHistory();
  },
  computed: {
    userQueryHistory(): string {
      // @ts-ignore TODO Figure out why TS doesn't see this.
      return this.filteredUserSearchHistoryRes(this.$data.searchQuery);
    },
    ...mapGetters('search', [
      'userSearchResults',
      'filteredUserSearchHistoryRes',
    ]),
    /** Outputs the search results for the popup. */
    popupSearchResults(): {
      data: SearchResult | null;
      loading: boolean;
      loaded: boolean;
    } {
      const storeResult: StoreValue<SearchResult | null> =
        // @ts-ignore TODO Figure out why TS doesn't see this.
        this.userSearchResults;
      return unwrapWithLoading(storeResult);
    },
    isMobileResponsive(): boolean {
      // @ts-ignore TODO fix typescript not recognizing this
      return getWindowInstance(this).isMobileResponsive();
    },
  },
  watch: {
    inputFocused(newValue: boolean) {
      if (newValue) {
        this.$emit('popup-shown', true);
      } else if (
        !this.$data.searchPopupNavigationSelected &&
        // @ts-ignore TODO fix ts issue
        !this.isMobileResponsive
      ) {
        // This additional guard is necessary due to an event timing issue.
        // If we simply set this.$data.searchPopupShown to follow the state of
        // inputFocused, we will end up closing the popup before the mouseup
        // event is fired (doing the navigation).
        this.$emit('popup-shown', false);
      }
    },
    searchQuery(newValue: string): void {
      if (newValue)
        // @ts-ignore TODO Figure out why ts believes this doesn't exist.
        this.performSearch({ query: newValue });
      else {
        // it's important to cancel the debounce action, or else race conditions may clear the input, but load the search results anyways
        // @ts-ignore TODO Figure out why ts believes this doesn't exist.
        this.performSearch.cancel();
        // @ts-ignore TODO Figure out why ts believes this doesn't exist.
        this.clearSearch();
      }
    },
  },
  methods: {
    focusInput(): void {
      (this.$refs.input as HTMLInputElement).focus();
    },
    onInputEvt(event: InputEvent): void {
      this.$data.searchQuery = (event.target as HTMLInputElement).value;
      this.$emit('input', this.$data.searchQuery);
    },
    wipeSearchInputField(): void {
      this.$data.searchQuery = '';
      this.$emit('input', this.$data.searchQuery);
      // @ts-ignore TODO fix typescript error
      this.focusInput();
    },
    searchbarSubmit(): void {
      (this.$refs.input as HTMLInputElement).blur();
      this.$emit('popup-shown', false);

      // prevent navigation when there's no query
      if (!this.$data.searchQuery) return;

      inAppRedirect(appURLs.searchResultsByQuery(this.$data.searchQuery));
    },
    onNavigate(): void {
      this.$emit('popup-shown', false);
    },
    searchIconClicked(): void {
      this.$emit('popup-shown', true);
    },
    ...mapActions('search', [
      'fetchSearchHistory',
      'searchForQuery',
      'clearSearch',
    ]),
    handleSearchBlur(): void {
      // @ts-ignore fix this ts error
      this.inputFocused = false;
    },
    navigateMouseDown(): void {
      // @ts-ignore fix this ts error
      this.searchPopupNavigationSelected = true;
    },
    navigateMouseUp(): void {
      // @ts-ignore fix this ts error
      this.searchPopupNavigationSelected = false;
    },
  },
});
