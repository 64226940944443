import { render, staticRenderFns } from "./MediaBar.vue?vue&type=template&id=549165ad"
import script from "./MediaBar.vue?vue&type=script&lang=js"
export * from "./MediaBar.vue?vue&type=script&lang=js"
import style0 from "./MediaBar.vue?vue&type=style&index=0&id=549165ad&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/skade-pwa/components/ui/atom/Icon.vue').default})
