import { ActionsCreator } from '@/store/utils';

export default {
  init(context) {
    context.commit('INIT');
  },
  // Update Profilename
  validateProfileName(context, name) {
    if (name) {
      context.commit('SET_PROFILENAME_SUCCESS', false);
      context.commit('SET_PROFILENAME_LOADING', true);
      this.dispatch('api/publicValidateProfileProfilename', name)
        .then(res => {
          context.commit('SET_PROFILENAME_TAKEN', !res.data.isValid);
        })
        .catch(err => {})
        .finally(() => {
          context.commit('SET_PROFILENAME_LOADING', false);
        });
    } else {
      context.commit('SET_PROFILENAME_TAKEN', false);
    }
  },
  updateProfileName(context, payload) {
    context.commit('INIT');
    context.commit('SET_PROFILENAME_LOADING', true);
    this.dispatch('api/updateProfile', payload)
      .then(res => {
        context.commit('SET_PROFILENAME_SUCCESS', true);
        this.dispatch('authentication/getMe', res.data).then(res => {
          this.dispatch('authentication/setProfileId', payload.profileId);
        });
      })
      .catch(err => {
        context.commit('SET_PROFILENAME_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_PROFILENAME_LOADING', false);
      });
  },

  // Update Email
  validateUserEmail(context, email) {
    if (email) {
      this.dispatch('api/publicValidateUserEmail', email)
        .then(res => {
          context.commit('SET_EMAIL_TAKEN', !res.data.isValid);
        })
        .catch(err => {});
    } else {
      context.commit('SET_EMAIL_TAKEN', false);
    }
  }
};
