import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { Store } from 'vuex';
import { MessageNotificationDetail } from '~/types/messages/message-notifications';
import { RootState } from '~/store';
import { ContainsHubConnection } from '..';

export default function messageNotificationsHub(
  store: Store<RootState>,
  jwt: string
): ContainsHubConnection {
  const hub = new HubConnectionBuilder()
    .withUrl(
      `${process.env.NUXT_ENV_API_URL}/subscribe/message-notifications`,
      {
        accessTokenFactory: () => jwt,
      }
    )
    .configureLogging(
      ['local', 'development'].includes(process.env.NODE_ENV ?? '')
        ? LogLevel.Debug
        : LogLevel.None
    )
    .build();

  hub.on('ReceiveMessageNotification', (not: MessageNotificationDetail) => {
    store.dispatch('messaging/addNewMessageNotification', not);
    store.dispatch('messaging/setMessageNotificationCount');
  });

  return { hub };
}
