/**
 * Check if the URL is a video
 * @param url The URL to check
 * @returns True if the URL is a video, false otherwise
 */
function isVideo(url: string): boolean {
  return isYoutubeVideo(url) || isDirectVideo(url);
}

/**
 * Check if the URL is a Youtube video
 * @param url The URL to check
 * @returns True if the URL is a Youtube video, false otherwise
 */
function isYoutubeVideo(url: string): boolean {
  return url.startsWith('https://www.youtube.com');
}

/**
 * Get the embed URL of a Youtube video given the video URL
 * @param url The URL to check
 * @returns The embed URL of the Youtube video
 */
function getYoutubeEmbedUrl(url: string): string {
  const matches = new RegExp(
    'http(?:s)?:\\/\\/(?:www\\.)?youtube\\.com\\/watch\\?v=([a-zA-Z0-9-_]+)'
  ).exec(url);
  if (matches === null) {
    console.error('Could not get Youtube video ID from URL', url);
    return '';
  }

  const videoId = matches[1];
  const embedUrl = `https://www.youtube.com/embed/${videoId}`;
  return embedUrl;
}

/**
 * Check if the URL is a direct video
 * @param url The URL to check
 * @returns True if the URL is a direct video, false otherwise
 */
function isDirectVideo(url: string): boolean {
  return /^.*\.(mp4|webm|mpeg|mov)$/.test(url);
}

/**
 * Get the MIME type of a video URL
 * @param url The URL to check
 * @returns The MIME type of the video
 */
function getVideoMimeType(url: string): string {
  const extension = url.split('.').pop();
  if (extension === undefined)
    console.error('No extension found for video URL', url);
  return 'video/' + (extension ?? 'mp4');
}

export default {
  isVideo,
  isYoutubeVideo,
  isDirectVideo,
  getVideoMimeType,
  getYoutubeEmbedUrl,
};
