import { ActionsCreator } from '@/store/utils';

export default {
  // Profile
  initSymbolProfilePage(context) {
    context.commit('INIT_PROFILE');
  },
  getSymbolProfileById(context, payload) {
    context.commit('INIT_PROFILE');
    context.commit('SET_PROFILE_LOADING', true);
    return this.dispatch('api/getSymbolProfileById', payload)
    .then(response => {
      context.commit('SET_PROFILE', response.data);
    }).catch(error => {
      context.commit('SET_PROFILE_ERROR', error);
    })
    .finally(() => {
      context.commit('SET_PROFILE_LOADING', false);
    }); 
  },     
  followProfile(context, payload) {
    this.dispatch('api/followProfile', payload)
      .then(res => {
        context.commit('TOGGLE_PROFILE_FOLLOW', res.data);
      })
      .catch(err => {
        context.commit('SET_PROFILE_ERROR', err);
      });
  },
};
