
export default {
  name: 'OnboardingTourCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
    totalStep: {
      type: Number,
      default: 3,
    },
    // can be : top | left
    arrowPos: {
      type: String,
      default: 'top',
    },
  },
};
