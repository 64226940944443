
import { inAppRedirect } from '~/utils/route-redirects';

export default {
  name: 'IndustryTags',
  props: {
    // [{id, name}]
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    goToMarket() {
      if (this.items && this.items.length) {
        if (this.items[0].id != '*') {
          inAppRedirect('/market?industryId=' + this.items[0].id);
        } else {
          inAppRedirect('/market');
        }
      }
    },
  },
};
