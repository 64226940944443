
import UserImgInfo from '@/components/ui/molecule/UserImgInfo';
import CheckIcon from '~/components/ui/icons/CheckIcon.vue';
import AddIcon from '~/components/ui/icons/AddIcon.vue';

export default {
  name: 'UIRecommendationWidget',
  components: { UserImgInfo, CheckIcon, AddIcon },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    profiles: {
      type: Array,
      required: true,
    },
    noRecommendationTranslationKey: {
      type: String,
      default: 'no_follower',
    },
    oneRecommendationTranslationKey: {
      type: String,
      default: 'follower_count_1',
    },
    manyRecommendationTranslationKey: {
      type: String,
      default: 'follower_count',
    },
    noResultInfo: {
      type: String,
      default: '',
    },
    isViewMoreVisible: {
      type: Boolean,
      default: true,
    },
  },
};
