import * as getters from './getters';

import actions from './actions';
import mutations from './mutations';

const state = () => ({
  users: { data: null, loading: null, error: null },
  companies: { data: null, loading: null, error: null },
  cryptos: { data: null, loading: null, error: null },
  tickers: { data: null, loading: null, error: null },
  hashtags: { data: null, loading: null, error: null },
  indices: { data: null, loading: null, error: null },
  symbols: { data: null, loading: null, error: null },

  searchLoading: null,
  searchError: null,
  followError: null,

  v2: {
    tickers: null,
    hasMoreTickers: true,
    users: null,
    hasMoreUsers: true,
    page: 0,
    resultsPerPage: 15,
  },
});

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
