
import CloseIcon from '~/components/ui/icons/CloseIcon.vue';
import ChevronIcon from '~/components/ui/icons/ChevronIcon.vue';
import { getSymbolTagText } from '@/utils/company-tags.ts';
import { isMobile } from '~/utils/screenSizes';
export default {
  name: 'StockOperationCard',
  components: { CloseIcon, ChevronIcon },
  props: {
    symbol: {
      type: Object,
      default: null,
    },
    showCardBackgroundStyle: {
      type: Boolean,
      default: false,
    },
    showCloseIcon: {
      type: Boolean,
      default: false,
    },
    showChevronIcon: {
      type: Boolean,
      default: false,
    },
    chevronDirection: {
      type: String,
      default: 'down',
    },
    showSymbolExchange: {
      type: Boolean,
      default: false,
    },
    labelText: {
      type: String,
      default: '',
    },
    avatarUri: {
      type: String,
      default: '',
    },
    symbolName: {
      type: String,
      default: '',
    },
    symbolType: {
      type: String,
      default: '',
    },
    companyName: {
      type: String,
      default: '',
    },
    exchange: {
      type: String,
      default: '',
    },
  },
  computed: {
    isMobileSize() {
      return isMobile();
    },
    setBackgroundStyle() {
      if (this.showCardBackgroundStyle) {
        return 'rounded-xl border border-neutral-4 bg-neutral-3';
      }
      return '';
    },
  },
  methods: {
    getCompanyTag(companyType) {
      return getSymbolTagText(companyType);
    },
  },
};
