const recommendationLimit = 5;

export default {
  init(context) {
    context.commit('INIT');
  },
  getTrendingIndices(context) {
    context.commit('SET_TRENDING_INDICES_LOADING', true);
    return this.dispatch('api/getTrendingIndices', {
      limit: recommendationLimit,
      page: context.state.trendingIndicesPage,
    })
      .then((res) => {
        context.commit('SET_TRENDING_INDICES', res.data.trendingIndices);
        res.data.reset && context.commit('RESET_TRENDING_INDICES_PAGE');
      })
      .catch((err) => {
        context.commit('SET_TRENDING_INDICES_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_TRENDING_INDICES_LOADING', false);
      });
  },
  getNextTrendingIndices(context) {
    context.commit('INCREASE_TRENDING_INDICES_PAGE');
    return context.dispatch('getTrendingIndices');
  },
};
