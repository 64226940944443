export default {
  createAdminLeague(context, payload) {
    context.commit('CREATE_ADMIN_LEAGUE_INIT');
    return this.dispatch('api/createAdminLeague', payload)
      .then((res) => {
        context.commit('CREATE_ADMIN_LEAGUE_SUCCESS', res.data);
        context.commit('APPEND_LEAGUES_TO_LEAGUES', res.data);
      })
      .catch((err) => {
        context.commit('CREATE_ADMIN_LEAGUE_ERROR', err);
      });
  },
  getLeagues(context, query) {
    context.commit('GET_LEAGUES_INIT');
    return this.dispatch('api/searchLeagues', query)
      .then((res) => {
        context.commit('GET_LEAGUES_SUCCESS', res.data);
      })
      .catch((err) => {
        context.commit('GET_LEAGUES_ERROR', err);
      });
  },
};
