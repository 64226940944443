
import { mapActions, mapGetters } from 'vuex';
import SendCodeStep from '@/components/ui/modal/PasswordResetModal/SendCodeStep.vue';
import EnterCodeStep from '@/components/ui/modal/PasswordResetModal/EnterCodeStep.vue';
import NewPasswordStep from '@/components/ui/modal/PasswordResetModal/NewPasswordStep.vue';
// import PurposeStep from '@/components/ui/modal/PasswordResetModal/PurposeStep.vue';
import LogoutStep from '@/components/ui/modal/PasswordResetModal/LogoutStep.vue';
import Modal from '@/components/ui/molecule/Modal';
import { appURLs, inAppRedirect } from '~/utils/route-redirects';

export default {
  name: 'OnboardingModalWrapper',
  components: {
    SendCodeStep,
    EnterCodeStep,
    NewPasswordStep,
    LogoutStep,
    Modal,
  },
  data() {
    return {
      profilesOffset: 0,
      isValid: false,
      maxStep: 4,
    };
  },
  computed: {
    ...mapGetters('authentication', ['me']),
    ...mapGetters('settings/passwordReset', ['resetPasswordTokenEmailSuccess']),
    ...mapGetters('modals', ['passwordResetModal']),
    step() {
      return this.passwordResetModal.step;
    },
  },
  methods: {
    ...mapActions('modals', ['hidePasswordReset', 'passwordResetNextStep']),
    ...mapActions('settings/passwordReset', [
      'sendPasswordResetTokenEmail',
      'setPasswordResetCode',
    ]),
    handleClose() {
      this.hidePasswordReset();
    },
    async handleSendCode(value) {
      switch (value) {
        case 'email':
          await this.sendPasswordResetTokenEmail();
          break;
        case 'phone':
          await this.sendPasswordResetTokenEmail();
          break;
        default:
          break;
      }
      if (this.resetPasswordTokenEmailSuccess) this.passwordResetNextStep();
    },
    handleSetCode(code) {
      this.setPasswordResetCode(code);
      this.passwordResetNextStep();
    },
    handleResetSuccess() {
      this.passwordResetNextStep();
    },
    handleSubmitPurpose() {
      //TODO : send the info to the API
      this.passwordResetNextStep();
    },
    handleSubmitLogout() {
      //TODO : send info to the API
      this.hidePasswordReset();

      window.$nuxt.$notify.success(this.$t('modals_pr_success'));

      inAppRedirect(this.localePath(appURLs.feed()));
    },
  },
};
