export default {
  init(context, payload) {
    context.commit('INIT', payload);
  },
  getRecommendedCompanies(context) {
    context.commit('SET_COMPANIES', null);
    context.commit('RESET_COMPANIES_OFFSET');
    context.commit('SET_COMPANIES_LOADING', true);
    return this.dispatch('api/getRecommendedCompanies', {
      limit: 12,
      page: 1,
    })
      .then((res) => {
        context.commit('SET_COMPANIES', res.data);
        res.data.length < 12 &&
          context.commit('SET_SHOW_MORE_COMPANIES', false);
      })
      .catch((err) => {
        // TODO
      })
      .finally(() => {
        context.commit('SET_COMPANIES_LOADING', false);
      });
  },
  getNextCompanies(context) {
    context.commit('SET_NEXT_COMPANIES_LOADING', true);
    return this.dispatch('api/getRecommendedCompanies', {
      limit: 12,
      page: context.state.companiesOffset + 1,
    })
      .then((res) => {
        context.commit('INCREASE_COMPANIES_OFFSET');
        context.commit('APPEND_COMPANIES', res.data);
        res.data.length < 12 &&
          context.commit('SET_SHOW_MORE_COMPANIES', false);
      })
      .catch((err) => {
        // TODO
      })
      .finally(() => {
        context.commit('SET_NEXT_COMPANIES_LOADING', false);
      });
  },
  followCompany(context, profileId) {
    return this.dispatch('api/followProfile', profileId)
      .then((res) => {
        context.commit('UPDATE_COMPANY_IS_FOLLOWED', res.data);
      })
      .catch((err) => {
        // TODO
      })
      .finally(() => {});
  },
  getRecommendedProfiles(context) {
    context.commit('SET_PROFILES', null);
    context.commit('RESET_PROFILES_OFFSET');
    context.commit('SET_PROFILES_LOADING', true);
    return this.dispatch('api/getRecommendedProfiles', {
      limit: 12,
      page: 1,
    })
      .then((res) => {
        context.commit('SET_PROFILES', res.data);
        res.data.length < 12 && context.commit('SET_SHOW_MORE_PROFILES', false);
      })
      .catch((err) => {
        // TODO
      })
      .finally(() => {
        context.commit('SET_PROFILES_LOADING', false);
      });
  },
  getNextProfiles(context) {
    context.commit('SET_NEXT_PROFILES_LOADING', true);
    return this.dispatch('api/getRecommendedProfiles', {
      limit: 12,
      page: context.state.profilesOffset + 1,
    })
      .then((res) => {
        context.commit('INCREASE_PROFILES_OFFSET');
        context.commit('APPEND_PROFILES', res.data);
        res.data.length < 12 && context.commit('SET_SHOW_MORE_PROFILES', false);
      })
      .catch((err) => {
        // TODO
      })
      .finally(() => {
        context.commit('SET_NEXT_PROFILES_LOADING', false);
      });
  },
  followProfile(context, profileId) {
    return this.dispatch('api/followProfile', profileId)
      .then((res) => {
        context.commit('UPDATE_PROFILE_IS_FOLLOWED', res.data);
      })
      .catch((err) => {
        // TODO
      })
      .finally(() => {});
  },
};
