
import { mapActions, mapGetters } from 'vuex';
import Modal from '~/components/ui/molecule/Modal.vue';
import SearchProfileWrapper from '@/components/stocks/SearchProfileWrapper';
import UITeamPortfolioProfilesTable from '@/components/ui/organism/UITeamPortfolioProfilesTable.vue';
import UIProfileSearchWIthProfileCards from '@/components/ui/organism/UIProfileSearchWithProfileCards.vue';

export default {
  name: 'TeamPortfolioShareModal',
  components: {
    Modal,
    SearchProfileWrapper,
    UITeamPortfolioProfilesTable,
    UIProfileSearchWIthProfileCards,
  },
  props: {
    tableItems: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      profilesToAdd: [],
      roles: [
        { value: 'Editor', key: 'EDIT' },
        { value: 'Viewer', key: 'VIEW' },
      ],
      selectedRole: {
        value: 'Editor',
        key: 'EDIT',
      },
    };
  },
  computed: {
    ...mapGetters('portfolio', ['getPortfolioByIdRes']),
    ...mapGetters('search', ['searchRes']),
  },
  methods: {
    ...mapActions('modals', ['showManageTeamPortfolioModal']),
    ...mapActions('portfolio', [
      'updateTeamPortfolioProfile',
      'deleteTeamPortfolioProfile',
      'createTeamPortfolioProfile',
    ]),
    handleAddProfile(profileToAdd) {
      const profileIndex = this.searchRes.data.mentions.findIndex(
        (profile) => profile.profileId === profileToAdd.profileId
      );

      if (profileIndex !== -1) {
        this.searchRes.data.mentions.splice(profileIndex, 1);
      }
      this.profilesToAdd.push(profileToAdd);
    },
    handleUpdateTeamPortfolioRole(payload) {
      const profileToUpdate = this.tableItems.find(
        (item) =>
          item.profileId === payload.profileId &&
          item.portfolioId === payload.portfolioId
      );
      if (profileToUpdate.portfolioUserRole === payload.portfolioUserRole) {
        return;
      }
      this.updateTeamPortfolioProfile(payload);
    },
    handleRemoveTeamPortfolioMember(payload) {
      this.deleteTeamPortfolioProfile(payload);
    },
    handleRemoveUserProfile(profileId) {
      const profileToRemoveIndex = this.profilesToAdd.findIndex(
        (profile) => profile.profileId === profileId
      );

      if (profileToRemoveIndex !== -1) {
        this.profilesToAdd.splice(profileToRemoveIndex, 1);
      }
    },
    handleCreate() {
      const payload = [];
      this.profilesToAdd.forEach((profile) => {
        const profileToAdd = {
          profileId: profile.profileId,
          portfolioId: this.getPortfolioByIdRes.data.id,
          portfolioUserRole: this.selectedRole.key,
        };
        payload.push(profileToAdd);
      });
      this.createTeamPortfolioProfile(payload);
      this.profilesToAdd = [];
    },
    handleRoleChange(role) {
      this.selectedRole = role;
    },
    getModalTitle() {
      return (
        this.$t('team_portfolio_modal_share_portfolio_access') +
        '"' +
        this.getPortfolioByIdRes.data.name +
        '"'
      );
    },
  },
};
