import Vue from 'vue';
import VueMq from 'vue-mq';

/**
 * Be mindful that $mq and Tailwind don't follow the same behavior regarding breakpoints
 * for Tailwind, md => @media (min-width: 768px)
 * for $mq, md => @media (min-width: 640px; max-width: 768)
 * So in general we need to take one tier higher breakpoint when using $mq along with Tailwind
 */
Vue.use(VueMq, {
  breakpoints: {
    // These follow tailwind.
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    '2xl': 1536,
  },
  defaultBreakpoint: 'lg', // Used for SSR.
});
