import { MutationsCreator } from '@/store/utils';
import { findAndReplace } from '@/utils';

export default {
  INIT: state => {
    state.similarCryptos = [];
    state.SimilarCryptosPage = 1;
    state.SimilarCryptosError = null;
    state.SimilarCryptosLoading = false;
    state.isViewMoreVisible = true;
  },

  // Mutual Follows
  SET_SIMILAR_CRYPTOS: (state, payload) => {
    state.similarCryptos = payload;
  },
  SET_SIMILAR_CRYPTOS_LOADING: (state, isLoading) => {
    state.similarCryptosLoading = isLoading;
  },
  SET_VIEW_MORE_VISIBLE: (state, isVisible) => {
    state.isViewMoreVisible = isVisible;
  },
  SET_SIMILAR_CRYPTOS_ERROR: (state, er) => {
    state.similarCryptosError = er;
  },
  INCREASE_SIMILAR_CRYPTOS_PAGE: (state, er) => {
    state.similarCryptosPage++;
  },
  RESET_SIMILAR_CRYPTOS_PAGE: (state, er) => {
    state.similarCryptosPage = 0;
  },
  UPDATE_SIMILAR_CRYPTO(state, company) {
    if (state.similarCryptos) {
      let index = state.similarCryptos.findIndex(
        e => e.profileId === company.profileId
      );

      if (index !== -1) {
        state.similarCryptos[index].recommendationScore =
          company.followerCount;
        state.similarCryptos[index].isFollowed = company.isFollowed;
      }
    }
  }
};
