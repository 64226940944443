
import { mapActions, mapGetters } from 'vuex';
import UISearchSymbol from '@/components/ui/organism/UISearchSymbol';
import { debounce } from 'lodash';

export default {
  name: 'SearchSymbolWrapper',
  components: { UISearchSymbol },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    inputError: {
      type: String,
      default: '',
    },
    searchType: {
      type: String,
      default: 'symbol',
    },
    showSearchLabel: {
      type: Boolean,
      default: false,
    },
    searchFieldFocused: {
      type: Boolean,
      default: false,
    },
  },
  computed: mapGetters('search', ['searchRes']),
  methods: {
    search: debounce(mapActions('search', ['search']).search, 200),

    /**
     * Fired by the UISearchSymbol when there's a
     * @param {string} query - The user query.
     */
    onSearchRequest(query) {
      if (query) {
        this.search({ type: this.searchType, query, limit: 10 });
      }
    },

    handleClickAdd(symbol) {
      this.$emit('onAddSymbol', symbol);
    },
  },
};
