
/**
 * @deprecated This component is deprecated and you should use WCard.vue
 */
export default {
  props: {
    padding: {
      type: Boolean,
      default: true,
    },
    extraPadding: {
      type: Number,
      default: 0,
    },
    disableStyle: {
      type: Boolean,
      default: false,
    },
    borderRounded: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['click'],
  computed: {
    computedClasses() {
      const classes = [];
      if (this.padding) {
        classes.push('p-4');
      }
      if (this.extraPadding > 0) {
        classes.push(`p-${this.extraPadding + 4}`);
      }
      if (!this.disableStyle) {
        classes.push('ui-card', 'border', 'border-neutral-4');
      }
      if (this.borderRounded) {
        classes.push('rounded-lg');
      }
      return classes;
    },
  },
};
