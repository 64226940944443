import Vue from 'vue';

export interface WindowInstanceProps {
  innerWidth: number;
  isMobileResponsive: () => boolean;
}

export type TypeWithWindowInstance = {
  $windowInstance: WindowInstanceProps;
};

const WindowInstance: WindowInstanceProps = new Vue({
  data() {
    return {
      innerWidth: window.innerWidth,
    };
  },
  created() {
    window.addEventListener('resize', () => {
      if (this.innerWidth != window.innerWidth) {
        // @ts-ignore TODO fix typescript error
        this.innerWidth = window.innerWidth;
      }
    });
  },
  methods: {
    isMobileResponsive(): boolean {
      // TODO take this value from some constants file
      return this.innerWidth <= 640;
    },
    isAboveXlResponsive(): boolean {
      // TODO take this value from some constants file
      return this.innerWidth >= 1200;
    },
  },
});

export function getWindowInstance(context: TypeWithWindowInstance) {
  return context.$windowInstance;
}

export default (
  context: unknown,
  inject: (arg1: unknown, arg2: unknown) => void
) => inject('windowInstance', WindowInstance);
