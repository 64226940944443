import { UIActionIcon, UIMenuMode, UIMessagingState } from './types';

/**
 * This vuex module is used for communication between UI components. If you are
 * using this, it's likely a hack around something. Please note it and open a
 * ticket.
 */
import { NSRootModule } from '~/store/types';
import mutations from './mutations';

const state: UIMessagingState = {
  /** The current navbar title as delegated by some component.
   *
   * WAL-1017: This hack is used to inject a page title into the navbar.
   * Ideally the navbar should pull this from the metadata of a component.
   */
  pageTitle: '',
  pageNested: false,
  pageActionIcon: UIActionIcon.Profile,
  pageActionRoute: '/404',
  pageMenuMode: UIMenuMode.BurgerMenu,
  customBackActionEvent: false,
};

export default {
  namespaced: true,
  state,
  mutations,
} as NSRootModule<UIMessagingState>;
