const recommendationLimit = 5;

export default {
  init(context) {
    context.commit('INIT');
  },
  getSimilarCompanies(context, companyProfileId) {
    context.commit('SET_SIMILAR_COMPANIES_LOADING', true);
    return this.dispatch('api/getSimilarCompaniesRecommendation', {
      limit: recommendationLimit,
      page: context.state.similarCompaniesPage,
      companyProfileId: companyProfileId
    })
      .then(res => {
        context.commit('SET_SIMILAR_COMPANIES', res.data.recommendations);
        if (res.data.reset) {
          context.state.similarCompaniesPage == 1 &&
            context.commit('SET_VIEW_MORE_VISIBLE', false);
          context.commit('RESET_SIMILAR_COMPANIES_PAGE');
        }
      })
      .catch(err => {
        context.commit('SET_SIMILAR_COMPANIES_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_SIMILAR_COMPANIES_LOADING', false);
      });
  },
  getNextSimilarCompanies(context, companyProfileId) {
    context.commit('INCREASE_SIMILAR_COMPANIES_PAGE');
    return context.dispatch('getSimilarCompanies', companyProfileId);
  },

  // General
  followRecommendation(context, profileId) {
    return this.dispatch('api/followProfile', profileId).then(res => {
      context.commit('UPDATE_SIMILAR_COMPANY', res.data);
    });
  }
};
