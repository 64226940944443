
import { mapActions, mapGetters } from 'vuex';
import UITrendingIndexWidget from '~/components/ui/organism/UITrendingIndexWidget';

export default {
  Name: 'TrendingIndicesWidgetWrapper',
  components: { UITrendingIndexWidget },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  async fetch() {
    if (process.client) {
      await this.getTrendingIndices();
    }
  },
  computed: {
    ...mapGetters('trendingIndicesWidget', [
      'trendingIndices',
      'trendingIndicesLoading',
    ]),
  },
  methods: {
    ...mapActions('trendingIndicesWidget', [
      'getTrendingIndices',
      'getNextTrendingIndices',
    ]),
    handleLoadMore() {
      this.getNextTrendingIndices();
    },
  },
};
