export const industries = (state) => state.industries;
export const loading = (state) => state.loading;
export const followedIndustryloading = (state) => state.followedIndustryloading;
export const error = (state) => state.error;
export const followedIndustryerror = (state) => state.followedIndustryerror;
export const allIndustriesFollowed = (state) =>
  !!state.industries && !state.industries.some((i) => !i.isFollowed);
export const allStocksIndustriesFollowed = (state) =>
  !!state.industries &&
  !state.industries.some((i) => i.name != 'Cryptocurrencies' && !i.isFollowed);
