
import IconErrorOutline from './IconErrorOutline.vue';
import IconSettingsOutline from './IconSettingsOutline.vue';
import WIconPlusOutline from './WIconPlusOutline';
import WIconSearchOutline from './WIconSearchOutline';

const ICON_TABLE = {
  error: { outline: IconErrorOutline, fill: null },
  plus: { outline: WIconPlusOutline, fill: null },
  search: { outline: WIconSearchOutline, fill: null },
  settings: { outline: IconSettingsOutline, fill: null },
};

export const SupportedIcons = Object.keys(ICON_TABLE);
export function iconSupportsMode(icon, mode) {
  return !!ICON_TABLE[icon][mode];
}

/**
 * This component serves as the main icon delegate.
 * Feed it the icon you want, it will render the component you need.
 *
 * **This component replaces the legacy `Icon`. You should prioritize**
 * **using/extending it over the legacy solution. If you need an icon that**
 * **is missing, read on.**
 *
 * Note that, however, if you can avoid using this component, you should. It is
 * not a cheap component as it dynamically looks up which component to render,
 * meaning that it has a **huge** dependency graph.
 *
 * In order to find what Icons are supported, import `SupportedIcons`:
 *
 * ```javascript
 * import WIcon, { SupportedIcons } from 'WIcon';
 * SupportedIcons.forEach((iconName) => {
 *   console.log(`WIcon supports: ${iconName}`);
 * });
 * ```
 *
 * To find whether a given `Icon` supports `fill` or `outline`, import and
 * query `iconSupportsMode(icon, mode)`:
 *
 * ```javascript
 * import WIcon, { iconSupportsMode } from 'WIcon';
 * console.assert(iconSupportsMode('plus', 'outline'));
 * ```
 *
 * In order to extend this component with an `Icon` you need, you need to do
 * two things:
 *   1. Create your icon **as a Vue component**:
 *     `components/ui/icons/Example.vue` with your `svg` code inlined, based on
 *     the precedent in `WIconPlusOutline.vue`. Ensure you remove all
 *     `fill/stroke` properties from `svg` attributes and/or dynamically inject
 *     them.
 *   2. Register your new component within `WIcon.vue` `ICON_TABLE`.
 *
 * After doing this `WIcon` should find your new icon in `Storybook` and the
 * `app`. Have fun using it!
 */
export default {
  // TODO: Ideally, load this dynamically.
  components: {
    IconErrorOutline,
    IconSettingsOutline,
    WIconPlusOutline,
    WIconSearchOutline,
  },
  props: {
    /**
     * The actual icon name you want to render. If you need to know the
     * possible Icon `type`s, you may import `SupportedIcons`.
     *
     * @example "plus"
     */
    type: {
      type: String,
      required: true,
      validator: (v) => Object.keys(ICON_TABLE).includes(v),
    },
    /**
     * Whether the icon is to render in outline or fill mode.
     * Note that not all icons support both modes.
     * @values outline fill
     */
    mode: {
      type: String,
      required: false,
      default: 'outline',
      validator: (v) => ['outline', 'fill'].includes(v),
    },
    /**
     * The CSS color to use for the element.
     * If `null`, the icon will inherit the parent `fill` property.
     */
    color: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * The size of the icon. Accepts `css` values so feel free to use relative
     * values.
     */
    size: {
      type: [String, Number],
      required: false,
      default: 20,
    },
  },
  computed: {
    comp() {
      return ICON_TABLE[this.type][this.mode];
    },
    injectedSize() {
      return typeof this.size === 'string' || this.size instanceof String
        ? this.size
        : `${this.size}px`;
    },
  },
};
