
import Searchbar from './Searchbar.vue';

import { ComponentIds } from '~/utils/componentIds';

export default {
  name: 'NavContent',
  components: {
    Searchbar,
  },
  props: {
    searchPopupShown: {
      type: Boolean,
      required: true,
    },
    mode: {
      type: String,
      required: true,
      validator: (v) => ['search', 'title', 'portfolio'].includes(v),
    },
    pageTitle: {
      type: String,
      default: '',
      required: false,
    },
  },
  emits: ['focus', 'blur', 'input', 'popup-shown', 'onOpenHelpCenter'],
  data() {
    return {
      showPortfolioDropdownList: false,
      portfoliosInDropdown: [],
    };
  },
  computed: {
    searchbarId() {
      return ComponentIds.SearchbarId;
    },
  },
};
