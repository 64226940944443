
/**
 * Link component
 * Is able to open a link in a new tab if the `open-in-new-tab` prop is set to `true`
 * @component
 * @example
 * <Link href="https://google.com" open-in-new-tab>
 *   <span>Google</span>
 * </Link>
 */
export default {
  props: {
    /**
     * Link URL
     * @type {string}
     * @required
     * @example https://google.com
     */
    href: {
      type: String,
      required: true,
    },
    /**
     * Open link in new tab
     * @type {boolean}
     * @default false
     * @example true
     */
    openInNewTab: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    /**
     * Check if the link is external
     * @returns {boolean}
     */
    isExternal() {
      return !this.href.startsWith('/') && !this.href.startsWith('#');
    },
  },
};
