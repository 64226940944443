
import CreatePostModalWrapper from './CreatePostModalWrapper';
// import ImageSliderModalWrapper from './ImageSliderModalWrapper';
import SelectProfileModalWrapper from './SelectProfileModalWrapper';
import MentionCardWrapper from './MentionCardWrapper';
import CreatePortfolioModalWrapper from './CreatePortfolioModalWrapper';
import PasswordResetModalWrapper from './PasswordResetModalWrapper';
import CreatePortfolioOperationModalWrapper from './CreatePortfolioOperationModalWrapper';
import UILegalDisclaimerModal from '~/components/ui/organism/UILegalDisclaimerModal.vue';
import PortfolioEmptyStateModal from '~/components/shared/modals/PortfolioEmptyStateModal.vue';
import NewsUpdateModalWrapper from '~/components/shared/modals/NewsUpdateModalWrapper.vue';
import HelpCenterWrapper from '~/components/ui/helpCenter/HelpCenterWrapper.vue';
import CompareTickersModalWrapper from './CompareTickersModalWrapper.vue';
import ComparePortfoliosModalWrapper from './ComparePortfoliosModalWrapper.vue';
import ManageTeamPortfolioModalWrapper from './ManageTeamPortfolioModalWrapper';
import CreateLeagueModalWrapper from './CreateLeagueModalWrapper';
import { mapGetters } from 'vuex';

export default {
  name: 'Modals',
  components: {
    MentionCardWrapper,
    SelectProfileModalWrapper,
    CreatePostModalWrapper,
    CreatePortfolioModalWrapper,
    // ImageSliderModalWrapper,
    PasswordResetModalWrapper,
    CreatePortfolioOperationModalWrapper,
    PortfolioEmptyStateModal,
    NewsUpdateModalWrapper,
    HelpCenterWrapper,
    CompareTickersModalWrapper,
    ComparePortfoliosModalWrapper,
    UILegalDisclaimerModal,
    ManageTeamPortfolioModalWrapper,
    CreateLeagueModalWrapper,
  },
  computed: {
    ...mapGetters('onboarding', ['hasCompletedTutorial']),
    ...mapGetters('authentication', ['profile', 'isUserAdmin']),
    ...mapGetters('modals', [
      'passwordResetModal',
      'createPortfolioOperationModal',
      'portfolioEmptyStateModal',
      'showLegalDisclaimerModal',
      'helpCenterObjectRes',
      'compareTickersModal',
      'comparePortfoliosModal',
      'manageTeamPortfolioModal',
      'showCreatePostModalRes',
      'createLeagueModal',
    ]),
    routeName() {
      return this.$route.name?.split('_')[0];
    },
    showPortfolioEmptyStateModal() {
      return (
        this.portfolioEmptyStateModal.show &&
        ['portfolios'].indexOf(this.routeName) != -1
      );
    },
  },
};
