export default {
  init(context) {
    context.commit('INIT');
  },
  getTrendingCryptos(context, query) {
    context.commit('SET_TRENDING_CRYPTOS_LOADING', true);
    this.dispatch('api/getTrendingCryptos', query)
      .then((res) => {
        context.commit('SET_TRENDING_CRYPTOS', res.data);
      })
      .catch((err) => {
        context.commit('SET_TRENDING_CRYPTOS_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_TRENDING_CRYPTOS_LOADING', false);
      });
  },
};
