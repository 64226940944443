export default {
  GET_CRYPTO_INIT: (state) => {
    state.crypto = null;
    state.getCrypto.loading = true;
    state.getCrypto.error = null;
  },

  SET_CRYPTO: (state, payload) => {
    state.crypto = payload;
  },
  SET_GET_CRYPTO_ERROR: (state, payload) => {
    state.getCrypto.error = payload;
  },
  SET_GET_CRYPTO_LOADING: (state, payload) => {
    state.getCrypto.loading = payload;
  },
  GET_NEXT_CRYPTO_INIT: (state) => {
    state.getNextCrypto.loading = true;
    state.getNextCrypto.error = null;
  },
  APPEND_CRYPTO: (state, payload) => {
    state.crypto = [...state.crypto, ...payload];
  },
  SET_GET_NEXT_CRYPTO_ERROR: (state, payload) => {
    state.getNextCrypto.error = payload;
  },
  SET_GET_NEXT_CRYPTO_LOADING: (state, payload) => {
    state.getNextCrypto.loading = payload;
  },
  UPDATE_CRYPTO_IS_FOLLOWED(state, payload) {
    if (state.crypto) {
      const index = state.crypto.findIndex(
        (c) => c.symbolId === payload.symbolId
      );

      if (index != -1) {
        state.crypto[index].isFollowed = payload.company.isFollowed;
      }
    }
  },
  UPDATE_CRYPTO_PRICE_CHANGE_IS_FOLLOWED(state, payload) {
    if (state.crypto) {
      const index = state.crypto.findIndex(
        (c) => c.symbolId === payload.symbolId
      );

      if (index != -1) {
        state.crypto[index].isFollowingPriceChange =
          !state.crypto[index].isFollowingPriceChange;
      }
    }
  },
  SET_FOLLOW_CRYPTO_ERROR(state, err) {
    state.followCrypto.error = err;
  },
};
