import { Context, Middleware } from '@nuxt/types';
import { NavMeta, NavMetaConcrete, PageMeta } from '~/types/nuxt/page';
import { UIActionIcon, UIMenuMode } from '~/store/modules/ui-messaging/types';

import { GlobalStore } from '~/store/types';
import { isString } from 'lodash';

// NOTICE FOR DEVS ----------------------------------------
// THIS CODE IS OBSOLETE. DO NOT USE IT
// Instead, check the mixin topNavHeader in utils/mixins.js:6
// --------------------------------------------------------

const logWithContext = (message: string) =>
  console.log(`pageMetaMiddleware: ${message}`);

/** Handles the nav meta patches */
function patchNav(navMeta: NavMeta[], store: GlobalStore) {
  logWithContext('patchNav');

  // Traverses the whole .meta.nav chain and composes a final state-of-truth
  // given the chain.
  const patch: NavMetaConcrete = navMeta.reduce(
    (patch, part) => ({
      nested: part.nested !== undefined ? part.nested : patch.nested,
      title: part.title !== undefined ? part.title : patch.title,
      action:
        part.action !== undefined
          ? {
              // Route may be null, need to check for undefineds.
              route:
                part.action.route !== undefined
                  ? part.action.route
                  : patch.action?.route,
              icon:
                part.action.icon !== undefined
                  ? part.action.icon
                  : patch.action?.icon,
            }
          : patch.action,
      menuMode: part.menuMode !== undefined ? part.menuMode : patch.menuMode,
    }),
    {
      nested: false,
      title: '',
      action: {
        route: undefined,
        icon: UIActionIcon.Profile,
      },
      menuMode: UIMenuMode.BurgerMenu,
    }
  ) as NavMetaConcrete;

  logWithContext('setting props');

  store.commit('ui-messaging/SET_PAGE_NESTED', patch.nested);
  store.commit('ui-messaging/SET_PAGE_MENU_MODE', patch.menuMode);

  if (patch.title) {
    logWithContext('SET_PAGE_TITLE');

    store.commit(
      'ui-messaging/SET_PAGE_TITLE',
      isString(patch.title)
        ? patch.title
        : !patch.title.translate
        ? patch.title.value
        : // @ts-ignore TODO
          window.$nuxt.$t(patch.title.value)
    );
  }
  if (patch.action) {
    store.commit('ui-messaging/SET_PAGE_ACTION', {
      route: patch.action.route,
      icon: patch.action.icon,
    });
  }
}

/** Returns the nav chain given a meta chain */
function mapPageMetaByKey<T>(
  pageMetaChain: (PageMeta | undefined)[],
  key: keyof PageMeta
): T[] {
  return pageMetaChain
    .filter((x) => x && x[key])
    .map((x) => (x as PageMeta)[key]) as T[];
}

/**
 * Handles the .meta key for each nuxt page.
 *
 * All nuxt pages might have a .meta property that allows `nuxt` to commit
 * side-effects based on said property. However, `nuxt` emits an array of
 * `meta` properties. Given nested pages (`_portfolioId.vue/settings.vue`), the
 * nuxt engine will push in the following "chain": `[_portfolioId.meta,
 * _portfolioId.settings.meta]`.
 */
const pageMetaMiddleware: Middleware = (context: Context) => {
  logWithContext('initial');

  const { route, store } = context;
  if (route.meta === undefined) {
    return;
  }

  // route.meta returns all the nested page meta objects as a chain.
  const pageMetaChain = route.meta;

  // This object contains handlers for specific keys in the route.meta type.
  // This allows for partial reduction and moduralizing meta sections into
  // keys. Sorry for the FP, was the only flexible way.
  type MetaChainHandler = (
    arg0: (PageMeta | undefined)[],
    arg1: keyof PageMeta
  ) => void;
  const META_KEY_HANDLERS: {
    [key in keyof PageMeta]: MetaChainHandler;
  } = {
    nav: (metaChain, key) => patchNav(mapPageMetaByKey(metaChain, key), store),
  };

  // For each handler, call it.
  (
    Object.entries(META_KEY_HANDLERS) as [keyof PageMeta, MetaChainHandler][]
  ).forEach(([k, v]) => {
    v(pageMetaChain as PageMeta[], k);
  });
};

export default pageMetaMiddleware;
