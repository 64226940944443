import { routeBelongsToLocale } from '@/utils/routeNames';

export default async function (context) {
  const { route, app } = context;
  const appLocale = app.i18n.locale;

  console.debug('Locale middleware triggering', {
    context,
    routeName: route?.name,
  });

  // if the URL the user is visiting does not belong to the app's locale, redirect them
  // make sure to keep query parameters
  if (!routeBelongsToLocale(route, appLocale)) {
    {
      // preserve query parameters from the current route
      const queryParams = new URLSearchParams(route.query).toString();

      // the route to redirect to
      const to = app.localePath(
        `${route.path}${queryParams ? '?' + queryParams : ''}`
      );

      console.debug(
        'Locale middleware. Current route does not match the app locale. Switching locales',
        {
          from: route,
          to: to,
          appLocale: appLocale,
        }
      );

      return context.redirect(to);
    }
  } else
    console.debug('Locale middleware. Current route belongs to locale', {
      route,
    });
}
