
import Vue, { PropType } from 'vue';
import { mapActions } from 'vuex';
import CloseIcon from '~/components/ui/icons/CloseIcon.vue';

/**
 * Represents a modal.
 * @todo Extend the functionality of this modal and ideally remove the 'close'
 * event so that this modal closes itself via routes. That requires a huge
 * refactor on how modals work on this app.
 */
export default Vue.extend({
  components: {
    CloseIcon,
  },
  props: {
    /**
     * The modal title to use. This is.
     */
    title: {
      type: String as PropType<string>,
      default: '',
    },
    hasButtons: {
      type: Boolean,
      default: false,
    },
    hasSubmitBtn: {
      type: Boolean,
      default: false,
    },
    hasCancelBtn: {
      type: Boolean,
      default: false,
    },
    cancelBtnText: {
      type: String,
      default: '',
    },
    submitBtnText: {
      type: String,
      default: '',
    },
    submitBtnState: {
      type: String,
      default: 'enabled',
    },
    submitButtonTooltip: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: String,
      default: 'screen-md',
    },
    width: {
      type: String,
      default: 'full',
    },
    paddingX: {
      type: String,
      default: '6',
    },
    paddingY: {
      type: String,
      default: '4',
    },
    fullScreen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalHeight: '',
      bottomOffset: 0,
    };
  },
  computed: {
    widthClass() {
      return 'max-w-' + this.maxWidth + ' w-' + this.width;
    },
    paddingClass() {
      return 'px-' + this.paddingX + ' py-' + this.paddingY;
    },
    showSubmit() {
      return this.hasButtons || this.hasSubmitBtn;
    },
    showCancel() {
      return this.hasButtons || this.hasCancelBtn;
    },
    mobileContentStyle() {
      let res = 'top: 80px;';
      if (this.fullScreen) {
        res += `bottom: ${this.bottomOffset}px;`;
      } else if (this.showSubmit || this.showCancel) {
        res += `bottom: ${this.bottomOffset}px;`;
      } else {
        res += `bottom: ${this.bottomOffset}px;`;
      }
      return res;
    },
  },
  created() {
    const body = document.body;
    body.classList.add('overflow-y-hidden');
    this.bottomOffset = this.fullScreen ? 0 : 58;
    this.modalHeight = this.fullScreen ? 'calc(100dvh - 176px)' : '100%';
  },
  beforeDestroy() {
    const body = document.body;
    body.classList.remove('overflow-y-hidden');
  },
  methods: {
    ...mapActions('modals', ['hideAllModals']),
  },
});
