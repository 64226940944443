export default {
  getCrypto(context, payload) {
    context.commit('GET_CRYPTO_INIT');
    return this.dispatch('api/searchStocks', payload)
      .then((res) => {
        context.commit('SET_CRYPTO', res.data);
      })
      .catch((err) => {
        context.commit('SET_GET_CRYPTO_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_GET_CRYPTO_LOADING', false);
      });
  },
  getNextCrypto(context, payload) {
    context.commit('GET_NEXT_CRYPTO_INIT');
    return this.dispatch('api/searchStocks', payload)
      .then((res) => {
        context.commit('APPEND_CRYPTO', res.data);
      })
      .catch((err) => {
        context.commit('SET_GET_NEXT_CRYPTO_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_GET_NEXT_CRYPTO_LOADING', false);
      });
  },
  followCompany(context, payload) {
    return this.dispatch('api/followProfile', payload.companyId)
      .then((res) => {
        context.commit('UPDATE_CRYPTO_IS_FOLLOWED', {
          company: res.data,
          symbolId: payload.symbolId,
        });
      })
      .catch((err) => {
        context.commit('SET_FOLLOW_CRYPTO_ERROR', err);
      });
  },
  followStockPriceChange(context, payload) {
    return this.dispatch('api/followStockChange', payload)
      .then(() => {
        context.commit('UPDATE_CRYPTO_PRICE_CHANGE_IS_FOLLOWED', payload);
      })
      .catch((err) => {
        context.commit('SET_FOLLOW_CRYPTO_ERROR', err);
      });
  },
};
