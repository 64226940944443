import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../store/index.ts'), 'store/index.ts')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/types.ts'), 'types.ts')
  resolveStoreModules(require('../store/utils.ts'), 'utils.ts')
  resolveStoreModules(require('../store/modules/users/index.js'), 'modules/users/index.js')
  resolveStoreModules(require('../store/modules/ui-messaging/index.ts'), 'modules/ui-messaging/index.ts')
  resolveStoreModules(require('../store/modules/symbolProfile/index.js'), 'modules/symbolProfile/index.js')
  resolveStoreModules(require('../store/modules/stocks/index.js'), 'modules/stocks/index.js')
  resolveStoreModules(require('../store/modules/shared/index.js'), 'modules/shared/index.js')
  resolveStoreModules(require('../store/modules/searchPage/index.js'), 'modules/searchPage/index.js')
  resolveStoreModules(require('../store/modules/search/index.ts'), 'modules/search/index.ts')
  resolveStoreModules(require('../store/modules/recommendations/index.js'), 'modules/recommendations/index.js')
  resolveStoreModules(require('../store/modules/profile/index.js'), 'modules/profile/index.js')
  resolveStoreModules(require('../store/modules/post/index.js'), 'modules/post/index.js')
  resolveStoreModules(require('../store/modules/portfolio/index.js'), 'modules/portfolio/index.js')
  resolveStoreModules(require('../store/modules/onboarding/index.js'), 'modules/onboarding/index.js')
  resolveStoreModules(require('../store/modules/notifications/index.js'), 'modules/notifications/index.js')
  resolveStoreModules(require('../store/modules/news/index.js'), 'modules/news/index.js')
  resolveStoreModules(require('../store/modules/modals/index.js'), 'modules/modals/index.js')
  resolveStoreModules(require('../store/modules/messaging/index.js'), 'modules/messaging/index.js')
  resolveStoreModules(require('../store/modules/market/index.js'), 'modules/market/index.js')
  resolveStoreModules(require('../store/modules/leagues/index.js'), 'modules/leagues/index.js')
  resolveStoreModules(require('../store/modules/layouts/index.js'), 'modules/layouts/index.js')
  resolveStoreModules(require('../store/modules/industry/index.js'), 'modules/industry/index.js')
  resolveStoreModules(require('../store/modules/home/index.js'), 'modules/home/index.js')
  resolveStoreModules(require('../store/modules/hashtags/index.js'), 'modules/hashtags/index.js')
  resolveStoreModules(require('../store/modules/favoritePosts/index.js'), 'modules/favoritePosts/index.js')
  resolveStoreModules(require('../store/modules/cryptoProfile/index.js'), 'modules/cryptoProfile/index.js')
  resolveStoreModules(require('../store/modules/crypto/index.js'), 'modules/crypto/index.js')
  resolveStoreModules(require('../store/modules/companyProfile/index.js'), 'modules/companyProfile/index.js')
  resolveStoreModules(require('../store/modules/communityPortfolios/index.js'), 'modules/communityPortfolios/index.js')
  resolveStoreModules(require('../store/modules/authentication/index.js'), 'modules/authentication/index.js')
  resolveStoreModules(require('../store/modules/api/index.js'), 'modules/api/index.js')
  resolveStoreModules(require('../store/modules/api/actions.js'), 'modules/api/actions.js')
  resolveStoreModules(require('../store/modules/api/getters.js'), 'modules/api/getters.js')
  resolveStoreModules(require('../store/modules/api/mutations.js'), 'modules/api/mutations.js')
  resolveStoreModules(require('../store/modules/authentication/actions.js'), 'modules/authentication/actions.js')
  resolveStoreModules(require('../store/modules/authentication/getters.js'), 'modules/authentication/getters.js')
  resolveStoreModules(require('../store/modules/authentication/mutations.js'), 'modules/authentication/mutations.js')
  resolveStoreModules(require('../store/modules/communityPortfolios/actions.js'), 'modules/communityPortfolios/actions.js')
  resolveStoreModules(require('../store/modules/communityPortfolios/getters.js'), 'modules/communityPortfolios/getters.js')
  resolveStoreModules(require('../store/modules/communityPortfolios/mutations.js'), 'modules/communityPortfolios/mutations.js')
  resolveStoreModules(require('../store/modules/companyProfile/actions.js'), 'modules/companyProfile/actions.js')
  resolveStoreModules(require('../store/modules/companyProfile/getters.js'), 'modules/companyProfile/getters.js')
  resolveStoreModules(require('../store/modules/companyProfile/mutations.js'), 'modules/companyProfile/mutations.js')
  resolveStoreModules(require('../store/modules/crypto/actions.js'), 'modules/crypto/actions.js')
  resolveStoreModules(require('../store/modules/crypto/getters.js'), 'modules/crypto/getters.js')
  resolveStoreModules(require('../store/modules/crypto/mutations.js'), 'modules/crypto/mutations.js')
  resolveStoreModules(require('../store/modules/cryptoProfile/actions.js'), 'modules/cryptoProfile/actions.js')
  resolveStoreModules(require('../store/modules/cryptoProfile/getters.js'), 'modules/cryptoProfile/getters.js')
  resolveStoreModules(require('../store/modules/cryptoProfile/mutations.js'), 'modules/cryptoProfile/mutations.js')
  resolveStoreModules(require('../store/modules/favoritePosts/actions.js'), 'modules/favoritePosts/actions.js')
  resolveStoreModules(require('../store/modules/favoritePosts/getters.js'), 'modules/favoritePosts/getters.js')
  resolveStoreModules(require('../store/modules/favoritePosts/mutations.js'), 'modules/favoritePosts/mutations.js')
  resolveStoreModules(require('../store/modules/hashtags/actions.js'), 'modules/hashtags/actions.js')
  resolveStoreModules(require('../store/modules/hashtags/getters.js'), 'modules/hashtags/getters.js')
  resolveStoreModules(require('../store/modules/hashtags/mutations.js'), 'modules/hashtags/mutations.js')
  resolveStoreModules(require('../store/modules/home/actions.js'), 'modules/home/actions.js')
  resolveStoreModules(require('../store/modules/home/getters.js'), 'modules/home/getters.js')
  resolveStoreModules(require('../store/modules/home/mutations.js'), 'modules/home/mutations.js')
  resolveStoreModules(require('../store/modules/industry/actions.js'), 'modules/industry/actions.js')
  resolveStoreModules(require('../store/modules/industry/getters.js'), 'modules/industry/getters.js')
  resolveStoreModules(require('../store/modules/industry/mutations.js'), 'modules/industry/mutations.js')
  resolveStoreModules(require('../store/modules/layouts/actions.js'), 'modules/layouts/actions.js')
  resolveStoreModules(require('../store/modules/layouts/getters.js'), 'modules/layouts/getters.js')
  resolveStoreModules(require('../store/modules/layouts/mutations.js'), 'modules/layouts/mutations.js')
  resolveStoreModules(require('../store/modules/leagues/actions.js'), 'modules/leagues/actions.js')
  resolveStoreModules(require('../store/modules/leagues/getters.js'), 'modules/leagues/getters.js')
  resolveStoreModules(require('../store/modules/leagues/mutations.js'), 'modules/leagues/mutations.js')
  resolveStoreModules(require('../store/modules/market/actions.js'), 'modules/market/actions.js')
  resolveStoreModules(require('../store/modules/market/getters.js'), 'modules/market/getters.js')
  resolveStoreModules(require('../store/modules/market/mutations.js'), 'modules/market/mutations.js')
  resolveStoreModules(require('../store/modules/messaging/actions.js'), 'modules/messaging/actions.js')
  resolveStoreModules(require('../store/modules/messaging/getters.js'), 'modules/messaging/getters.js')
  resolveStoreModules(require('../store/modules/messaging/mutations.js'), 'modules/messaging/mutations.js')
  resolveStoreModules(require('../store/modules/modals/actions.js'), 'modules/modals/actions.js')
  resolveStoreModules(require('../store/modules/modals/getters.js'), 'modules/modals/getters.js')
  resolveStoreModules(require('../store/modules/modals/mutations.js'), 'modules/modals/mutations.js')
  resolveStoreModules(require('../store/modules/news/actions.js'), 'modules/news/actions.js')
  resolveStoreModules(require('../store/modules/news/getters.js'), 'modules/news/getters.js')
  resolveStoreModules(require('../store/modules/news/mutations.js'), 'modules/news/mutations.js')
  resolveStoreModules(require('../store/modules/notifications/actions.js'), 'modules/notifications/actions.js')
  resolveStoreModules(require('../store/modules/notifications/getters.js'), 'modules/notifications/getters.js')
  resolveStoreModules(require('../store/modules/notifications/mutations.js'), 'modules/notifications/mutations.js')
  resolveStoreModules(require('../store/modules/onboarding/actions.js'), 'modules/onboarding/actions.js')
  resolveStoreModules(require('../store/modules/onboarding/getters.js'), 'modules/onboarding/getters.js')
  resolveStoreModules(require('../store/modules/onboarding/mutations.js'), 'modules/onboarding/mutations.js')
  resolveStoreModules(require('../store/modules/portfolio/actions.js'), 'modules/portfolio/actions.js')
  resolveStoreModules(require('../store/modules/portfolio/getters.js'), 'modules/portfolio/getters.js')
  resolveStoreModules(require('../store/modules/portfolio/mutations.js'), 'modules/portfolio/mutations.js')
  resolveStoreModules(require('../store/modules/post/actions.js'), 'modules/post/actions.js')
  resolveStoreModules(require('../store/modules/post/getters.js'), 'modules/post/getters.js')
  resolveStoreModules(require('../store/modules/post/mutations.js'), 'modules/post/mutations.js')
  resolveStoreModules(require('../store/modules/profile/actions.js'), 'modules/profile/actions.js')
  resolveStoreModules(require('../store/modules/profile/getters.js'), 'modules/profile/getters.js')
  resolveStoreModules(require('../store/modules/profile/mutations.js'), 'modules/profile/mutations.js')
  resolveStoreModules(require('../store/modules/recommendations/actions.js'), 'modules/recommendations/actions.js')
  resolveStoreModules(require('../store/modules/recommendations/getters.js'), 'modules/recommendations/getters.js')
  resolveStoreModules(require('../store/modules/recommendations/mutations.js'), 'modules/recommendations/mutations.js')
  resolveStoreModules(require('../store/modules/search/actions.ts'), 'modules/search/actions.ts')
  resolveStoreModules(require('../store/modules/search/getters.ts'), 'modules/search/getters.ts')
  resolveStoreModules(require('../store/modules/search/mutations.ts'), 'modules/search/mutations.ts')
  resolveStoreModules(require('../store/modules/search/types.ts'), 'modules/search/types.ts')
  resolveStoreModules(require('../store/modules/searchPage/actions.js'), 'modules/searchPage/actions.js')
  resolveStoreModules(require('../store/modules/searchPage/getters.js'), 'modules/searchPage/getters.js')
  resolveStoreModules(require('../store/modules/searchPage/mutations.js'), 'modules/searchPage/mutations.js')
  resolveStoreModules(require('../store/modules/shared/actions.js'), 'modules/shared/actions.js')
  resolveStoreModules(require('../store/modules/shared/getters.js'), 'modules/shared/getters.js')
  resolveStoreModules(require('../store/modules/shared/mutations.js'), 'modules/shared/mutations.js')
  resolveStoreModules(require('../store/modules/stocks/actions.js'), 'modules/stocks/actions.js')
  resolveStoreModules(require('../store/modules/stocks/getters.js'), 'modules/stocks/getters.js')
  resolveStoreModules(require('../store/modules/stocks/mutations.js'), 'modules/stocks/mutations.js')
  resolveStoreModules(require('../store/modules/symbolProfile/actions.js'), 'modules/symbolProfile/actions.js')
  resolveStoreModules(require('../store/modules/symbolProfile/getters.js'), 'modules/symbolProfile/getters.js')
  resolveStoreModules(require('../store/modules/symbolProfile/mutations.js'), 'modules/symbolProfile/mutations.js')
  resolveStoreModules(require('../store/modules/ui-messaging/mutations.ts'), 'modules/ui-messaging/mutations.ts')
  resolveStoreModules(require('../store/modules/ui-messaging/types.ts'), 'modules/ui-messaging/types.ts')
  resolveStoreModules(require('../store/modules/users/actions.js'), 'modules/users/actions.js')
  resolveStoreModules(require('../store/modules/users/getters.js'), 'modules/users/getters.js')
  resolveStoreModules(require('../store/modules/users/mutations.js'), 'modules/users/mutations.js')
  resolveStoreModules(require('../store/modules/widgets/trendingTickers/index.js'), 'modules/widgets/trendingTickers/index.js')
  resolveStoreModules(require('../store/modules/widgets/trendingIndices/index.js'), 'modules/widgets/trendingIndices/index.js')
  resolveStoreModules(require('../store/modules/widgets/trendingCryptos/index.js'), 'modules/widgets/trendingCryptos/index.js')
  resolveStoreModules(require('../store/modules/widgets/similarCryptos/index.js'), 'modules/widgets/similarCryptos/index.js')
  resolveStoreModules(require('../store/modules/widgets/similarCompanies/index.js'), 'modules/widgets/similarCompanies/index.js')
  resolveStoreModules(require('../store/modules/widgets/popularCryptos/index.js'), 'modules/widgets/popularCryptos/index.js')
  resolveStoreModules(require('../store/modules/widgets/mutualRecommendation/index.js'), 'modules/widgets/mutualRecommendation/index.js')
  resolveStoreModules(require('../store/modules/widgets/companyFollowers/index.js'), 'modules/widgets/companyFollowers/index.js')
  resolveStoreModules(require('../store/modules/settings/password-reset/index.js'), 'modules/settings/password-reset/index.js')
  resolveStoreModules(require('../store/modules/settings/account-management/index.js'), 'modules/settings/account-management/index.js')
  resolveStoreModules(require('../store/modules/settings/account-management/actions.js'), 'modules/settings/account-management/actions.js')
  resolveStoreModules(require('../store/modules/settings/account-management/getters.js'), 'modules/settings/account-management/getters.js')
  resolveStoreModules(require('../store/modules/settings/account-management/mutations.js'), 'modules/settings/account-management/mutations.js')
  resolveStoreModules(require('../store/modules/settings/password-reset/actions.js'), 'modules/settings/password-reset/actions.js')
  resolveStoreModules(require('../store/modules/settings/password-reset/getters.js'), 'modules/settings/password-reset/getters.js')
  resolveStoreModules(require('../store/modules/settings/password-reset/mutations.js'), 'modules/settings/password-reset/mutations.js')
  resolveStoreModules(require('../store/modules/widgets/companyFollowers/actions.js'), 'modules/widgets/companyFollowers/actions.js')
  resolveStoreModules(require('../store/modules/widgets/companyFollowers/getters.js'), 'modules/widgets/companyFollowers/getters.js')
  resolveStoreModules(require('../store/modules/widgets/companyFollowers/mutations.js'), 'modules/widgets/companyFollowers/mutations.js')
  resolveStoreModules(require('../store/modules/widgets/mutualRecommendation/actions.js'), 'modules/widgets/mutualRecommendation/actions.js')
  resolveStoreModules(require('../store/modules/widgets/mutualRecommendation/getters.js'), 'modules/widgets/mutualRecommendation/getters.js')
  resolveStoreModules(require('../store/modules/widgets/mutualRecommendation/mutations.js'), 'modules/widgets/mutualRecommendation/mutations.js')
  resolveStoreModules(require('../store/modules/widgets/popularCryptos/actions.js'), 'modules/widgets/popularCryptos/actions.js')
  resolveStoreModules(require('../store/modules/widgets/popularCryptos/getters.js'), 'modules/widgets/popularCryptos/getters.js')
  resolveStoreModules(require('../store/modules/widgets/popularCryptos/mutations.js'), 'modules/widgets/popularCryptos/mutations.js')
  resolveStoreModules(require('../store/modules/widgets/similarCompanies/actions.js'), 'modules/widgets/similarCompanies/actions.js')
  resolveStoreModules(require('../store/modules/widgets/similarCompanies/getters.js'), 'modules/widgets/similarCompanies/getters.js')
  resolveStoreModules(require('../store/modules/widgets/similarCompanies/mutations.js'), 'modules/widgets/similarCompanies/mutations.js')
  resolveStoreModules(require('../store/modules/widgets/similarCryptos/actions.js'), 'modules/widgets/similarCryptos/actions.js')
  resolveStoreModules(require('../store/modules/widgets/similarCryptos/getters.js'), 'modules/widgets/similarCryptos/getters.js')
  resolveStoreModules(require('../store/modules/widgets/similarCryptos/mutations.js'), 'modules/widgets/similarCryptos/mutations.js')
  resolveStoreModules(require('../store/modules/widgets/trendingCryptos/actions.js'), 'modules/widgets/trendingCryptos/actions.js')
  resolveStoreModules(require('../store/modules/widgets/trendingCryptos/getters.js'), 'modules/widgets/trendingCryptos/getters.js')
  resolveStoreModules(require('../store/modules/widgets/trendingCryptos/mutations.js'), 'modules/widgets/trendingCryptos/mutations.js')
  resolveStoreModules(require('../store/modules/widgets/trendingIndices/actions.js'), 'modules/widgets/trendingIndices/actions.js')
  resolveStoreModules(require('../store/modules/widgets/trendingIndices/getters.js'), 'modules/widgets/trendingIndices/getters.js')
  resolveStoreModules(require('../store/modules/widgets/trendingIndices/mutations.js'), 'modules/widgets/trendingIndices/mutations.js')
  resolveStoreModules(require('../store/modules/widgets/trendingTickers/actions.js'), 'modules/widgets/trendingTickers/actions.js')
  resolveStoreModules(require('../store/modules/widgets/trendingTickers/getters.js'), 'modules/widgets/trendingTickers/getters.js')
  resolveStoreModules(require('../store/modules/widgets/trendingTickers/mutations.js'), 'modules/widgets/trendingTickers/mutations.js')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs|ts)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
