import { unlocalizeRouteName } from '@/utils/routeNames';

export default async function (context) {
  const { route, store, app, redirect, $auth } = context;

  console.debug('Register middleware triggering', {
    routeName: route?.name,
  });

  const unlocalizedRouteName = unlocalizeRouteName(route);

  // TODO this skipping should not be handled in the middleware itself
  // the register middleware should skip the following pages
  const routeNamesToSkip = ['loading', 'iframe-session-check'];

  // use this as a flag to determine whether the current route should be skipped
  let shouldSkipRoute = false;

  if (!unlocalizedRouteName)
    console.error('Register middleware could not unlocalize route name.');
  else
    routeNamesToSkip.forEach((routeNameToSkip) => {
      if (unlocalizedRouteName.startsWith(routeNameToSkip)) {
        console.debug('Register middleware should skip route', {
          routeNameToSkip,
        });

        // make sure to skip the route
        if (!shouldSkipRoute) shouldSkipRoute = true;
      }
    });

  if (shouldSkipRoute) {
    console.debug('Register middleware skipping route', route?.name);

    return;
  }

  const apiUsername = store.getters['authentication/apiUsername'];

  console.debug('Register middleware user check');

  if (!apiUsername) {
    // No API user created - redirect to login
    console.debug(
      'Register middleware found no API username. Redirecting to login.'
    );

    await $auth.loginWith('oidc');

    return;
  } else {
    console.debug('Register middleware detected user.');
  }
}
