
const contextualizeLogMessage = (message) => `Axeptio.vue. ${message}`;

import { mapGetters } from 'vuex';
import { AxeptioTokenName } from '~/utils/behavior/axeptio';

export default {
  name: 'Axeptio',
  computed: {
    ...mapGetters('authentication', ['profile']),
    locale() {
      const locale = this.$i18n.locale;
      return locale;
    },
  },
  watch: {
    locale() {
      const locale = this.locale;
      window._axcb = window._axcb || [];
      window._axcb.push(function (sdk) {
        sdk.setCookiesVersion('skade-' + locale);
      });
    },
  },
  mounted() {
    console.debug(contextualizeLogMessage('Axeptio component mounting'));

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this;
    window._axcb = window._axcb || [];

    window._axcb.push(function (sdk) {
      const query = context.$route.query;

      if (query[AxeptioTokenName]) {
        const token = query[AxeptioTokenName];
        console.debug(
          contextualizeLogMessage(
            'Axeptio token found in query params. Setting token to SDK'
          ),
          token
        );

        sdk.setToken(token);
      } else {
        console.debug(
          contextualizeLogMessage('Axeptio token not found in query params')
        );
      }

      sdk.on(
        'cookies:complete',
        function (
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          choices
        ) {
          console.debug(contextualizeLogMessage('Cookies complete event'));

          const token = sdk?.getToken?.();
          if (token) {
            console.debug(
              contextualizeLogMessage('Axeptio token found in the SDK'),
              token
            );
          } else {
            console.error(
              contextualizeLogMessage(
                'Axeptio token not found when it was expected'
              )
            );
          }
        }
      );
      sdk.on('consent:saved', function () {
        console.debug(contextualizeLogMessage('Consent saved.'));
      });
      sdk.on('ready', async () => {
        console.debug(contextualizeLogMessage('Axeptio is ready'));

        if (query[AxeptioTokenName]) {
          console.debug(
            contextualizeLogMessage('Removing axeptio token query param')
          );

          const url = new URL(window.location.href);
          url.searchParams.delete(AxeptioTokenName);

          window.history.replaceState({}, document.title, url);
        }
      });
    });
  },
  methods: {
    hideAxeptioButton() {
      console.debug(contextualizeLogMessage('Hiding axeptio button'));
      window.hideAxeptioButton?.();
    },
    showAxeptioButton() {
      console.debug(contextualizeLogMessage('Showing axeptio button'));
      window.showAxeptioButton?.();
    },
  },
};
