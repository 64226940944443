
import UISimplePortfolioCard from '@/components/ui/organism/UISimplePortfolioCard.vue';
import PortfolioSearchResultCard from '~/components/ui/molecule/PortfolioSearchResultCard.vue';
import SearchIcon from '~/components/ui/icons/SearchIcon.vue';
import { debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'UIPortfolioSearchWithCards',
  components: { UISimplePortfolioCard, PortfolioSearchResultCard, SearchIcon },
  props: {
    portfoliosToAdd: {
      type: Array,
      default() {
        return [];
      },
    },
    buttonState: {
      type: String,
      default: 'disabled',
    },
    showResultsDropdown: {
      type: Boolean,
      default: true,
    },
    limitReachedText: {
      type: String,
      default: '',
    },
    comparisonLimitReached: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      query: '',
      pickerVisible: false,
      currentIndex: 0,
    };
  },
  computed: {
    ...mapGetters('portfolio', ['portfolioSearchRes', 'getPortfolioByIdRes']),
    filteredResults() {
      if (!this.portfolioSearchRes?.data) return [];

      // specific case for indices
      if (!this.getPortfolioByIdRes?.data) {
        return this.portfolioSearchRes.data;
      }

      return this.portfolioSearchRes.data.filter(
        (portfolio) => portfolio.id !== this.getPortfolioByIdRes.data.id
      );
    },
  },
  methods: {
    ...mapActions('portfolio', ['portfolioSearch']),
    handleSearchDebounce: debounce(function (query) {
      if (!query) {
        return;
      }
      this.portfolioSearch({
        limit: 10,
        page: 1,
        searchString: query,
      });
    }, 300),
    handleSearch() {
      this.handleSearchDebounce(this.query);
    },
    handleClickOutside() {
      this.pickerVisible = false;
    },
    handleSubmit() {
      this.$emit('onSubmit');
    },
    getPortfolioCardClasses(portfolioId) {
      const isPortfolioPicked = this.portfoliosToAdd.some((portfolio) => {
        return portfolio.id === portfolioId;
      });

      if (isPortfolioPicked) {
        return 'bg-primary-2 pointer';
      }

      return this.comparisonLimitReached
        ? 'opacity-50 cursor-not-allowed'
        : 'pointer';
    },
    handleCardClick(portfolio) {
      this.query = '';
      this.$emit('addPortfolio', portfolio);
    },
    clearSearchQuery() {
      this.query = '';
    },
  },
};
