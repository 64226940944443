// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".notification-number{background:#ff173e;transform:translate(30%,-35%)}.header-notification{position:relative}.header-notification-btn{cursor:pointer}.header-notification-btn:hover{background:var(--color-neutral-3)}.header-notification-dropdown{display:none;position:absolute;right:0}.header-notification-dropdown-content{background:var(--color-secondary);width:440px}.header-notification-dropdown-content .load-more-btn:hover{background:var(--color-primary-1)}.header-notification-dropdown-content .scroll-list{max-height:70vh;min-height:150px;overflow-y:scroll}.header-notification-dropdown-content ::-webkit-scrollbar{width:4px}.header-notification-dropdown-content ::-webkit-scrollbar-thumb{background:var(--color-primary);border-radius:10px}.header-notification-dropdown-content .title{color:var(--color-text);font-style:normal;line-height:22px;padding:20px;width:100%}.header-notification:hover .header-notification-dropdown{display:block}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"a": "#5a80fb",
	"b": "#6b88fc",
	"c": "#7b91fc",
	"d": "#8899fd",
	"e": "#95a2fd",
	"f": "#a1abfe",
	"g": "#adb4fe",
	"h": "#b8bdfe",
	"i": "#c3c6ff",
	"j": "#cdcfff",
	"k": "#d8d9ff",
	"l": "#e2e2ff",
	"m": "#ececff",
	"n": "#f5f5ff",
	"o": "#fff"
};
module.exports = ___CSS_LOADER_EXPORT___;
