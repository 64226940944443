
import { mapGetters } from 'vuex';
import NewsUpdateModal from '@/components/ui/modal/NewsUpdateModal/NewsUpdateModal';

export default {
  name: 'NewsUpdateModalWrapper',
  components: {
    NewsUpdateModal,
  },
  computed: {
    ...mapGetters('authentication', ['profile']),
  },
  methods: {
    validate() {
      this.isValid =
        (this.tutorialStep == 0 && !!this.birthdate) || this.isLocalAccount;
    },
  },
};
