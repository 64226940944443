
import { mapActions, mapGetters } from 'vuex';
import UISearchProfile from '@/components/ui/organism/UISearchProfile';
import { debounce } from 'lodash';

export default {
  name: 'SearchProfileWrapper',
  components: { UISearchProfile },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    inputError: {
      type: String,
      default: '',
    },
    portfolioUsers: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: mapGetters('search', ['searchRes']),
  watch: {
    'searchRes.data.mentions'() {
      this.filterAlreadyAddedUsersFromSearch();
    },
  },
  methods: {
    ...mapActions('search', ['search']),
    handleSearchDebounce: debounce(function (query) {
      if (query) {
        this.search({ type: '@', query, limit: 10 });
      }
    }, 300),
    handleSearch(query) {
      this.handleSearchDebounce(query);
    },
    filterAlreadyAddedUsersFromSearch() {
      if (this.searchRes.data.mentions?.length === 0) {
        return this.searchRes.data.mentions;
      }

      const filteredSearchRes = this.searchRes.data.mentions;

      // Filtering out users that are already added to the portfolio
      this.portfolioUsers.forEach((profile) => {
        const profileIndex = filteredSearchRes.findIndex(
          (existingProfile) => existingProfile.profileId === profile.profileId
        );

        if (profileIndex !== -1) {
          filteredSearchRes.splice(profileIndex, 1);
        }
      });
      return filteredSearchRes;
    },
  },
};
