import actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

const state = () => ({
  trendingIndices: [],
  trendingIndicesError: null,
  trendingIndicesLoading: false,
  trendingIndicesPage: 1,
});

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
