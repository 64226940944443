
import { mapActions } from 'vuex';
import CloseIcon from '~/components/ui/icons/CloseIcon.vue';

export default {
  name: 'MultipleImageDisplay',
  components: {
    CloseIcon,
  },
  props: {
    showCloseBtn: {
      type: Boolean,
      default: false,
    },
    allowCarousel: {
      type: Boolean,
      default: false,
    },
    images: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    ...mapActions('modals', ['showImageSliderModal']),
    handleCarouselModal(index) {
      this.showImageSliderModal({ navigateTo: index, images: this.images });
    },
  },
};
