import { SearchHistory } from '~/types/api/search';
import { SearchResult } from '~/types/models/search';
import { StoreValue } from '~/store/types';

export const SEARCH_HISTORY_QUERY_COUNT = 10;

export interface SearchState {
  /**
   * Whether the search history should be shown.
   */
  showSearchHistory: boolean;

  /**
   * Whether the search popup is shown.
   */
  searchPopupShown: boolean;

  /**
   * The user's search query
   */
  query: string;

  /**
   * Stores search results for the user's current search query.
   */
  userSearch: StoreValue<SearchResult | null>;

  /**
   * Stores `SEARCH_HISTORY_QUERY_COUNT` of the user's latest history.
   */
  searchHistory: StoreValue<SearchHistory>;

  /**
   * @deprecated This state property is part of legacy code. It's functionality
   * is neither type-checked, nor properly validated. Do not use this if you
   * can help it. It is incredibly bloated and does too much all in one
   * property.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  search: { data: any; loading: any; error: any };
}
