import { MutationsCreator } from '@/store/utils';

export default {
  INIT: (state, isSuccess) => {
    state.success = false;
    state.error = null;
  },
  SET_SUCCESS: (state, isSuccess) => {
    state.success = isSuccess;
  },
  SET_ERROR: (state, err) => {
    state.error = err;
    state.success = false;
  },
  SET_RESET_PASSWORD_TOKEN_EMAIL_SUCCESS(state, isSuccess) {
    state.resetPasswordTokenEmailSuccess = isSuccess;
  },
  SET_RESET_PASSWORD_CODE(state, code) {
    state.resetPasswordToken = code;
  }
};
