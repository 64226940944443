// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ui-portfolio-search-with-cards .search-input{height:30px}.ui-portfolio-search-with-cards .search-input:focus-visible{outline:none}.ui-portfolio-search-with-cards .results-container{max-height:calc(100% - 50px);overflow-y:auto}.ui-portfolio-search-with-cards .formulate-input[data-classification=text] .formulate-input-element{align-items:center;background:#f5f5f5;background:var(--Neutral-Neutral-3,#f5f5f5);border-radius:12px;display:flex;gap:8px;padding:0 16px}.ui-portfolio-search-with-cards .formulate-input[data-classification=text] .formulate-input-element:focus-within{border:1px solid #2f54eb}.ui-portfolio-search-with-cards .formulate-input[data-classification=text] input{border:none;font-size:13px;height:40px;line-height:20px;padding:0}.ui-portfolio-search-with-cards .formulate-input[data-classification=text] .formulate-input-label--before{font-size:13px;font-weight:600;line-height:20px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"a": "#5a80fb",
	"b": "#6b88fc",
	"c": "#7b91fc",
	"d": "#8899fd",
	"e": "#95a2fd",
	"f": "#a1abfe",
	"g": "#adb4fe",
	"h": "#b8bdfe",
	"i": "#c3c6ff",
	"j": "#cdcfff",
	"k": "#d8d9ff",
	"l": "#e2e2ff",
	"m": "#ececff",
	"n": "#f5f5ff",
	"o": "#fff"
};
module.exports = ___CSS_LOADER_EXPORT___;
