import actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

const state = () => ({
  topGainerPortfolios: { data: null, loading: null, error: null },
  nextTopGainerPortfoliosLoading: false,
  topGainerPortfoliosPagination: { page: 1, limit: 20, showMore: false },
  followedUsersTopGainerPortfolios: { data: null, loading: null, error: null },
  nextFollowedUsersTopGainerPortfoliosLoading: false,
  followedUsersTopGainerPortfoliosPagination: {
    page: 1,
    limit: 20,
    showMore: false,
  },
});

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
