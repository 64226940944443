import { MutationsCreator } from '@/store/utils';
import { findAndReplace } from '@/utils';

export default {
  INIT: state => {
    state.trendingCryptos = [];
    state.trendingCryptosError = null;
    state.trendingCryptosLoading = false;
  },

  SET_TRENDING_CRYPTOS_LOADING: (state, isLoading) => {
    state.trendingCryptosLoading = isLoading;
  },

  SET_TRENDING_CRYPTOS: (state, payload) => {
    state.trendingCryptos = payload;
  },

  SET_TRENDING_CRYPTOS_ERROR: (state, er) => {
    state.trendingCryptosError = er;
  },
};
