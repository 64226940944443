/**
 * Exposes a functional API client.
 */

import { Axios, AxiosRequestConfig, AxiosResponse } from 'axios';
import { curry } from 'lodash';

/**
 * Combines the given arguments to yield a valid URI path.
 *
 * @param args - The path parts. These may end in `/` or not, the parser will
 *   strip all superfluous `/`'s from the string.
 * @returns The parsed path.
 */
export function apiPath(...args: string[]): string {
  return args.reduce(
    (full: string, part: string) =>
      full.endsWith('/') && part.startsWith('/')
        ? full + part.substring(1)
        : !(full.endsWith('/') || part.startsWith('/'))
        ? full + '/' + part
        : full + part,
    '/'
  );
}

interface LocalizedClient {
  // This interface mirrors the axios interface, hence the "any"s.
  /* eslint-disable @typescript-eslint/no-explicit-any */
  get: <T = any, R = AxiosResponse<T>, D = any>(
    arg0?: AxiosRequestConfig<D>
  ) => Promise<R>;
  delete: <T = any, R = AxiosResponse<T>, D = any>(
    arg0?: AxiosRequestConfig<D>
  ) => Promise<R>;
  head: <T = any, R = AxiosResponse<T>, D = any>(
    arg0?: AxiosRequestConfig<D>
  ) => Promise<R>;
  options: <T = any, R = AxiosResponse<T>, D = any>(
    arg0?: AxiosRequestConfig<D>
  ) => Promise<R>;
  post: <T = any, R = AxiosResponse<T>, D = any>(
    arg0: D,
    arg1?: AxiosRequestConfig<D>
  ) => Promise<R>;
  put: <T = any, R = AxiosResponse<T>, D = any>(
    arg0: D,
    arg1?: AxiosRequestConfig<D>
  ) => Promise<R>;
  patch: <T = any, R = AxiosResponse<T>, D = any>(
    arg0: D,
    arg1?: AxiosRequestConfig<D>
  ) => Promise<R>;
  postForm: <T = any, R = AxiosResponse<T>, D = any>(
    arg0: D,
    arg1?: AxiosRequestConfig<D>
  ) => Promise<R>;
  putForm: <T = any, R = AxiosResponse<T>, D = any>(
    arg0: D,
    arg1?: AxiosRequestConfig<D>
  ) => Promise<R>;
  patchForm: <T = any, R = AxiosResponse<T>, D = any>(
    arg0: D,
    arg1?: AxiosRequestConfig<D>
  ) => Promise<R>;
  /* eslint-enable @typescript-eslint/no-explicit-any */
}

type LocalizedClientBuilder = (
  arg0: Axios,
  endpoint?: string
) => LocalizedClient;

/**
 * Localizes the axios client to the given starting route.
 *
 * Note that this does not support `axios.request` (but the rest of the API is
 * supported)
 *
 * @param basePath The base localized path.
 *
 * @see plugins/api/search.ts For an example.
 */
export default (basePath: string): LocalizedClientBuilder =>
  (axios: Axios, endpoint = '') => {
    const path = apiPath(basePath, ...endpoint.split('/'));
    return {
      get: curry(axios.get, 2)(path),
      delete: curry(axios.delete, 2)(path),
      head: curry(axios.head, 2)(path),
      options: curry(axios.options, 2)(path),
      post: curry(axios.post, 3)(path),
      put: curry(axios.put, 3)(path),
      patch: curry(axios.patch, 3)(path),
      postForm: curry(axios.postForm, 3)(path),
      putForm: curry(axios.putForm, 3)(path),
      patchForm: curry(axios.patchForm, 3)(path),
    };
  };
