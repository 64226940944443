
import UITrendingStockWidget from '~/components/ui/widgets/symbol/UITrendingStockWidget.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  Name: 'TrendingCryptosWidgetWrapper',
  components: { UITrendingStockWidget },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: 5,
    },
  },
  async fetch() {
    if (process.client) {
      await this.getTrendingCryptos({
        limit: this.limit,
        trendingType: 'GAINERS',
        type: 0,
      });
    }
  },
  computed: {
    ...mapGetters('trendingCryptosWidget', [
      'trendingCryptos',
      'trendingCryptosLoading',
    ]),
  },
  methods: {
    ...mapActions('trendingCryptosWidget', ['getTrendingCryptos']),
  },
};
