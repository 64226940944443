import { render, staticRenderFns } from "./NewsUpdateModal.vue?vue&type=template&id=e9620e92"
import script from "./NewsUpdateModal.vue?vue&type=script&lang=js"
export * from "./NewsUpdateModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WButton: require('/skade-pwa/components/ui/atom/WButton/WButton.vue').default,Modal: require('/skade-pwa/components/ui/atom/Modal.vue').default})
