
import SunIcon from '~/components/ui/icons/SunIcon.vue';
import { isDesktop } from '~/utils/screenSizes';

export default {
  name: 'IsEuMarketOpen',
  components: { SunIcon },

  computed: {
    isOpen() {
      return this.isDayTime && this.isWeekday;
    },
    isDayTime() {
      return (
        this.$dayjs().tz('Europe/Paris').format('H') >= 9 &&
        (this.$dayjs().tz('Europe/Paris').format('H') < 17 ||
          (this.$dayjs().tz('Europe/Paris').format('H') == 17 &&
            this.$dayjs().tz('Europe/Paris').format('m') <= 30))
      );
    },
    isWeekday() {
      return (
        this.$dayjs().tz('Europe/Paris').format('d') > 0 &&
        this.$dayjs().tz('Europe/Paris').format('d') < 6
      );
    },
    isDesktopSize() {
      return isDesktop();
    },
  },
};
