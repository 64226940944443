import * as getters from './getters';

import actions from './actions';
import mutations from './mutations';

const state = () => ({
  // TODO rename this from tutorial to onboarding to reduce confusion
  hasCompletedTutorial: false,
  hasSeenTips: false,
  tutorialStep: 0,
  tutorialLoading: false,
  tipsStep: 1,
  tipsTotalStep: 5,
  recommendedCompanies: null,
  recommendedCompaniesLoading: false,
  recommendedCompaniesSearchQuery: '',
  nextCompaniesLoading: false,
  companiesOffset: 1,
  showMoreCompanies: true,
  recommendedProfiles: null,
  recommendedProfilesLoading: false,
  recommendedProfilesSearchQuery: '',
  nextProfilesLoading: false,
  profilesOffset: 1,
  showMoreProfiles: true,
  birthdateSuccess: false,
  birthdateErrors: null,
  userOnboardingQuestions: [],
  userOnboardingAnswers: [],
});

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
