
import { mapActions, mapGetters } from 'vuex';
import UIRecommendationWidget from '@/components/ui/widgets/recommendations/UIRecommendationWidget';
import { inAppRedirect } from '~/utils/route-redirects';

export default {
  name: 'SimilarCompaniesWidgetWrapper',
  components: { UIRecommendationWidget },
  props: {
    companyProfileId: {
      type: String,
      required: true,
    },
  },
  async fetch() {
    await this.getSimilarCompanies(this.companyProfileId);
  },
  computed: {
    ...mapGetters('similarCompaniesWidget', [
      'similarCompanies',
      'similarCompaniesLoading',
      'similarCompaniesError',
      'isViewMoreVisible',
    ]),
  },
  methods: {
    ...mapActions('similarCompaniesWidget', [
      'getSimilarCompanies',
      'getNextSimilarCompanies',
      'followRecommendation',
    ]),
    handleLoadMore() {
      this.getNextSimilarCompanies(this.companyProfileId);
    },
    handleGoToProfile(profileId) {
      inAppRedirect('/profile/' + profileId);
    },
    handleFollow(profileId) {
      this.followRecommendation(profileId);
    },
  },
};
