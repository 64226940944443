
import UserImgInfo from '@/components/ui/molecule/UserImgInfo';
import { getMedia } from '@/utils/mixins';
import { inAppRedirect } from '~/utils/route-redirects';

export default {
  name: 'UIDeletedPost',
  components: {
    UserImgInfo,
  },
  mixins: [getMedia],
  props: {
    createdDate: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    isReply: {
      type: Boolean,
      default: false,
    },
    disableHover: {
      type: Boolean,
      default: false,
    },
    showCard: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    fromNow() {
      const fromNow = this.$dayjs(this.createdDate)
        .subtract('1', 's')
        .startOf('s')
        .fromNow();
      return fromNow;
    },
  },
  beforeCreate: function () {
    this.$options.components.UIPost =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('@/components/ui/organism/UIPost').default;
  },
  methods: {
    goToPost(postId) {
      inAppRedirect(`/post/${postId}`);
    },
  },
};
