export const recommendedCompanies = (state) => state.recommendedCompanies;
export const recommendedCompaniesLoading = (state) =>
  state.recommendedCompaniesLoading;
export const nextCompaniesLoading = (state) => state.nextCompaniesLoading;
export const recommendedProfiles = (state) => state.recommendedProfiles;
export const recommendedProfilesLoading = (state) =>
  state.recommendedProfilesLoading;
export const nextProfilesLoading = (state) => state.nextProfilesLoading;
export const tutorialLoading = (state) => state.tutorialLoading;
export const showMoreCompanies = (state) =>
  state.showMoreCompanies &&
  !state.recommendedCompaniesLoading &&
  !state.nextCompaniesLoading;
export const showMoreProfiles = (state) =>
  state.showMoreProfiles &&
  !state.nextProfilesLoading &&
  !state.recommendedProfilesLoading;
