import actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

const state = () => ({
  mutualFollows: [],
  topInvestors: { data: [], loading: null, error: null },
  mutualFollowsPage: 1,
  mutualFollowsError: null,
  mutualFollowsLoading: false,
  mutualIndustries: [],
  mutualIndustriesPage: 1,
  mutualIndustriesError: null,
  mutualIndustriesLoading: false,
});

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
