import Vue from 'vue';
import Highcharts from 'highcharts';
import HighchartsVue from 'highcharts-vue';
import stockInit from 'highcharts/modules/stock';
import colors from '@/assets/styles/vars/_highchartsColors.scss';

if (typeof Highcharts === 'object') {
  stockInit(Highcharts);
}

Highcharts.theme = {
  colors: Object.values(colors),
  chart: { style: { fontFamily: 'Poppins' } }
};

Highcharts.setOptions(Highcharts.theme);

Vue.use(HighchartsVue, {
  highcharts: Highcharts
});
