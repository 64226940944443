export default {
  INIT: (state) => {
    state.trendingIndices = [];
    state.trendingIndicesError = null;
    state.trendingIndicesLoading = false;
  },

  SET_TRENDING_INDICES_LOADING: (state, isLoading) => {
    state.trendingIndicesLoading = isLoading;
  },

  SET_TRENDING_INDICES: (state, payload) => {
    state.trendingIndices = payload;
  },

  SET_TRENDING_INDICES_ERROR: (state, er) => {
    state.trendingIndicesError = er;
  },

  RESET_TRENDING_INDICES_PAGE: (state) => {
    state.trendingIndicesPage = 0;
  },

  INCREASE_TRENDING_INDICES_PAGE: (state) => {
    state.trendingIndicesPage++;
  },
};
