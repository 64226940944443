
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Truncate',
  props: {
    singleLine: {
      type: Boolean,
      default: false,
    },
    disableStyle: {
      type: Boolean,
      default: false,
    },
    text: {
      required: true,
      type: String,
    },
    maxLength: {
      default: 40,
      type: Number,
    },
    isUserName: {
      type: Boolean,
      default: false,
    },
    profile: {
      type: Object,
      default: null,
    },
    hasMentionCardEvent: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters('modals', ['currentMentionCard']),
  },
  methods: {
    ...mapActions('modals', ['mouseEnterMention', 'mouseLeaveMention']),
    handleMouseEnter(elem) {
      if (!this.canShowCard()) {
        return;
      }
      const boundingBox = elem.getBoundingClientRect();
      const payload = {
        pos: {
          top: window.top.scrollY + boundingBox.bottom + 8,
          left: boundingBox.x,
        },
        profile: this.profile,
        id: this.profile.profileId,
      };
      this.mouseEnterMention(payload);
    },
    handleMouseLeave() {
      if (!this.canShowCard()) {
        return;
      }
      this.mouseLeaveMention(this.profile.profileId);
    },
    canShowCard() {
      return (
        this.hasMentionCardEvent &&
        this.isUserName &&
        !(this.profile === null || this.profile === undefined)
      );
    },
  },
};
