
import { mapActions } from 'vuex';
import CompareTickersModal from '@/components/ui/modal/CompareTickersModal.vue';

export default {
  name: 'CompareTickersModalWrapper',
  components: { CompareTickersModal },
  methods: {
    ...mapActions('modals', ['toggleCompareTickersModal']),
    handleClose() {
      this.toggleCompareTickersModal();
    },
  },
};
