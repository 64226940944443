
import { inAppRedirect } from '~/utils/route-redirects';

export default {
  name: 'NotificationPortfolioComment',
  props: {
    notif: {
      required: true,
      type: Object,
    },
  },
  methods: {
    goToTarget() {
      inAppRedirect({
        path: `/portfolios/${this.notif.targetId}`,
        query: { topic: 'conversation', postId: this.notif.postId },
      });
    },
  },
};
