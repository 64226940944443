
import { isMobile } from '~/utils/screenSizes';
import CloseIcon from '~/components/ui/icons/CloseIcon.vue';
export default {
  name: 'TickerSearchResultCard',
  components: { CloseIcon },
  props: {
    symbol: {
      type: Object,
      default: null,
    },
    avatarUri: {
      type: String,
      default: '',
    },
    symbolName: {
      type: String,
      default: '',
    },
    companyName: {
      type: String,
      default: '',
    },
    price: {
      type: Number,
      default: 0,
    },
    change: {
      type: Number,
      default: 0,
    },
    changePercent: {
      type: Number,
      default: 0,
    },
    currencySymbol: {
      type: String,
      default: '',
    },
    showCloseIcon: {
      type: Boolean,
      default: false,
    },
    isMainSymbol: {
      type: Boolean,
      default: false,
    },
    showColorIndicator: {
      type: Boolean,
      default: false,
    },
    indicatorColor: {
      type: String,
      default: '',
    },
  },
  computed: {
    isMobileSize() {
      return isMobile();
    },
    getIndicatorColorClass() {
      return 'bg-' + this.indicatorColor;
    },
  },
  methods: {
    handleTickerRemove() {
      this.$emit('onTickerRemove', this.symbol);
    },
  },
};
