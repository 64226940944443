// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".onboarding-tour-card{border-radius:4px;box-shadow:1px 1px 4px rgba(0,0,0,.1);position:relative;width:300px;z-index:1000}.onboarding-tour-card:after{border:6px solid transparent;border-top:6px solid var(--color-secondary);content:\" \";filter:drop-shadow(0 3px 2px var(--color-shadow));-ms-filter:\"progid:DXImageTransform.Microsoft.Dropshadow(OffX=0, OffY=3, Color='#444')\";filter:\"progid:DXImageTransform.Microsoft.Dropshadow(OffX=0, OffY=3, Color='#444')\";height:0;margin-left:-6px;pointer-events:none;position:absolute;width:0}.onboarding-tour-card.arrow-top:after{left:150px;top:-12px;transform:rotate(180deg)}.onboarding-tour-card.arrow-left:after{left:-5px;top:10%;transform:rotate(90deg)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"a": "#5a80fb",
	"b": "#6b88fc",
	"c": "#7b91fc",
	"d": "#8899fd",
	"e": "#95a2fd",
	"f": "#a1abfe",
	"g": "#adb4fe",
	"h": "#b8bdfe",
	"i": "#c3c6ff",
	"j": "#cdcfff",
	"k": "#d8d9ff",
	"l": "#e2e2ff",
	"m": "#ececff",
	"n": "#f5f5ff",
	"o": "#fff"
};
module.exports = ___CSS_LOADER_EXPORT___;
