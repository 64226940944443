
import { mapActions, mapGetters } from 'vuex';
import UISelectProfileModal from '@/components/ui/organism/UISelectProfileModal';

export default {
  name: 'SelectProfileModalWrapper',
  components: { UISelectProfileModal },
  computed: {
    ...mapGetters('authentication', ['me']),
    ...mapGetters('modals', ['showSelectProfileModalRes'])
  },
  methods: {
    ...mapActions('authentication', ['setProfileId']),
    ...mapActions('modals', ['showSelectProfileModal']),
    handleSelectProfile(profileId) {
      this.setProfileId(profileId);

      if (this.showSelectProfileModalRes) {
        this.showSelectProfileModal();
      }
    }
  }
};
