import { AxiosError, AxiosResponse } from 'axios';
import { SearchHistory, SearchType } from '~/types/api/search';

import { NSRootActionTree } from '~/store/types';
import { SearchResult } from '~/types/models/search';
import { SearchState } from './types';
import { isEqual } from 'lodash';

export default {
  showSearchPopup({ commit }) {
    commit('SET_SEARCH_POPUP_SHOWN', true);
  },
  hideSearchPopup({ commit }) {
    commit('SET_SEARCH_POPUP_SHOWN', false);
  },
  clearSearch({ commit }) {
    commit('RESET_USER_SEARCH_RESULTS');
  },
  /**
   * Given a user input string, this parses it and performs an appropriate API
   * query.
   *
   * Whenever a search occurs, do not clear the previous results before setting the new ones, but instead replace them with the news results to reduce UI jankiness
   *
   *
   * @param query - The raw query the user inputted.
   */
  searchForQuery({ commit }, { query }: { query: string }) {
    const QUERY_VALUES_LIMIT = 5;

    if (query.length === 0) {
      commit('RESET_USER_SEARCH_RESULTS');
      return;
    }

    const queryTypes: SearchType[] =
      query[0] === '@'
        ? [SearchType.Profile]
        : query[0] === '#'
        ? [SearchType.Hashtag]
        : Object.values(SearchType); // Search for everything

    const queryShouldBeStripped =
      isEqual(queryTypes, [SearchType.Profile]) ||
      isEqual(queryTypes, [SearchType.Hashtag]);
    const serverFacingQuery = queryShouldBeStripped ? query.slice(1) : query;

    commit('SEARCH_PUSH_SEARCH_FOR_QUERY_BEGIN', { query });
    this.dispatch('api/search', {
      limit: QUERY_VALUES_LIMIT,
      type: queryTypes.join(','),
      query: serverFacingQuery,
    })
      .then((res: AxiosResponse<SearchResult>) => {
        commit('SEARCH_PUSH_RESULT_FOR_QUERY', { query, result: res.data });
      })
      .catch((err) => {
        commit('SEARCH_ERROR_FOR_QUERY', { query, error: err });
      });
  },

  /**
   * Grabs the current user's search history.
   */
  fetchSearchHistory({ commit }) {
    const HISTORY_COUNT = 10;
    this.dispatch('api/getSearchHistory', {
      count: HISTORY_COUNT,
    })
      .then((res: AxiosResponse<SearchHistory>) => {
        commit('SEARCH_HISTORY_SET', res.data);
      })
      .catch((err: AxiosError) => {
        commit('SEARCH_HISTORY_ERROR', err);
      });
  },

  /**
   * @deprecated This action is deprecated and should not be used. It uses an
   *   unsupported, untested and not type-safe search property of this module.
   * @see search.state.search
   * @see searchForQuery
   */
  search({ commit }, payload) {
    return (
      this.dispatch('api/search', payload)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: AxiosResponse<any>) => {
          commit('SET_SEARCH', res.data);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: AxiosError<any>) => {
          commit('SET_ERROR', err);
        })
    );
  },
} as NSRootActionTree<SearchState>;
