
import CloseIcon from '~/components/ui/icons/CloseIcon.vue';
export default {
  name: 'CreatePortfolioOperationModalHeader',
  components: { CloseIcon },
  props: {
    showOperationSwitcher: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      operationOptions: [
        {
          key: 'Stock',
          value: this.$t('assets'),
        },
        {
          key: 'Cash',
          value: this.$t('cash'),
        },
      ],
      selectedOperation: 'Stock',
    };
  },
};
