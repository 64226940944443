/**
 * The API error prefix is used to identify errors that are thrown by the API.
 */
export const ApiErrorPrefix = 'wal_';

/**
 * This function removes the API error prefix from the error code.
 * @param errorCode The error code that is returned by the API.
 * @returns The error code without the API error prefix.
 */
export const removeApiErrorPrefix = (errorCode: string) => {
  return errorCode.replace(ApiErrorPrefix, '');
};

/**
 * This function removes the HTTP status code from the error code.
 * @param errorCode The error code that is returned by the API.
 * @returns The error code without the HTTP status code.
 */
export const removeErrorStatusCode = (errorCode: string) => {
  return errorCode.replace(/\d{3}/, '');
};

/**
 * This function returns the application error message based on the error code.
 * @param errorCode The error code that is returned by the API.
 * @returns The application error message, if it exists.
 */
export const getApplicationError = (errorCode: string) => {
  if (!errorCode) return errorCode;

  return removeErrorStatusCode(removeApiErrorPrefix(errorCode));
};

/**
 * This map contains the application error codes that are returned by the API.
 */
export const ApiApplicationErrorCodes = {
  BadRequest: {
    UniquePortfolioName: '005',
    PortfolioNameExceedsMaximumCharacters: '013',
    MaximumPortfoliosPerProfileReached: '018',
  },
};
