
import { mapGetters } from 'vuex';
// import Modal from '~/components/ui/molecule/Modal.vue';
import { getListedPortfolioEarliestOperationDate } from '@/utils/portfolio-dates/';

export default {
  name: 'CreatePortfolioCashOperationModal',
  components: {},
  mixins: [getListedPortfolioEarliestOperationDate],
  props: {
    isCreateOperationLoading: {
      type: Boolean,
      default: false,
    },
    isAddItemLoading: {
      type: Boolean,
      default: false,
    },
    defaultPortfolio: {
      type: Object,
      default: () => null,
    },
    defaultOperationType: {
      type: String,
      required: true,
      validator: (v) => ['Buy', 'Sell'].includes(v),
    },
    currency: {
      type: String,
      required: true,
    },
    cashAvailable: {
      type: Object,
      default: null,
    },
    daysToSubstract: {
      type: Number,
      default: 2,
    },
    availablePortfolioCurrencies: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['onClose', 'onCashSubmit'],
  data() {
    return {
      error: '',
      price: 0,
      quantity: null,
      maxDate: this.$dayjs()
        .subtract(this.daysToSubstract, 'day')
        .format('YYYY-MM-DD'),
      formValues: {
        operationType: 'Buy',
        portfolioName: '',
        date: this.maxDate, // this.$dayjs().subtract(2, 'day').format('YYYY-MM-DD'),  // this.getMaxDate()
        quantity: 1,
        price: 0,
        chosenCurrency: '', // For STOCK TRANSACTION
        chosenCurrencyCash: '', // For CASH imports
      },
      total: 0,
      totalText: '0',
      conversionRate: 1,
      formIsMultiple: false,
      reset: false,
    };
  },
  computed: {
    ...mapGetters('modals', ['createPortfolioOperationModal']),
    // TODO: Split into a library we inject and call. yesterday is agnostic of
    // the component.
    disabled() {
      if (this.formValues.operationType == 'Sell') {
        if (
          this.cashAvailable[this.formValues.chosenCurrencyCash].value <
          this.total
        ) {
          return true;
        }
      }
      return this.error;
    },
    shouldSubmit() {
      return this.createPortfolioOperationModal.isSubmitting;
    },
    buttonState() {
      if (
        this.formValues.date > this.maxDate ||
        this.formValues.date <= this.minDate
      ) {
        this.$emit('buttonStateChange', 'disabled');
        return 'disabled';
      }
      if (this.total <= 0 || this.disabled) {
        this.$emit('buttonStateChange', 'disabled');
        return 'disabled';
      }
      if (this.isCreateOperationLoading || this.isAddItemLoading) {
        this.$emit('buttonStateChange', 'loading');
        return 'loading';
      }
      this.$emit('buttonStateChange', 'enabled');
      return 'enabled';
    },
    minDate() {
      const listedPortfolioEarliestDate = this.$dayjs(
        this.getListedPortfolioEarliestOperationDate()
      );
      const minDate = this.defaultPortfolio.isEligibleToLadder
        ? listedPortfolioEarliestDate.subtract(1, 'day').format('YYYY-MM-DD')
        : this.$dayjs().subtract(10, 'years').format('YYYY-MM-DD');
      if (!this.createPortfolioOperationModal?.symbol) {
        return minDate;
      }
      const item = this.defaultPortfolio.items.find(
        (i) => i.symbol == this.createPortfolioOperationModal.symbol.name
      );
      if (!item || !item.operations || item.operations.length == 0) {
        return minDate;
      }
      item.operations.sort((a, b) =>
        this.$dayjs(a.OperationDate).isAfter(this.$dayjs(b.OperationDate))
          ? 1
          : -1
      );
      const lastOperationDate = this.$dayjs(item.operations[0].operationDate)
        .subtract(1, 'day')
        .format('YYYY-MM-DD');
      if (this.defaultPortfolio.isEligibleToLadder) {
        return this.$dayjs(minDate).isAfter(lastOperationDate)
          ? minDate
          : lastOperationDate;
      } else {
        return lastOperationDate;
      }
    },
  },
  watch: {
    total() {
      this.total = this.round(this.total);
      this.$emit('totalAmountChanged', this.total);
      this.buttonState;
      this.$emit('chosenCurrency', this.getChosenCurrencyLabel());
    },
    'formValues.date'() {
      this.buttonState;
    },
    'formValues.chosenCurrencyCash'() {
      this.buttonState;
      this.$emit('chosenCurrency', this.getChosenCurrencyLabel());
    },
    shouldSubmit(newVal) {
      if (newVal === true) {
        this.submit(this.createPortfolioOperationModal.multipleTransactions);
      }
    },
  },
  created() {
    this.getDefaultChosenCurrency();
    this.formValues.date = this.maxDate;
    this.formValues.operationType = this.defaultOperationType;
  },

  methods: {
    submit(multipleTransactions) {
      let amount = this.total;
      if (this.formValues.operationType == 'Sell') {
        amount *= -1;
      }
      this.$emit(
        'onCashSubmit',
        {
          currencyCode: this.formValues.chosenCurrencyCash,
          date: this.formValues.date,
          amount: amount,
        },
        multipleTransactions,
        () => {
          this.total = 0;
        }
      );
    },
    getDefaultChosenCurrency() {
      if (!this.defaultPortfolio) {
        this.formValues.chosenCurrency = this.currency;
        return;
      }
      this.formValues.chosenCurrencyCash = this.currency;
    },
    getChosenCurrencyLabel() {
      const currency = this.formValues.chosenCurrencyCash;
      if (
        currency === null ||
        currency === undefined ||
        currency.length !== 3
      ) {
        return '';
      }
      const symbol = this.$display.money.getCurrencySymbol(currency);
      if (symbol !== '') {
        return symbol;
      }
      return currency;
    },
    getToolTip() {
      if (this.formValues.date > this.maxDate) {
        return this.$t('before_date', {
          date: this.maxDate,
        });
      }
      return '';
    },
    getCashAvailableHelp() {
      if (
        this.formValues.operationType == 'Sell' &&
        this.cashAvailable !== undefined &&
        this.cashAvailable !== null
      ) {
        return (
          this.$t('cash_available', {
            date: this.$dayjs(this.formValues.date).format('DD/MM/YYYY'),
          }) +
          ': ' +
          this.cashAvailable[this.formValues.chosenCurrencyCash].value +
          this.$display.money.getCurrencySymbol(
            this.formValues.chosenCurrencyCash
          )
        );
      }
    },
    round(num) {
      return Math.trunc(num * 100) / 100;
    },
    getOperationButtonStyle(operationType) {
      if (this.formValues.operationType == operationType) {
        return 'bg-primary-1 border-primary text-primary border-2';
      } else {
        return 'border';
      }
    },
    resetModalState() {
      this.total = 0;
    },
    handleOperationSwitch(operation) {
      if (operation === 'Buy') {
        this.formValues.operationType = 'Buy';
        this.resetModalState();
      } else if (operation === 'Sell') {
        this.formValues.operationType = 'Sell';
        this.resetModalState();
      }
    },
  },
};
