
import { isMobile } from '~/utils/screenSizes';
import StockOperationCard from '~/components/ui/molecule/StockOperationCard';
import SearchIcon from '~/components/ui/icons/SearchIcon.vue';

export default {
  name: 'UISearchSymbol',
  components: { StockOperationCard, SearchIcon },
  props: {
    searchResults: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    inputError: {
      type: String,
      default: '',
    },
    searchType: {
      type: String,
      default: 'symbol',
    },
    showSearchLabel: {
      type: Boolean,
      defualt: false,
    },
    focusSearchField: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      query: '',
      pickerVisible: false,
      currentIndex: 0,
      placeholderEmpty: true,
    };
  },
  computed: {
    mixedResults() {
      if (!this.searchResults) return [];
      let res = [];
      if (this.searchResults.symbols)
        res = res.concat(this.searchResults.symbols);
      if (this.searchResults.cryptoSymbols)
        res = res.concat(this.searchResults.cryptoSymbols);
      res = res.sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });
      return res;
    },
    isMobileSize() {
      return isMobile();
    },
  },
  mounted() {
    if (this.focusSearchField) this.handleFocus();
  },
  methods: {
    handleClickOutside() {
      this.pickerVisible = false;
    },
    handleClickAdd(asset, index) {
      this.currentIndex = index;
      this.$emit('addSymbol', asset);
      this.query = asset.name;
      this.pickerVisible = false;
    },
    handleFocus() {
      this.pickerVisible = true;
      this.query = '';
      document.getElementById('symbolInputField').focus();
    },
    /**
     * Fired by the formulate search key event.
     * @param {string} query - The user query.
     */
    onSearchInput(query) {
      if (query === '') {
        return;
      }
      this.$emit('search-request', query);
    },
  },
};
