
import { buildAssetUri } from '@/utils/mixins';
import Avatar from 'vue-avatar';

export default {
  name: 'ProfileImg',
  components: {
    Avatar,
  },
  mixins: [buildAssetUri],
  props: {
    src: {
      default: null,
      type: String,
    },
    alt: {
      default: '',
      type: String,
    },
    profileType: {
      default: 'PersonalProfile',
      type: String,
    },
    displayName: {
      default: '',
      type: String,
    },
    size: {
      default: 40,
      type: Number,
    },
    hasBorders: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // TODO replace with utils/behavior/images.ts:2
      defaultUri: require('@/assets/img/unknow-user.jpg'),
      defaultCompanyUri: require('@/assets/img/unknow-company.jpg'),
    };
  },
};
