/**
 * Given a number determines the prefix to be used for the delta
 * representation.
 *
 * Do not use this in vue components. Write your own display method that uses
 * this.
 *
 * @param x - The number
 * @returns The string to use.
 */
export function deltaPrefix(x: number): string {
  return x > 0 ? '+' : '';
}
