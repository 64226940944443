
import UITrendingStockWidget from '~/components/ui/widgets/symbol/UITrendingStockWidget.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  Name: 'TrendingLosersTickersWidgetWrapper',
  components: { UITrendingStockWidget },
  props: {
    limit: {
      type: Number,
      default: 5,
    },
  },
  async fetch() {
    if (process.client) {
      await this.getTrendingTickers({
        limit: this.limit,
        trendingType: 'LOSERS',
        type: 1,
      });
    }
  },
  computed: {
    ...mapGetters('trendingTickersWidget', [
      'trendingLosersTickers',
      'trendingTickersLoading',
    ]),
    tickersList() {
      return this.trendingLosersTickers;
    },
  },
  methods: {
    ...mapActions('trendingTickersWidget', ['getTrendingTickers']),
  },
};
