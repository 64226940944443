/**
 * The minimum operation date for a ranked portfolio
 *
 */
export const getListedPortfolioEarliestOperationDate = {
  methods: {
    getListedPortfolioEarliestOperationDate() {
      const daysToSubstract = 2;
      let earliestDate = this.$dayjs().subtract(daysToSubstract, 'day');

      switch (earliestDate.day()) {
        case 0:
          // Sunday
          earliestDate = earliestDate.subtract(2, 'day');
          break;
        case 6:
          // Saturday
          earliestDate = earliestDate.subtract(2, 'day');
          break;
        default:
          break;
      }
      return earliestDate.format('YYYY-MM-DD');
    },
  },
};
