import actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

const state = () => ({
  // Update Profilename
  profileNameUpdate: {
    success: false,
    error: null,
    isLoading: false,
    isProfileNameTaken: false
  },

  // Update Email
  emailUpdate: {
    success: false,
    error: null,
    isLoading: false,
    isEmailTaken: false
  },

  // Update Birthdate
  birthdateUpdate: {
    success: false,
    error: null,
    isLoading: false,
    isEmailTaken: false
  },
});

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
