export enum UIActionIcon {
  Profile = 'profile',
  Settings = 'settings',
}

export enum UIMenuMode {
  BurgerMenu = 'burgerMenu',
  BackArrow = 'backArrow',
  Empty = 'empty',
}

export interface UIMessagingState {
  /** The current page title */
  pageTitle: string;

  /**
   * Whether the current page is a "nested" page, ie. such a page which forces
   * the navbar into a back position.
   */
  pageNested: boolean;

  /**
   * Which navigation profile icon should be rendered.
   *
   * This property is used to delegate the navbar right icon render.
   */
  pageActionIcon: UIActionIcon;

  /**
   * Which route the action icon should lead to.
   */
  pageActionRoute: string;

  /**
   * Defines the left menu style (wether it's a back arrow or a burger menu).
   */
  pageMenuMode: string;

  /**
   * Can be used to propagate back actions that need something else than pure navigation
   */
  customBackActionEvent: boolean;
}
