
import { mapActions, mapGetters } from 'vuex';
import CustomModal from '~/components/ui/molecule/CustomModal.vue';
import PortfolioSearchResultCard from '~/components/ui/molecule/PortfolioSearchResultCard.vue';
import UIPortfolioSearchWithCards from '~/components/ui/organism/UIPortfolioSearchWithCards.vue';
import CloseIcon from '~/components/ui/icons/CloseIcon.vue';
import { _portfolioComparisonLimit } from '~/utils/comparison-tool-settings.ts';

export default {
  name: 'ComparePortfoliosModal',
  components: {
    CustomModal,
    CloseIcon,
    PortfolioSearchResultCard,
    UIPortfolioSearchWithCards,
  },
  data() {
    return {
      selectedPortfolios: [],
    };
  },
  computed: {
    ...mapGetters('portfolio', [
      'portfoliosToCompareRes',
      'getPortfolioByIdRes',
      'portfolioSearchRes',
    ]),
    ...mapGetters('modals', [
      'comparePortfoliosModal',
      'portfoliosInComparison',
    ]),
    ...mapGetters('authentication', ['profile']),
    numberOfPortfoliosInComparison() {
      return this.portfoliosInComparison + this.selectedPortfolios.length;
    },
    getModalTitle() {
      return (
        this.$t('compare_to') +
        ` (${this.numberOfPortfoliosInComparison}` +
        '/' +
        `${_portfolioComparisonLimit})`
      );
    },
    buttonState() {
      if (
        this.numberOfPortfoliosInComparison <= _portfolioComparisonLimit &&
        this.numberOfPortfoliosInComparison >= 1
      ) {
        return 'enabled';
      } else {
        return 'disabled';
      }
    },
    comparisonLimitReached() {
      return this.numberOfPortfoliosInComparison >= _portfolioComparisonLimit;
    },
    filteredResults() {
      if (!this.portfolioSearchRes?.data) return [];

      // specific case for indices
      if (!this.getPortfolioByIdRes?.data) {
        return this.portfolioSearchRes.data;
      }

      return this.portfolioSearchRes.data.filter(
        (portfolio) => portfolio.id !== this.getPortfolioByIdRes.data.id
      );
    },
  },
  methods: {
    ...mapActions('portfolio', ['addPortfoliosToCompare']),
    ...mapActions('modals', ['toggleComparePortfoliosModal']),
    handleCompareButton() {
      this.addPortfoliosToCompare(this.selectedPortfolios);
      this.toggleComparePortfoliosModal({ show: false });
    },
    handleAddPortfolio(portfolioToAdd) {
      // clearing search query
      this.$refs.portfolioSearchWithCards?.clearSearchQuery();
      const isPortfolioPicked = this.selectedPortfolios.some((portfolio) => {
        return portfolio.id == portfolioToAdd.id;
      });

      if (isPortfolioPicked) {
        const portfolioIndex = this.selectedPortfolios.findIndex(
          (portfolio) => {
            return portfolio.id === portfolioToAdd.id;
          }
        );
        if (portfolioIndex !== -1) {
          this.selectedPortfolios.splice(portfolioIndex, 1);
          return;
        }
      }

      if (this.numberOfPortfoliosInComparison < _portfolioComparisonLimit) {
        this.selectedPortfolios.push(portfolioToAdd);
      }
    },
    handleRemovePortfolio(portfolioId) {
      const portfolioIndex = this.selectedPortfolios.findIndex(
        (portfolio) => portfolio.id === portfolioId
      );
      if (portfolioIndex !== -1) {
        this.selectedPortfolios.splice(portfolioIndex, 1);
      }
    },
    handleModalClose() {
      this.toggleComparePortfoliosModal({
        show: false,
      });
    },
    getPortfolioCardClasses(portfolioId) {
      const isPortfolioPicked = this.selectedPortfolios.some((portfolio) => {
        return portfolio.id === portfolioId;
      });

      if (isPortfolioPicked) {
        return 'bg-primary-2 pointer';
      }

      return this.comparisonLimitReached
        ? 'opacity-50 cursor-not-allowed'
        : 'pointer';
    },
  },
};
