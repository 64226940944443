/** Enumerates the possible search types that can be performed. */
export enum SearchType {
  Profile = '@',
  Hashtag = '#',
  Company = 'company',
  Symbol = 'symbol',
  Crypto = 'crypto',
  News = 'news',
  Post = 'post',
  Indices = 'indices',
}

/** Represents an individual search history item. */
export interface SearchHistoryItem {
  /** A unique search query identifier. */
  id: string;
  /** The actual search query string */
  query: string;
  /**
   * The type of search that was performed.
   * @see SearchType
   */
  type: SearchType;
}

/** Search History type. */
export type SearchHistory = SearchHistoryItem[];

export interface SearchCompany {
  profileId: string;
  profileName: string;
  avatarUri?: string;
  industry: string;
  isFollowed: boolean;
  followersCount: number;
}

export interface SearchSymbol {
  symbolId: string;
  exchange: string;
  exchangeName: string;
  name: string;
  type: string;
  companyId: string;
  companyName: string;
  avatarUri: string;
  sector: string;
  industry: string;
}

export interface SearchMention {
  profileId: string;
  profileName: string;
  displayName: string;
  avatarUri: string;
  isFollowed: boolean;
  mutualFriendsCount: number;
  followerCount: number;
}

export interface SearchCrypto {
  profileId: string;
  symbol: string;
  profileName: string;
  avatarUri: string;
  industry: 'Cryptocurrency';
  followersCount: number;
  isFollowed: boolean;
}

export interface SearchIndices {
  profileId: string;
  symbol: string;
  profileName: string;
  avatarUri: string;
  industry: 'Indices';
  followersCount: number;
  isFollowed: boolean;
}

export interface SearchHashtag {
  hashtag: string;
  count: number;
  id: string;
}

export type SearchSymbolWithPrices = object;

export interface SearchResult {
  mentions?: SearchMention[];
  hashtags?: SearchHashtag[];
  companies?: SearchCompany[];
  symbols?: SearchSymbol[];
  symbolsWithPrices?: SearchSymbolWithPrices[];
  cryptos?: SearchCrypto[];
  indices?: SearchIndices[];
}
