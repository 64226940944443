
import { mapActions, mapGetters } from 'vuex';
import UIMutualRecommendationWidget from '@/components/ui/widgets/recommendations/UIMutualRecommendationWidget';
import UITopInvestorsWidget from '@/components/ui/widgets/recommendations/UITopInvestorsWidget';
import { inAppRedirect } from '~/utils/route-redirects';

export default {
  name: 'MutualRecommendationWidgetWrapper',
  components: { UIMutualRecommendationWidget, UITopInvestorsWidget },
  props: {
    type: {
      type: String,
      required: true,
      validator: (v) => ['suggested', 'topInvestors'].includes(v),
    },
  },
  data() {
    return {
      section: 'mutualFollows',
    };
  },
  async fetch() {
    // That can put some load to the api, so we avoid extra loadings
    // Means that only by refreshing we can get back to the 1st page
    if (this.type === 'suggested') {
      if (!this.mutualFollows || this.mutualFollows.length == 0) {
        await this.getMutualFollows();
      }
    } else {
      if (
        process.client &&
        (!this.topInvestors || this.topInvestors.length == 0)
      ) {
        const payload = { limit: 5, page: 1 };
        await this.getTopInvestors(payload);
      }
    }

    // TODO : WAL-829 keeping this commented if we want to re-enable this again
    // (!this.mutualIndustries || this.mutualIndustries.length == 0) &&
    //   this.getMutualIndustries();
  },
  computed: {
    ...mapGetters('mutualRecommendationWidget', [
      'mutualFollows',
      'mutualFollowsLoading',
      'mutualFollowsError',
      'mutualIndustries',
      'mutualIndustriesLoading',
      'mutualIndustriesError',
      'topInvestors',
      'topInvestorsLoading',
    ]),
  },
  methods: {
    ...mapActions('mutualRecommendationWidget', [
      'getMutualFollows',
      'getNextMutualFollows',
      'getMutualIndustries',
      'getNextMutualIndustries',
      'followRecommendation',
      'getTopInvestors',
    ]),
    handleSelectMutualFollows() {
      this.section = 'mutualFollows';
    },
    handleSelectMutualIndustries() {
      this.section = 'mutualIndustries';
    },
    handleLoadMore() {
      switch (this.section) {
        case 'mutualFollows':
          this.getNextMutualFollows();
          break;
        case 'mutualIndustries':
          this.getNextMutualIndustries();
          break;
        default:
          break;
      }
    },
    handleGoToProfile(profileId) {
      inAppRedirect('/profile/' + profileId);
    },
    handleFollow(profileId) {
      this.followRecommendation(profileId);
    },
  },
};
