
import StockOperationCard from '~/components/ui/molecule/StockOperationCard';
export default {
  name: 'UITickersDropdown',
  components: { StockOperationCard },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    labelText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isPickerVisible: false,
      selectedItem: null,
    };
  },
  watch: {
    selectedItem() {
      this.$emit('onSymbolChange', this.selectedItem);
    },
  },
  created() {
    this.selectedItem = this.items[0];
  },
  methods: {
    handleClickOutside() {
      this.isPickerVisible = false;
    },
    handleShowDropdown() {
      this.isPickerVisible = !this.isPickerVisible;
    },
    handleCardClick(symbol) {
      this.selectedItem = symbol;
      this.isPickerVisible = false;
    },
  },
};
