
import { mapActions, mapGetters } from 'vuex';
import TeamPortfolioShareModal from '@/components/ui/modal/ManageTeamPortfolioModal.vue';

export default {
  name: 'TeamPortfolioModalWrapper',
  components: { TeamPortfolioShareModal },
  computed: {
    ...mapGetters('portfolio', ['getTeamPortfolioProfilesRes']),
  },
  methods: {
    ...mapActions('modals', ['showManageTeamPortfolioModal']),
  },
};
