export default {
  getIndustries(context) {
    context.commit('INIT');
    context.commit('SET_LOADING', true);
    this.dispatch('api/getIndustries')
      .then(res => {
        context.commit('SET_INDUSTRIES', res.data);
      })
      .catch(err => {
        context.commit('SET_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_LOADING', false);
      });
  },
  getFollowedIndustries(context, payload) {
    context.commit('INIT');
    context.commit('SET_FOLLOWED_INDUSTRY_LOADING', true);
    return this.dispatch('api/followedIdustries', payload)
      .then(res => {
        context.commit('SET_FOLLOWED_INDUSTRIES', res.data);
      })
      .catch(err => {
        context.commit('SET_FOLLOWED_INDUSTRY_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_FOLLOWED_INDUSTRY_LOADING', false);
      });
  },
  toggleFollowIndustry(context, payload) {
    return this.dispatch('api/followIndustryToggle', payload)
      .then(res => {
        context.commit('TOGGLE_FOLLOW_INDUSTRY', res.data);
      })
      .catch(err => {
        context.commit('SET_ERROR', err);
      });
  }
};
