import * as Sentry from '@sentry/vue';

import Vue from 'vue';

console.info('Initializing Sentry');

Sentry.init({
  // eslint-disable-next-line no-undef
  release: `${process.env.npm_package_name}@${process.env.npm_package_version}`,
  // eslint-disable-next-line no-undef
  environment: process.env.SENTRY_ENVIRONMENT,
  Vue,
  // eslint-disable-next-line no-undef
  dsn: process.env.NUXT_ENV_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration({
      // TODO set up trace propagation targets
    }),
    Sentry.replayIntegration({
      // TODO adjust the options - mask senstive data
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance monitoring
  tracesSampleRate: 1.0,
  // Session replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
