
export default {
  name: 'ProfileItem',
  props: {
    src: {
      type: String,
      required: true
    },
    profileName: {
      type: String,
      required: true
    }
  }
};
