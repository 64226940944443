/**
 * Number of recommended companies per page
 * @type {number}
 * @constant
 */
const RECOMMENDED_COMPANIES_PER_PAGE = 12;
const RECOMMENDED_PROFILES_PER_PAGE = 12;

export default {
  init(context, payload) {
    context.commit('INIT', payload);
  },
  completeTutorial(context) {
    context.commit('SET_TUTORIAL_LOADING', true);
    return this.dispatch('api/completeTutorial')
      .then(() => {
        context.commit('COMPLETE_TUTORIAL');
      })
      .finally(() => {
        context.commit('SET_TUTORIAL_LOADING', false);
      });
  },
  tutorialNextStep(context) {
    context.commit('SET_TUTORIAL_LOADING', true);
    return this.dispatch('api/tutorialNextStep')
      .then(() => {
        context.commit('TUTORIAL_NEXT_STEP');
      })
      .finally(() => {
        context.commit('SET_TUTORIAL_LOADING', false);
      });
  },
  tutorialPreviousStep(context) {
    return this.dispatch('api/tutorialPreviousStep').then(() => {
      context.commit('TUTORIAL_PREVIOUS_STEP');
    });
  },
  tipsNextStep(context) {
    // Complete step
    if (context.state.tipsStep == context.state.tipsTotalStep) {
      return this.dispatch('api/completeTips').then(() => {
        context.commit('COMPLETE_TIPS');
      });
    } else {
      return this.dispatch('api/tipsNextStep').then(() => {
        context.commit('TIPS_NEXT_STEP');
      });
    }
  },
  getRecommendedCompanies(context, payload) {
    const searchQuery = payload;
    context.commit('SET_COMPANIES', null);
    context.commit('RESET_COMPANIES_OFFSET');
    context.commit('SET_COMPANIES_LOADING', true);
    context.commit('SET_SHOW_MORE_COMPANIES', true);
    context.commit('SET_COMPANIES_SEARCH_QUERY', searchQuery);
    return this.dispatch('api/getRecommendedCompanies', {
      limit: RECOMMENDED_COMPANIES_PER_PAGE,
      page: 1,
      query: searchQuery,
    })
      .then((res) => {
        const { data } = res;
        context.commit('SET_COMPANIES', data);

        data.length < RECOMMENDED_COMPANIES_PER_PAGE &&
          context.commit('SET_SHOW_MORE_COMPANIES', false);
      })
      .finally(() => {
        context.commit('SET_COMPANIES_LOADING', false);
      });
  },
  getNextCompanies(context) {
    context.commit('SET_NEXT_COMPANIES_LOADING', true);
    return this.dispatch('api/getRecommendedCompanies', {
      limit: RECOMMENDED_COMPANIES_PER_PAGE,
      page: context.state.companiesOffset + 1,
      query: context.state.recommendedCompaniesSearchQuery,
    })
      .then((res) => {
        context.commit('INCREASE_COMPANIES_OFFSET');
        context.commit('APPEND_COMPANIES', res.data);
        res.data.length < RECOMMENDED_COMPANIES_PER_PAGE &&
          context.commit('SET_SHOW_MORE_COMPANIES', false);
      })
      .finally(() => {
        context.commit('SET_NEXT_COMPANIES_LOADING', false);
      });
  },
  followCompany(context, profileId) {
    return this.dispatch('api/followProfile', profileId).then((res) => {
      context.commit('UPDATE_COMPANY_IS_FOLLOWED', res.data);
    });
  },
  getRecommendedProfiles(context, payload) {
    const searchQuery = payload;
    context.commit('SET_PROFILES', null);
    context.commit('RESET_PROFILES_OFFSET');
    context.commit('SET_PROFILES_LOADING', true);
    context.commit('SET_SHOW_MORE_PROFILES', true);
    context.commit('SET_PROFILES_SEARCH_QUERY', searchQuery);
    return this.dispatch('api/getRecommendedProfiles', {
      limit: 12,
      page: 1,
      query: searchQuery,
    })
      .then((res) => {
        context.commit('SET_PROFILES', res.data);
        res.data.length < RECOMMENDED_PROFILES_PER_PAGE &&
          context.commit('SET_SHOW_MORE_PROFILES', false);
      })
      .finally(() => {
        context.commit('SET_PROFILES_LOADING', false);
      });
  },
  getNextProfiles(context) {
    context.commit('SET_NEXT_PROFILES_LOADING', true);
    return this.dispatch('api/getRecommendedProfiles', {
      limit: 12,
      page: context.state.profilesOffset + 1,
      query: context.state.recommendedProfilesSearchQuery,
    })
      .then((res) => {
        context.commit('INCREASE_PROFILES_OFFSET');
        context.commit('APPEND_PROFILES', res.data);
        res.data.length < RECOMMENDED_PROFILES_PER_PAGE &&
          context.commit('SET_SHOW_MORE_PROFILES', false);
      })
      .finally(() => {
        context.commit('SET_NEXT_PROFILES_LOADING', false);
      });
  },
  followProfile(context, profileId) {
    return this.dispatch('api/followProfile', profileId).then((res) => {
      context.commit('UPDATE_PROFILE_IS_FOLLOWED', res.data);
    });
  },
  updateUserBirthdate(context, payload) {
    context.commit('SET_TUTORIAL_LOADING', true);
    return this.dispatch('api/patchUserBirthdate', payload)
      .then((res) => {
        context.commit('SET_BIRTHDATE_SUCCESS', true);
        this.dispatch(
          'authentication/updateUserBirthdate',
          res.data.birthdateUtc
        );
      })
      .catch((err) => {
        context.commit('SET_BIRTHDATE_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_TUTORIAL_LOADING', false);
      });
  },
  getUserOnboardingQuestions(context) {
    return this.dispatch('api/getUserOnboardingQuestions').then((res) => {
      context.commit('SET_USER_ONBOARDING_QUESTIONS', res.data);
    });
  },
  getUserOnboardingAnswersByProfileId(context, profileId) {
    return this.dispatch(
      'api/getUserOnboardingAnswersByProfileId',
      profileId
    ).then((res) => {
      context.commit('SET_USER_ONBOARDING_ANSWERS', res.data);
    });
  },
  saveUserOnboardingAnswers(context, payload) {
    return this.dispatch('api/saveUserOnboardingAnswers', payload);
  },
  setUserOnboardingAnswer(context, payload) {
    context.commit('APPEND_USER_ONBOARDING_ANSWER', payload);
  },
};
