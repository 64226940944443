import actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

const state = () => ({
  // Profile
  symbolProfile: null,
  symbolProfileLoading: false,
  symbolProfileError: null
});

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
