// MutualFollows
export const mutualFollows = state => state.mutualFollows;
export const topInvestors = state => state.topInvestors.data;
export const topInvestorsLoading = state => state.topInvestors.loading;
export const mutualFollowsPage = state => state.mutualFollowsPage;
export const mutualFollowsError = state => state.mutualFollowsError;
export const mutualFollowsLoading = state => state.mutualFollowsLoading;

// MutualIndustries
export const mutualIndustries = (state) => state.mutualIndustries;
export const mutualIndustriesPage = (state) => state.mutualIndustriesPage;
export const mutualIndustriesError = (state) => state.mutualIndustriesError;
export const mutualIndustriesLoading = (state) => state.mutualIndustriesLoading;
