
import UserImgInfo from '@/components/ui/molecule/UserImgInfo';
import CheckIcon from '~/components/ui/icons/CheckIcon.vue';
import AddIcon from '~/components/ui/icons/AddIcon.vue';

export default {
  name: 'UIMutualRecommendationWidget',
  components: { UserImgInfo, CheckIcon, AddIcon },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    section: {
      type: String,
      default: 'mutualFollows',
    },
    profiles: {
      type: Array,
      required: true,
    },
  },
};
