
import { mapActions, mapGetters } from 'vuex';
import CustomModal from '~/components/ui/molecule/CustomModal.vue';
import TickerSearchResultCard from '~/components/ui/molecule/TickerSearchResultCard.vue';
import UITickerSearchWithCards from '~/components/ui/organism/UITickerSearchWithCards.vue';
import CloseIcon from '~/components/ui/icons/CloseIcon.vue';
import { _tickerComparisonLimit } from '~/utils/comparison-tool-settings.ts';

export default {
  name: 'CompareTickersModal',
  components: {
    CustomModal,
    CloseIcon,
    TickerSearchResultCard,
    UITickerSearchWithCards,
  },
  data() {
    return {
      selectedTickers: [],
    };
  },
  computed: {
    ...mapGetters('companyProfile', ['tickersToCompareRes']),
    ...mapGetters('search', ['searchRes']),
    ...mapGetters('modals', [
      'itemsInComparison',
      'compareTickersModalSelectedTicker',
    ]),
    numberOfTickersInComparison() {
      return this.itemsInComparison + this.selectedTickers.length;
    },
    getModalTitle() {
      return (
        this.$t('compare_to') +
        ` (${this.numberOfTickersInComparison}` +
        '/' +
        `${_tickerComparisonLimit})`
      );
    },
    buttonState() {
      if (
        this.numberOfTickersInComparison <= _tickerComparisonLimit &&
        this.numberOfTickersInComparison >= 1
      ) {
        return 'enabled';
      } else {
        return 'disabled';
      }
    },
    comparisonLimitReached() {
      return this.numberOfTickersInComparison >= _tickerComparisonLimit;
    },
    filteredResults() {
      if (!this.searchRes.data?.symbolsWithPrices) return [];

      return this.searchRes.data.symbolsWithPrices.filter(
        (ticker) =>
          ticker.symbolId !== this.compareTickersModalSelectedTicker.symbolId
      );
    },
  },
  methods: {
    ...mapActions('companyProfile', [
      'getTickersToCompare',
      'getMultipleTickersToCompare',
    ]),
    ...mapActions('modals', ['toggleCompareTickersModal']),
    handleCardClick(symbol) {
      // clearing search query
      this.$refs.tickerSearchWithCards?.clearSearchQuery();
      const isTickerPicked = this.selectedTickers.some((ticker) => {
        return ticker.symbolId === symbol.symbolId;
      });

      if (isTickerPicked) {
        const tickerIndex = this.selectedTickers.findIndex((ticker) => {
          return ticker.symbolId === symbol.symbolId;
        });
        if (tickerIndex !== -1) {
          this.selectedTickers.splice(tickerIndex, 1);
          return;
        }
      }

      if (this.numberOfTickersInComparison < _tickerComparisonLimit) {
        this.selectedTickers.push(symbol);
      }
    },
    handleCompareButton() {
      if (this.selectedTickers.length > 0) {
        const payload = this.selectedTickers.map(({ companyId, name }) => {
          return {
            companyId: companyId,
            symbol: name,
          };
        });
        this.getMultipleTickersToCompare(payload);
        this.toggleCompareTickersModal({
          show: false,
        });
      }
    },
    handleRemoveSymbol(symbol) {
      const index = this.selectedTickers.findIndex(
        (item) => item.name === symbol
      );

      if (index !== -1) {
        this.selectedTickers.splice(index, 1);
      }
    },
    handleRemoveSymbolFromCard(symbolId) {
      const index = this.selectedTickers.findIndex(
        (item) => item.symbolId === symbolId
      );

      if (index !== -1) {
        this.selectedTickers.splice(index, 1);
      }
    },
    handleModalClose() {
      this.toggleCompareTickersModal({
        show: false,
      });
    },
    getTickerCardClasses(symbolId) {
      const isTickerPicked = this.selectedTickers.some((ticker) => {
        return ticker.symbolId === symbolId;
      });

      if (isTickerPicked) {
        return 'bg-primary-2 pointer';
      }

      return this.comparisonLimitReached
        ? 'opacity-50 cursor-not-allowed'
        : 'pointer';
    },
  },
};
