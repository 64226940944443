
import { isMobile, isDesktop, isTablet } from '~/utils/screenSizes';
import { mapGetters } from 'vuex';
import { appURLs } from '@/utils/route-redirects';

export default {
  name: 'HelpModals',
  props: {
    walkthroughStep: {
      type: Number,
      required: true,
    },
    feedStep: {
      type: Number,
      required: true,
    },
    settings: {
      type: Boolean,
      required: true,
    },
    walkthroughCoordX: {
      type: Number,
      default: 0,
    },
    walkthroughCoordY: {
      type: Number,
      default: 0,
    },
    feedCoordX: {
      type: Number,
      default: 0,
    },
    settingsCoordX: {
      type: Number,
      default: 0,
    },
    feedPageAvailable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['newWalkthroughStep', 'newFeedStep', 'closeHelpSettings'],
  computed: {
    isMobileSize() {
      return isMobile();
    },
    isDesktopSize() {
      return isDesktop();
    },
    isTabletSize() {
      return isTablet();
    },
    routeNameIncludesFeed() {
      const routeName = this.$route.name?.split('_')[0];
      return routeName?.includes(appURLs.feed());
    },
    ...mapGetters('authentication', ['profile']),
  },
  methods: {
    getWalkthroughModal1Style() {
      let toReturn = '';

      if (this.isMobileSize || this.isTabletSize) {
        toReturn = 'width: 100%; top: 4rem; left: 0;';
      } else {
        if (this.isDesktop) {
          toReturn = 'width: fit-content;';
          if (this.walkthroughCoordX !== 0) {
            toReturn += 'right: ' + this.walkthroughCoordX + 'px;';
          } else {
            toReturn += 'left: 1rem;';
          }
        } else {
          toReturn =
            'width:fit-content; ' + 'right: ' + this.walkthroughCoordX + 'px;';
        }
      }
      return toReturn;
    },
    getHalfScreenWidth() {
      return window.innerWidth / 2;
    },
  },
};
