
import { inAppRedirect } from '~/utils/route-redirects';

export default {
  name: 'NotificationPriceChange',
  props: {
    notif: {
      required: true,
      type: Object,
    },
  },
  methods: {
    goToProfile() {
      inAppRedirect(`/profile/${this.notif.author.profileId}`);
    },
    notifType() {
      if (this.notif.stockInfo.open > this.notif.stockInfo.close) {
        return 'PRICECHANGE_DECREASED';
      } else {
        return 'PRICECHANGE_INCREASED';
      }
    },
  },
};
