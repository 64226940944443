import actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

const state = () => ({
  popularCryptos: [],
  popularCryptosError: null,
  popularCryptosLoading: false
});

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
