
import Vue from 'vue';
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import NavProfile from './NavProfile.vue';
import NavHamburger from './NavHamburger.vue';
import NavContent from '@/components/NavigationBar/NavContent.vue';
import CloseIcon from '~/components/ui/icons/CloseIcon.vue';
import Sidebar from '~/components/shared/nav/sidebar/index.vue';
import SkadeLogoWithText from '~/assets/icons/skade-logo-with-text.svg';
import LogoIcon from '~/assets/icons/logo.svg';
import { getWindowInstance } from '~/plugins/windowInstance';
import { appURLs } from '@/utils/route-redirects';
import { unlocalizeRouteName } from '~/utils/routeNames';

enum NavigationMode {
  Standard = 'standard',
  Titular = 'titular',
}

export default Vue.extend({
  components: {
    SkadeLogoWithText,
    LogoIcon,
    NavContent,
    NavHamburger,
    NavProfile,
    Sidebar,
    CloseIcon,
  },
  computed: {
    feedUrl(): string {
      return this.localePath(appURLs.feed());
    },
    isMobileResponsive(): boolean {
      // @ts-ignore TODO fix typescript not recognizing this
      return getWindowInstance(this).isMobileResponsive();
    },
    showingMobileSearchPopup(): boolean {
      // @ts-ignore TODO fix typescript not recognizing this
      return this.isMobileResponsive && this.searchPopupShown;
    },
    ...mapGetters('search', ['searchPopupShown']),
    ...mapGetters('modals', ['helpCenterObjectRes']),
    ...mapState('ui-messaging', ['pageNested', 'pageTitle', 'pageMenuMode']),
    /**
     * The current navigation mode.
     * If the current navigation mode is standard, then a standard search bar
     * will be shown, but `nested` displays a titular page with a back icon
     * emitting a back event.
     */
    mode(): NavigationMode {
      return this.pageNested && ['sm'].includes(this.$mq)
        ? NavigationMode.Titular
        : NavigationMode.Standard;
    },
    /**
     * The overal navigation bar mode for supporting titular pages such as
     * "Settings".
     */
    contentMode(): string {
      const routeName = (this.$route?.name ?? '').split('_')[0];
      if (
        ['sm'].includes(this.$mq) &&
        ['portfolios-portfolioId'].indexOf(routeName) != -1
      ) {
        return 'portfolio';
      }
      return this.mode === 'titular' ? 'title' : 'search';
    },
    /** Which mode the left menu should be in. */
    menuMode(): string {
      // if the search popup is shown and the user is on mobile, allow the user to use the back button
      if (this.isMobileResponsive && this.searchPopupShown) return 'back';

      // Special cases handling
      if ('menu' in this.$route.query || 'follows' in this.$route.query) {
        return 'back';
      }

      // Regular use cases using the store
      // @ts-ignore TODO fix typescript error
      switch (this.pageMenuMode) {
        case 'burgerMenu':
          return 'menu';
        //@ts-ignore fix this TS issue
        case 'backArrow':
          // do not show the back arrow on non-mobile screen sizes
          return this.isMobileResponsive ? 'back' : 'menu';
        case 'empty':
          return 'empty';
        default:
          return 'menu';
      }
    },
    /**
     *  Whether to show the help center button in the top nav.
     * As per Manon's request, the help center button in the top nav should be shown whenever the search bar itself is shown.
     * This means that this property should be kept in sync with the condition used in NavContent to display the Searchbar
     * components/NavigationBar/NavContent.vue:9
     */
    showHelpCenter(): boolean {
      return this.contentMode === 'search';
    },
    showLogoMobile(): boolean {
      return (
        this.contentMode === 'search' &&
        this.isMobileResponsive &&
        !this.searchPopupShown
      );
    },
  },
  watch: {
    $route() {
      // make the search popup not shown when navigation occurs
      this.handleSearchPopupShown(false);
    },
  },
  methods: {
    hamburgerBackClicked(): void {
      if (this.isMobileResponsive && this.searchPopupShown) {
        this.handleSearchPopupShown(false);
      }

      if ('follows' in this.$route.query) {
        this.setContentStep(0);
      }

      if (
        this.$route &&
        (this.$route.name?.includes('settings') ||
          [appURLs.portfoliosSettings()].includes(
            // @ts-ignore TODO fix this TS error
            unlocalizeRouteName(this.$route)
          ))
      ) {
        // TODO add this mutation to the mixin
        this.TRIGGER_CUSTOM_BACK_ACTION(true);
        return;
      }
      if (
        this.mode !== 'titular' &&
        !Object.keys(this.$route.query).includes('menu')
      ) {
        return;
      }
      this.$router.go(-1);
    },
    ...mapActions('search', [
      'searchForQuery',
      'showSearchPopup',
      'hideSearchPopup',
    ]),
    ...mapActions('modals', ['setHelpCenterObjectRes']),
    ...mapActions('profile', ['setContentStep']),
    ...mapMutations('ui-messaging', ['TRIGGER_CUSTOM_BACK_ACTION']),
    closeHelpCenterButton(): void {
      const payload = { ...this.helpCenterObjectRes };
      payload.showFeedButton = false;
      // @ts-ignore TODO fix typescript error
      this.setHelpCenterObjectRes(payload);
    },
    openHelpCenter(): void {
      const payload = { ...this.helpCenterObjectRes };
      (payload.show = true),
        (payload.showFeedButton = false),
        (payload.showMain = true);
      // @ts-ignore TODO fix typescript error
      this.setHelpCenterObjectRes(payload);
    },
    openHelpCenterBottomButton(): void {
      const payload = { ...this.helpCenterObjectRes };
      (payload.show = true),
        (payload.showFeedButton = false),
        (payload.showMain = true);
      // @ts-ignore TODO fix typescript error
      this.setHelpCenterObjectRes(payload);
    },
    handleSearchPopupShown(searchPopupShown: boolean): void {
      if (searchPopupShown) this.showSearchPopup();
      else this.hideSearchPopup();
    },
  },
});
