import { AxiosError, AxiosResponse } from 'axios';
import { ActionContext, ActionTree, MutationTree } from 'vuex';

export interface StateValue {
  // eslint-disable-next-line
  data: any;
  // eslint-disable-next-line
  error: any;
  loading: boolean | null;
}

/**
 * @deprecated This is no longer used, except in a few stores. You should not
 * be calling this function.
 */
export function MutationsCreator<
  TargetState extends { [key: string]: StateValue }
>(mutationsItems: { name: string; state: string }[]) {
  const res: MutationTree<TargetState> = {};

  mutationsItems.forEach((item) => {
    res[`${item.name}_START`] = function (state: {
      [key: string]: StateValue;
    }) {
      state[item.state] = { loading: true, data: null, error: null };
    };
    res[`${item.name}_SUCCESS`] = function (
      state: { [key: string]: StateValue },
      // eslint-disable-next-line
      payload: any
    ) {
      state[item.state] = { loading: null, data: payload, error: null };
    };
    res[`${item.name}_ERROR`] = function (
      state: { [key: string]: StateValue },
      // eslint-disable-next-line
      payload: any
    ) {
      state[item.state] = { loading: null, data: null, error: payload };
    };
  });

  return res;
}

/**
 * @deprecated This is no longer used, except in a few stores. You should not
 * be calling this function.
 */
export function ActionsCreator<TargetState, RootState>(
  actionsItems: { name: string; mutation: string }[]
) {
  const res: ActionTree<TargetState, RootState> = {};

  actionsItems.forEach((item) => {
    res[item.name] = function (
      { commit }: ActionContext<TargetState, RootState>,
      payload
    ) {
      commit(`${item.mutation}_START`);
      // @ts-ignore TODO Type this
      return this.$api[item.name](payload)
        .then((response: AxiosResponse) => {
          const data = response.data != null ? response.data : response.status;

          commit(`${item.mutation}_SUCCESS`, data);
          return response;
        })
        .catch((e: AxiosError) => {
          commit(`${item.mutation}_ERROR`, e); // TODO error handling
          throw e;
        });
    };
  });

  return res;
}
