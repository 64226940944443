
import axios from 'axios';
import PriceCard from '@/components/ui/molecule/PriceCard';
import ValueCard from '@/components/ui/molecule/ValueCard';

export default {
  name: 'SymbolCardsWidget',
  components: {
    PriceCard,
    ValueCard,
  },
  props:{ 
    price: {
      type: Number,
    },
    changePrice:{
      type: Number,
    },
    returnV: {
      type: Number,
    },
    changeReturn:{
      type: Number,
    },
    value: {
      type: Number,
    },
    shares: {
      type: Number,
    },
  },
};
