export default {
  INIT(state) {
    state.companiesOffset = 1;
    state.profilesOffset = 1;
  },
  SET_COMPANIES_LOADING(state, isLoading) {
    state.recommendedCompaniesLoading = isLoading;
  },
  SET_NEXT_COMPANIES_LOADING(state, isLoading) {
    state.nextCompaniesLoading = isLoading;
  },
  SET_COMPANIES(state, companies) {
    state.recommendedCompanies = companies;
  },
  APPEND_COMPANIES(state, companies) {
    state.recommendedCompanies = [...state.recommendedCompanies, ...companies];
  },
  INCREASE_COMPANIES_OFFSET(state) {
    state.companiesOffset++;
  },
  RESET_COMPANIES_OFFSET(state) {
    state.companiesOffset = 1;
  },
  SET_PROFILES_LOADING(state, isLoading) {
    state.recommededProfilesLoading = isLoading;
  },
  SET_NEXT_PROFILES_LOADING(state, isLoading) {
    state.nextProfilesLoading = isLoading;
  },
  SET_PROFILES(state, profiles) {
    state.recommendedProfiles = profiles;
  },
  APPEND_PROFILES(state, profiles) {
    state.recommendedProfiles = [...state.recommendedProfiles, ...profiles];
  },
  INCREASE_PROFILES_OFFSET(state) {
    state.profilesOffset++;
  },
  RESET_PROFILES_OFFSET(state) {
    state.profilesOffset = 1;
  },
  UPDATE_PROFILE_IS_FOLLOWED(state, profile) {
    if (state.recommendedProfiles) {
      const index = state.recommendedProfiles.findIndex(
        (p) => p.profileId === profile.profileId
      );

      if (index != -1) {
        state.recommendedProfiles[index].isFollowed = profile.isFollowed;
      }
    }
  },
  UPDATE_COMPANY_IS_FOLLOWED(state, company) {
    if (state.recommendedCompanies) {
      const index = state.recommendedCompanies.findIndex(
        (c) => c.profileId === company.profileId
      );

      if (index != -1) {
        state.recommendedCompanies[index].isFollowed = company.isFollowed;
      }
    }
  },
  SET_SHOW_MORE_COMPANIES(state, value) {
    state.showMoreCompanies = value;
  },
  SET_SHOW_MORE_PROFILES(state, value) {
    state.showMoreProfiles = value;
  },
};
