export default {
  search(context, payload) {
    context.commit('SET_SEARCH_LOADING', true);
    return this.dispatch('api/search', payload)
      .then((res) => {
        context.commit('SET_SEARCH', res.data);
        context.commit('SET_SEARCH_LOADING', false);
      })
      .catch((err) => {
        context.commit('SET_SEARCH_ERROR', err);
        context.commit('SET_SEARCH_LOADING', false);
      });
  },
  appendUsersToSearch(context, payload) {
    return this.dispatch('api/search', payload)
      .then((res) => {
        context.commit('APPEND_USERS_TO_SEARCH', res.data.mentions);
        return res.data.mentions;
      })
      .catch((err) => {
        context.commit('SET_USER_SEARCH_ERROR', err);
      });
  },
  appendCompaniesToSearch(context, payload) {
    return this.dispatch('api/search', payload)
      .then((res) => {
        context.commit('APPEND_COMPANIES_TO_SEARCH', res.data.companies);
        return res.data.companies;
      })
      .catch((err) => {
        context.commit('SET_COMPANY_SEARCH_ERROR', err);
      });
  },
  appendCryptosToSearch(context, payload) {
    return this.dispatch('api/search', payload)
      .then((res) => {
        context.commit('APPEND_CRYPTOS_TO_SEARCH', res.data.cryptos);
        return res.data.cryptos;
      })
      .catch((err) => {
        context.commit('SET_CRYPTO_SEARCH_ERROR', err);
      });
  },
  appendTickersToSearch(context, payload) {
    return this.dispatch('api/search', payload)
      .then((res) => {
        context.commit('APPEND_TICKERS_TO_SEARCH', res.data.symbolsWithPrices);
        return res.data.symbolsWithPrices;
      })
      .catch((err) => {
        context.commit('SET_TICKER_SEARCH_ERROR', err);
      });
  },
  appendHashTagsToSearch(context, payload) {
    return this.dispatch('api/search', payload)
      .then((res) => {
        context.commit('APPEND_HASHTAGS_TO_SEARCH', res.data.hashtags);
        return res.data.hashtags;
      })
      .catch((err) => {
        context.commit('SET_HASHTAG_SEARCH_ERROR', err);
      });
  },
  appendIndicesToSearch(context, payload) {
    return this.dispatch('api/search', payload)
      .then((res) => {
        context.commit('APPEND_INDICES_TO_SEARCH', res.data.indices);
        return res.data.indices;
      })
      .catch((err) => {
        context.commit('SET_INDICES_SEARCH_ERROR', err);
      });
  },

  toggleFollowProfile(context, payload) {
    this.dispatch('api/followProfile', payload.profileId)
      .then(() => {
        context.commit('SET_FOLLOW', payload);
      })
      .catch((err) => {
        context.commit('SET_FOLLOW_ERROR', err);
      });
  },
  followSymbolStockPriceChange(context, payload) {
    this.dispatch('api/followStockChange', payload)
      .then(() => {
        context.commit('FOLLOW_SYMBOL_STOCK_PRICE_CHANGE_SUCCESS', payload);
      })
      .catch((err) => {
        context.commit('SET_COMPANY_SYMBOLS_ERROR', err);
      });
  },

  v2Search(context, payload) {
    const { query, type } = payload;

    const page = 1;
    const resultsPerPage = context.getters.v2ResultsPerPage;

    context.commit('V2_SET_PAGE', page);
    context.commit('SET_SEARCH_LOADING', true);

    return this.dispatch('api/search', {
      query,
      type,
      page: page,
      limit: resultsPerPage,
      save: true,
    })
      .then((res) => {
        context.commit('V2_SET_SEARCH', res.data);
        context.commit('SET_SEARCH_LOADING', false);
      })
      .catch((err) => {
        context.commit('SET_SEARCH_ERROR', err);
        context.commit('SET_SEARCH_LOADING', false);
      });
  },

  v2LoadNextPage(context, payload) {
    const { query, type } = payload;

    const page = context.getters.v2Page + 1;
    const resultsPerPage = context.getters.v2ResultsPerPage;

    context.commit('V2_SET_PAGE', page);

    return this.dispatch('api/search', {
      query,
      type,
      page: page,
      limit: resultsPerPage,
    })
      .then((res) => {
        context.commit('V2_APPEND_SEARCH', res.data);
      })
      .catch((err) => {
        context.commit('SET_SEARCH_ERROR', err);
      });
  },
};
