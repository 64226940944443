
import ArrowIcon from '~/components/ui/icons/ArrowIcon.vue';
export default {
  name: 'LogoutStep',
  components: { ArrowIcon },
  data() {
    return {
      value: null,
    };
  },
};
