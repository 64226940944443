export default {
  library: {
    toggle: {
      classification: 'checkbox',
      component: 'MyFormulateToggle'
    },
    customtextarea: {
      component: 'MyFormulateTextarea'
    },
    multiselect: {
      component: 'MyFormulateMultiSelect'
    }
  }
};
