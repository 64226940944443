import actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

const state = () => ({
  trendingCryptos: [],
  trendingCryptosError: null,
  trendingCryptosLoading: false
});

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
