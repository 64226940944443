const middleware = {}

middleware['locale'] = require('../middleware/locale.js')
middleware['locale'] = middleware['locale'].default || middleware['locale']

middleware['onboarding'] = require('../middleware/onboarding.js')
middleware['onboarding'] = middleware['onboarding'].default || middleware['onboarding']

middleware['pageMeta'] = require('../middleware/pageMeta.ts')
middleware['pageMeta'] = middleware['pageMeta'].default || middleware['pageMeta']

middleware['register'] = require('../middleware/register.js')
middleware['register'] = middleware['register'].default || middleware['register']

export default middleware
