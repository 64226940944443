
import ArrowIcon from '~/components/ui/icons/ArrowIcon.vue';
import MenuIcon from '~/assets/icons/menu-secondary.svg';
import Vue from 'vue';
import { appURLs } from '@/utils/route-redirects';

export default Vue.extend({
  components: {
    MenuIcon,
    ArrowIcon,
  },
  props: {
    mode: {
      required: true,
      type: String,
      validator: (v) => ['back', 'menu'].includes(v),
    },
  },
  // @ts-ignore TODO Figure out why Vue doesn't have an emits option
  emits: ['back'],
  data() {
    return {
      appURLs,
    };
  },
  computed: {
    shouldShowBackButton() {
      return this.mode === 'back';
    },
    shouldShowMenu() {
      return this.mode === 'menu' && ['sm', 'md'].includes(this.$mq);
    },
    shouldShowHamburger() {
      return this.shouldShowBackButton || this.shouldShowMenu;
    },
  },
});
