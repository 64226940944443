// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ui-mention-card{opacity:0;position:relative;transition:opacity .6s}.ui-mention-card.show{opacity:1}.ui-mention-card.hide{opacity:0}.ui-mention-card:after,.ui-mention-card:before{border-style:solid;bottom:100%;content:\"\";display:block;height:0;pointer-events:none;position:absolute;width:0}.ui-mention-card:after{border-color:transparent transparent var(--color-neutral-1) transparent;border-width:10px;margin-left:1px;margin-top:1px}.ui-mention-card:before{border-color:transparent transparent var(--color-neutral-4) transparent;border-width:11px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"a": "#5a80fb",
	"b": "#6b88fc",
	"c": "#7b91fc",
	"d": "#8899fd",
	"e": "#95a2fd",
	"f": "#a1abfe",
	"g": "#adb4fe",
	"h": "#b8bdfe",
	"i": "#c3c6ff",
	"j": "#cdcfff",
	"k": "#d8d9ff",
	"l": "#e2e2ff",
	"m": "#ececff",
	"n": "#f5f5ff",
	"o": "#fff"
};
module.exports = ___CSS_LOADER_EXPORT___;
