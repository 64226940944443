
import SymbolCards from '@/components/ui/widgets/symbol/SymbolCards';
import { mapGetters, mapActions } from 'vuex';
export default {
    name: 'DataTickerWidgetWrapper',
    components: {
        SymbolCards,
    },
    async fetch() {
        if (process.client) {
            await this.getSymbolProfileById(this.$route.params.symbol);
            await this.getTickerStockValue({symbol:this.symbolProfile.name,date:new Date(Date.now()).toISOString().slice(0,10)});
        }
    },
    computed: {
        ...mapGetters('symbolProfile', ['symbolProfile']),
        ...mapGetters('portfolio', ['getTickerStockValueRes']),
    },
    methods: {
        ...mapActions('symbolProfile', ['getSymbolProfileById']),
        ...mapActions('portfolio', ['getTickerStockValue']),
    },
    watch: {
        'getTickerStockValueRes.data'() {
            this.stockInfo=this.getTickerStockValueRes.data;
        },
    },
    data() {
        return {
            stockInfo: null
        }
    }
}
