import { appURLs } from '~/utils/route-redirects';
import { unlocalizeRouteName } from '~/utils/routeNames';

/**
 * Redirects the user to the onboarding page if they haven't completed the onboarding process
 * and they aren't on the onboarding page.
 */
export default async function (context) {
  const { store, route, app } = context;

  const hasCompletedOnboarding =
    store.getters['onboarding/hasCompletedTutorial'];

  console.debug('Onboarding middleware. Onboarding completion status', {
    hasCompletedOnboarding,
    routeName: route?.name,
  });

  // check if onboarding is complete. if so, exit from the middleware
  if (hasCompletedOnboarding === true) {
    console.debug(
      'Onboarding middleware. User has completed onboarding. Returning.'
    );
    return;
  }

  // check if the user is on a route where the onboarding redirect should be triggered.
  // in this case, trigger the onboarding redirect if the user is not already on onboarding and
  // if the user is not on the loading page (since this is where we handle our login parameters.)
  if (
    !unlocalizeRouteName(route).startsWith(appURLs.onboarding()) &&
    !unlocalizeRouteName(route).startsWith(appURLs.loading()) &&
    !unlocalizeRouteName(route).startsWith(appURLs.index())
  ) {
    console.debug('Onboarding middleware. Redirecting to onboarding');

    return app.router.push(app.localePath(appURLs.onboarding()));
  } else console.debug('Onboarding middleware. Skipping route');
}
