import actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

const state = () => ({
  trendingTickers: [],
  trendingLosersTickers: [],
  trendingGainersTickers: [],
  trendingTickersError: null,
  trendingTickersLoading: false
});

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
