
import { buildAssetUri } from '@/utils/mixins';

export default {
  name: 'Img',
  mixins: [buildAssetUri],
  props: {
    src: {
      required: true,
      type: String,
    },
    alt: {
      default: '',
      type: String,
    },
  },
};
