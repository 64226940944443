
import { mapActions, mapGetters } from 'vuex';
import NotifItem from './notificationType/';
import { debounce } from 'lodash';
import BellIcon from '~/components/ui/icons/BellIcon.vue';

export default {
  name: 'HeaderNotification',
  fetchOnServer: false,
  components: { NotifItem, BellIcon },
  data() {
    return {
      debouncedReadLoadedNotifications: debounce(
        this.readLoadedNotifications,
        300
      ),
    };
  },
  async fetch() {
    await this.getNotifications({ profileId: this.profileId });
    await this.getNotificationCount({ profileId: this.profileId });
  },
  computed: {
    ...mapGetters('authentication', ['profileId']),
    ...mapGetters('notifications', [
      'notifications',
      'loading',
      'nextNotificationsLoading',
      'notificationCount',
      'error',
      'nextNotificationsError',
      'notificationOffset',
      'hasMoreNotifications',
    ]),
    sanitizedNotificationCount() {
      return this.notificationCount ?? 0;
    },
  },
  watch: {
    profileId() {
      if (this.profileId) {
        this.getNotifications({ profileId: this.profileId });
      }
    },
    notificationCount() {
      if (this.notificationCount > 0) {
        document.title =
          '(' +
          (this.notificationCount > 99 ? '99+' : this.notificationCount) +
          ')' +
          ' Skade';
      } else {
        document.title = 'Skade';
      }
    },
  },
  methods: {
    ...mapActions('notifications', [
      'getNotifications',
      'getNotificationCount',
      'getNextNotifications',
      'markAllNotificationsAsRead',
      'markMultipleNotificationsAsRead',
    ]),
    loadMoreNotifications() {
      if (this.notifications && this.notifications.length) {
        this.getNextNotifications({
          profileId: this.profileId,
          limit: 5,
          offset: this.notificationOffset,
        });
        this.debouncedReadLoadedNotifications();
      }
    },
    readLoadedNotifications() {
      if (this.notifications && this.notificationCount > 0) {
        const unreadNotifications = this.notifications.filter(
          (n) => n.read == false
        );

        if (unreadNotifications.length > 0) {
          const notificationIds = unreadNotifications.map(
            (n) => n.notificationId
          );
          this.markMultipleNotificationsAsRead({
            profileId: this.profileId,
            notificationIds,
          });
        }
      }
    },
  },
};
