import actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

const state = () => ({
  showMobileSidebar: false,
  showCreatePostModal: {
    show: false,
    target: '',
    targetType: '',
    mentionPost: null,
    originalPost: null,
    title: '',
    callback: null,
    dispatch: null,
    context: null,
  },
  showImageSliderModal: {
    show: false,
    navigateTo: 0,
    images: [],
  },
  showSelectProfileModal: false,
  showCreatePortfolioModal: false,
  showLegalDisclaimerModal: false,
  mentionHovered: false,
  mentionCardHovered: false,
  hoveredMentionId: null,
  hoveredMentionCardId: null,
  currentMentionCard: null,
  mouseOnMention: null,
  mouseOnMentionCard: null,
  lastMentionCard: {
    profile: null,
  },
  passwordResetModal: {
    show: false,
    step: 1,
  },
  createPortfolioOperationModal: {
    show: false,
    isSubmitting: false,
    multipleTransactions: false,
    portfolioType: null,
    symbol: null,
    portfolio: null,
    operationType: 'Buy',
    symbolLoading: false,
    symbolError: null,
    createOperationError: null,
    createCashOperationError: null,
    tickerValue: null,
    isCashTransaction: false,
    currency: '',
    shouldShowOperationSwitcher: false,
    shouldShowOperationButtons: false,
    shouldShowTickerCard: false,
  },
  helpCenterObjectRes: {
    show: false,
    showFeedButton: localStorage.getItem('hasClickedHelpCenterButton')
      ? !JSON.parse(localStorage.getItem('hasClickedHelpCenterButton'))
      : true,
    showMain: false,
    showWalkthrough: 0,
    showSettings: false,
    showFeed: 0,
    feedPageAvailable: false,
  },
  showProfileCardRes: {
    show: false,
    profileId: null,
    targetCoordX: 0,
    targetCoordY: 0,
    targetHeight: 0,
  },
  portfolioEmptyStateModal: {
    show: false,
  },
  giphyModal: {
    lastGiphyTrendingSearch: null,
  },
  compareTickersModal: {
    show: false,
    numberOfItemsInComparison: 0,
    selectedTicker: {},
  },
  comparePortfoliosModal: {
    show: false,
    isMyPortfolio: false,
    numberOfItemsInComparison: 0,
  },
  manageTeamPortfolioModal: {
    show: false,
  },
  createLeagueModal: {
    show: false,
  },
});

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
