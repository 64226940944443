import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { Store } from 'vuex';
import { NotificationDetail } from '~/types/messages/notifications';
import { RootState } from '~/store';
import { ContainsHubConnection } from '..';

export default function notificationsHub(
  store: Store<RootState>,
  jwt: string
): ContainsHubConnection {
  const hub = new HubConnectionBuilder()
    .withUrl(`${process.env.NUXT_ENV_API_URL}/subscribe/notifications`, {
      accessTokenFactory: () => jwt,
    })
    .configureLogging(
      ['local', 'development'].includes(process.env.NODE_ENV ?? '')
        ? LogLevel.Debug
        : LogLevel.None
    )
    .build();

  hub.on('ReceiveNotification', (not: NotificationDetail) => {
    console.debug('Received a notification', not);

    const loggedInId = store.getters['authentication/profileId'];
    if (not.profileId !== loggedInId) {
      console.error(
        `Received a message for a profileId=${not.profileId}, while the user ` +
          `is logged in as loggedInId=${loggedInId}`
      );
      return;
    }

    store.dispatch('notifications/addNewNotification', not);
  });

  return { hub };
}
