
import ChevronIcon from '~/components/ui/icons/ChevronIcon.vue';
export default {
  name: 'DropDownTeamPortfolioOptions',
  components: { ChevronIcon },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  emits: [],
  data() {
    return {
      showMoreMenu: false,
    };
  },
  methods: {
    showMoreMenuOptions() {
      this.showMoreMenu = !this.showMoreMenu;
    },
    handleUpdateRoleToEdit() {
      this.$emit('updateRoleToEdit', {
        profileId: this.item.profileId,
        portfolioId: this.item.portfolioId,
        portfolioUserRole: 'EDIT',
      });
      this.showMoreMenuOptions();
    },
    handleUpdateRoleToViewOnly() {
      this.$emit('updateRoleToView', {
        profileId: this.item.profileId,
        portfolioId: this.item.portfolioId,
        portfolioUserRole: 'VIEW',
      });
      this.showMoreMenuOptions();
    },
    handleRemoveTeamMember() {
      this.$emit('removeTeamPortfolioMember', {
        profileId: this.item.profileId,
        portfolioId: this.item.portfolioId,
      });
      this.showMoreMenuOptions();
    },
    getRoleName(role) {
      switch (role) {
        case 'OWNER':
          return 'team_portfolio_user_role_owner';
        case 'EDIT':
          return 'team_portfolio_user_role_editor';
        case 'VIEW':
          return 'team_portfolio_user_role_viewer';
      }
    },
  },
};
