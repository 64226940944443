
import { mapActions } from 'vuex';

export default {
  name: 'NewPasswordStep',
  props: {
    email: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      newPassword: null,
      passwordConfirmation: null,
    };
  },
  methods: {
    ...mapActions('settings/passwordReset', ['resetPasswordWithToken']),
    async handleSubmit() {
      return this.resetPasswordWithToken({
        password: this.newPassword,
        confirmPassword: this.passwordConfirmation,
        email: this.email,
      })
        .then(() => this.$emit('onSubmitSuccess'))
        .catch(() => {
          this.$emit('onSubmitFail');
        });
    },
  },
};
