import actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

const state = () => ({
  industries: null,
  loading: false,
  followedIndustryloading: false,
  error: false,
  followedIndustryerror: false
});

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
