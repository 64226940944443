
import { mapActions } from 'vuex';
import { inAppRedirect } from '~/utils/route-redirects';

export default {
  name: 'ProfileTags',
  props: {
    // [{id, name, image}]
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    isMobile() {
      return window.screen.width <= 640;
    },
  },
  methods: {
    inAppRedirect,
    ...mapActions('modals', ['mouseEnterMention', 'mouseLeaveMention']),
    handleMouseEnterMention(e, item) {
      if (e && e.target && !this.isMobile) {
        this.mouseEnterMention({
          pos: {
            top: e.target.offsetTop + (e.target.offsetHeight + 8),
            left: e.target.offsetLeft,
          },
          profile: item,
          id: item.id,
        });
      }
    },
    handleMouseOutMention(e, item) {
      if (e && e.target) {
        this.mouseLeaveMention(item.id);
      }
    },
  },
};
