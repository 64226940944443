import { MutationsCreator } from '@/store/utils';
import { findAndReplace } from '@/utils';

export default {
  INIT: state => {
    state.cryptoSymbols.data = [];
    state.cryptoSymbols.error = null;
    state.cryptoSymbols.loading = true;
  },

  SET_CRYPTO_SYMBOLS_TICKERS: (state, payload) => {
    state.cryptoSymbols.data = payload;
  },

  SET_CRYPTO_SYMBOLS_ERROR: (state, er) => {
    state.cryptoSymbols.error = er;
  },
  
  SET_CRYPTO_SYMBOLS_LOADING: (state, payload) => {
    state.cryptoSymbols.loading = payload;
  },
};
