export default {
  init(context) {
    context.commit('INIT');
  },
  getCryptoSymbols(context, cryptoId) { 
    context.commit('INIT');
    // context.commit('SET_CRYPTO_SYMBOLS_LOADING', true);
    this.dispatch('api/getCryptoSymbols', cryptoId)
      .then(res => {
        context.commit('SET_CRYPTO_SYMBOLS_TICKERS', res.data);
      })
      .catch(err => {
        context.commit('SET_CRYPTO_SYMBOLS_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_CRYPTO_SYMBOLS_LOADING', false);
      });

  },
};
