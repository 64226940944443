
import { mapActions, mapGetters } from 'vuex';
import { isPortfolioOwner } from '@/utils/portfolio-role-checks';
import UICreatePortfolioModalv2 from '@/components/ui/organism/UICreatePortfolioModalv2';
import {
  canCreatePortfolio,
  cannotCreatePortfolioReason,
  portfolioLimitReachedError,
  portfolioUniqueNameError,
} from '~/utils/behavior/portfolio-creation';
import { startTransactionAsync } from '~/utils/instrumentation/transactions';
import { showErrorToast } from '~/utils/toast-notifications';
import { ApiApplicationErrorCodes } from '~/utils/behavior/apiErrors';
import { getApplicationError } from '~/utils/behavior/apiErrors';
import { inAppRedirect } from '~/utils/route-redirects';

export default {
  name: 'CreatePortfolioModalWrapper',
  components: { UICreatePortfolioModalv2 },
  computed: {
    ...mapGetters('portfolio', ['createPortfolioRes', 'getMyPortfoliosRes']),
    ...mapGetters('modals', ['showCreatePortfolioModalRes']),
    ...mapGetters('authentication', ['isEmailConfirmed', 'profileId']),
    ...mapGetters('profile', ['hasSeenWTPModalRes', 'profile']),
    myPortfolios() {
      return (
        this.getMyPortfoliosRes?.data?.portfolios?.filter((p) =>
          isPortfolioOwner(p.portfolioUserRole)
        ) || []
      );
    },
    canCreateNewPortfolio() {
      return canCreatePortfolio(
        this.isEmailConfirmed,
        this.myPortfolios.length
      );
    },
    disabledReason() {
      return cannotCreatePortfolioReason(
        this.isEmailConfirmed,
        this.myPortfolios.length
      );
    },
  },
  watch: {
    'createPortfolioRes.data'() {
      if (this.createPortfolioRes.data) {
        if (this.showCreatePortfolioModalRes) {
          this.showCreatePortfolioModal();
        }
        inAppRedirect('/portfolios/' + this.createPortfolioRes.data.id);
      }
    },
  },
  methods: {
    ...mapActions('portfolio', ['createPortfolio']),
    ...mapActions('modals', [
      'showCreatePortfolioModal',
      'showPortfolioEmptyStateModal',
    ]),
    ...mapActions('profile', ['getProfile']),
    async handleCreate(data) {
      try {
        await startTransactionAsync('Create Portfolio', async () => {
          await this.createPortfolio({
            name: data.name,
            description: data.description,
            isPublic: data.privacy == 'public',
            currencyCode: data.currency,
            type: data.type,
            cashAmounts: data.cashAmounts,
            date: data.date,
          });

          if (this.createPortfolioRes.data) {
            if (this.showCreatePortfolioModalRes) {
              this.showCreatePortfolioModal();
            }
            inAppRedirect('/portfolios/' + this.createPortfolioRes.data.id);
          }
          // TODO currently our api actions aren't consistent and dont allow errors to bubble up
          // so we can't catch errors here. A JIRA ticket should be created to handle this
          else throw new Error('Failed to create portfolio');
        });
      } catch (e) {
        console.error('Create portfolio transaction failed', e);

        const apiErrorCode = String(this.createPortfolioRes?.error?.errorCode);
        const errorCode = getApplicationError(apiErrorCode);

        let errorTranslationKey = 'error_occured_message';

        switch (errorCode) {
          case ApiApplicationErrorCodes.BadRequest
            .MaximumPortfoliosPerProfileReached:
            errorTranslationKey = portfolioLimitReachedError;
            break;
          case ApiApplicationErrorCodes.BadRequest.UniquePortfolioName:
            errorTranslationKey = portfolioUniqueNameError;
            break;
        }

        showErrorToast(this.$t(errorTranslationKey));
      }
    },
    handleClose() {
      this.showCreatePortfolioModal();
    },
  },
};
