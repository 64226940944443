
import DropDownTeamPortfolioOptions from '@/components/ui/molecule/DropDownTeamPortfolioOptions.vue';
export default {
  name: 'UITeamPortfolioProfilesTable',
  components: { DropDownTeamPortfolioOptions },
  props: {
    tableItems: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    handleUpdateToEdit(payload) {
      this.$emit('updateRoleToEdit', payload);
    },
    handleUpdateToViewOnly(payload) {
      this.$emit('updateRoleToView', payload);
    },
    handleRemoveTeamPortfolioMember(payload) {
      this.$emit('removeTeamPortfolioMember', payload);
    },
  },
};
