import actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

const state = () => ({
  companyFollowers: [],
  companyFollowersPage: 1,
  companyFollowersError: null,
  companyFollowersLoading: false,
});

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
