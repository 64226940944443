/**
 * Truncates the given text, adding ellipses as required.
 *
 * WARNING: There is a built-in CSS feature for this. Avoid using if all you're
 * trying to do is truncate text.
 *
 * @param text - The text to truncate
 * @param maxLength - The number of characters to truncate the text to.
 */
export function truncate(text: string, maxLength = 40): string {
  return text.length <= maxLength
    ? text
    : text.substring(0, maxLength - 1) + '…';
}

