import actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

const state = () => ({
  feed: null,
  feedLoading: false,
  nextPostsLoading: false,
  error: null,
  headlines: null,
  headlinesLoading: false,
  headlinesError: null,
  trendingIndices: [],
  trendingIndicesError: null,
  trendingIndicesLoading: false,
  allTrendingIndices: [],
  allTrendingIndicesError: null,
  allTrendingIndicesLoading: false,
});

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
