
import UISimpleTickerCard from '@/components/ui/organism/UISimpleTickerCard.vue';
import TickerSearchResultCard from '~/components/ui/molecule/TickerSearchResultCard.vue';
import SearchIcon from '~/components/ui/icons/SearchIcon.vue';
import { debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'UITickerSearchWithCards',
  components: { UISimpleTickerCard, TickerSearchResultCard, SearchIcon },
  props: {
    tickersToAdd: {
      type: Array,
      default() {
        return [];
      },
    },
    selectedTicker: {
      type: Object,
      default() {
        return {};
      },
    },
    buttonState: {
      type: String,
      default: 'disabled',
    },
    showResultsDropdown: {
      type: Boolean,
      default: true,
    },
    limitReachedText: {
      type: String,
      default: '',
    },
    comparisonLimitReached: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      query: '',
      pickerVisible: false,
      currentIndex: 0,
    };
  },
  computed: {
    ...mapGetters('search', ['searchRes']),
    filteredResults() {
      if (!this.searchRes.data?.symbolsWithPrices) return [];

      return this.searchRes.data.symbolsWithPrices.filter(
        (ticker) => ticker.symbolId !== this.selectedTicker.symbolId
      );
    },
  },
  methods: {
    ...mapActions('search', ['search']),
    handleSearchDebounce: debounce(function (query) {
      if (!query) {
        return;
      }

      this.search({
        type: 'symbol',
        query: this.query,
        limit: 10,
        getDetailsSearch: true,
      });
    }, 300),
    handleSearch() {
      this.handleSearchDebounce(this.query);
    },
    handleClickOutside() {
      this.pickerVisible = false;
    },
    handleSubmit() {
      this.$emit('onSubmit');
    },
    getTickerCardClasses(symbolId) {
      const isTickerPicked = this.tickersToAdd.some(
        (ticker) => ticker.symbolId === symbolId
      );

      if (isTickerPicked) {
        return 'bg-primary-2 pointer';
      }

      return this.comparisonLimitReached
        ? 'opacity-50 cursor-not-allowed'
        : 'pointer';
    },
    handleCardClick(ticker) {
      this.query = '';
      this.$emit('addTicker', ticker);
    },
    clearSearchQuery() {
      this.query = '';
    },
  },
};
