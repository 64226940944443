import { MutationsCreator } from '@/store/utils';
import { findAndReplace } from '@/utils';

export default {
  INIT: state => {
    state.similarCompanies = [];
    state.SimilarCompaniesPage = 1;
    state.SimilarCompaniesError = null;
    state.SimilarCompaniesLoading = false;
    state.isViewMoreVisible = true;
  },

  // Mutual Follows
  SET_SIMILAR_COMPANIES: (state, payload) => {
    state.similarCompanies = payload;
  },
  SET_SIMILAR_COMPANIES_LOADING: (state, isLoading) => {
    state.similarCompaniesLoading = isLoading;
  },
  SET_VIEW_MORE_VISIBLE: (state, isVisible) => {
    state.isViewMoreVisible = isVisible;
  },
  SET_SIMILAR_COMPANIES_ERROR: (state, er) => {
    state.similarCompaniesError = er;
  },
  INCREASE_SIMILAR_COMPANIES_PAGE: (state, er) => {
    state.similarCompaniesPage++;
  },
  RESET_SIMILAR_COMPANIES_PAGE: (state, er) => {
    state.similarCompaniesPage = 0;
  },
  UPDATE_SIMILAR_COMPANY(state, company) {
    if (state.similarCompanies) {
      let index = state.similarCompanies.findIndex(
        e => e.profileId === company.profileId
      );

      if (index !== -1) {
        state.similarCompanies[index].recommendationScore =
          company.followerCount;
        state.similarCompanies[index].isFollowed = company.isFollowed;
      }
    }
  }
};
