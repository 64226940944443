import { EPromiseState } from '~/store/types';
import { NSRootModule } from '~/store/types';
import { SearchState } from './types';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state: SearchState = {
  showSearchHistory: true,
  searchPopupShown: false,
  query: '',
  userSearch: {
    data: null,
    error: null,
    state: EPromiseState.None,
  },
  searchHistory: { data: [], state: EPromiseState.None, error: null },
  search: { data: null, loading: null, error: null },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
} as NSRootModule<SearchState>;
