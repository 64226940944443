
import { mapActions, mapGetters } from 'vuex';
import Banner from '@/components/ui/atom/Banner';

export default {
  name: 'EmailValidationBannerWrapper',
  components: { Banner },
  data() {
    return {
      mailStatusInterval: null,
    };
  },
  computed: {
    ...mapGetters('modals', ['helpCenterObjectRes']),
    ...mapGetters('authentication', ['isEmailConfirmed']),
    ...mapGetters('onboarding', ['hasCompletedTutorial']),
  },
  created() {
    this.mailStatusInterval = setInterval(this.getMailValidationStatus, 600000);
  },
  beforeDestroy() {
    clearInterval(this.mailStatusInterval);
  },
  methods: {
    ...mapActions('authentication', [
      'sendValidationMail',
      'getMailValidationStatus',
    ]),
  },
};
