import * as getters from './getters';

import actions from './actions';
import mutations from './mutations';

const state = () => ({
  crypto: null,
  getCrypto: {
    loading: false,
    error: null,
  },
  getNextCrypto: {
    loading: false,
    error: null,
  },
  followCrypto: {
    loading: false,
    error: null,
  },
});

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
