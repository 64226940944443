export default {
  UPDATE_POST: (state, payload) => {
    if (state.post) {
      state.post = payload;
    }
  },
  UPDATE_REPLY: (state, payload) => {
    if (state.replies) {
      const index = state.replies.findIndex((e) => e.postId === payload.postId);

      if (index !== -1) {
        const res = [...state.replies];

        // Reply-related info are not sent back from the API
        const replies = res[index].replies;
        const showLoadMore = res[index].showLoadMore;

        res[index] = payload;
        res[index].replies = replies;
        res[index].showLoadMore = showLoadMore;
        state.replies = res;
      }
    }
  },
  UPDATE_NESTED_REPLY: (state, payload) => {
    if (state.replies) {
      const replyIndex = state.replies.findIndex(
        (e) => e.postId === payload.replyId
      );

      const nestedIndex = state.replies[replyIndex].replies.findIndex(
        (p) => p.postId === payload.nestedReply.postId
      );

      if (replyIndex !== -1 && nestedIndex !== -1) {
        const replies = [...state.replies];
        const nestedReplies = [...replies[replyIndex].replies];

        nestedReplies[nestedIndex] = payload.nestedReply;
        replies[replyIndex].replies = nestedReplies;

        state.replies = replies;
      }
    }
  },
  SET_POST: (state, payload) => {
    state.post = payload;
  },
  SET_REPLIES: (state, payload) => {
    state.replies = payload;
  },
  SET_NESTED_REPLIES: (state, payload) => {
    if (state.replies) {
      const index = state.replies.findIndex((e) => e.postId === payload.postId);
      if (index !== -1) {
        const res = [...state.replies];
        res[index].replies = payload.replies;
        state.replies = res;
      }
    }
  },
  APPEND_REPLIES: (state, payload) => {
    state.replies = [...state.replies, ...payload];
  },
  APPEND_NEW_REPLY: (state, payload) => {
    if (state.isLoadFinished) state.replies = [...state.replies, payload];
  },
  APPEND_NESTED_REPLIES: (state, payload) => {
    if (state.replies) {
      const index = state.replies.findIndex((e) => e.postId === payload.postId);

      if (index !== -1) {
        const res = [...state.replies];

        res[index].replies = [...res[index].replies, ...payload.replies];
        state.replies = res;
      }
    }
  },
  APPEND_NEW_NESTED_REPLY: (state, payload) => {
    if (state.replies) {
      const index = state.replies.findIndex((e) => e.postId === payload.postId);

      if (index !== -1) {
        const res = [...state.replies];
        if (!res[index].replies) res[index].replies = [];

        res[index].replies = [...res[index].replies, payload.post];
        state.replies = res;
      }
    }
  },
  SET_NESTED_REPLY_VIEW_MORE: (state, payload) => {
    if (state.replies) {
      const index = state.replies.findIndex((e) => e.postId === payload.postId);
      if (index !== -1) {
        const res = [...state.replies];
        res[index].showLoadMore = payload.show;
        state.replies = res;
      }
    }
  },
  SET_POST_LOADING: (state, isLoading) => {
    state.postLoading = isLoading;
  },
  SET_REPLIES_LOADING: (state, isLoading) => {
    state.repliesLoading = isLoading;
  },
  SET_NEXT_REPLIES_LOADING: (state, isLoading) => {
    state.nextRepliesLoading = isLoading;
  },
  SET_NESTED_REPLIES_LOADING: (state, isLoading) => {
    state.nextRepliesLoading = isLoading;
  },
  SET_POST_ERROR: (state, error) => {
    state.postError = error;
    window.$nuxt.$notify.error('Error in post page : post');
  },
  SET_REPLIES_ERROR: (state, error) => {
    state.repliesError = error;
    window.$nuxt.$notify.error('Error in post page : replies');
  },

  INIT_PAGE: (state) => {
    state.post = null;
    state.postLoading = false;
    state.postError = null;
    state.replies = null;
    state.repliesLoading = false;
    state.repliesError = null;
    state.newReplyCount = 0;
    state.isLoadFinished = false;
  },
  INIT_POST: (state) => {
    state.post = null;
    state.postLoading = false;
    state.postError = null;
  },
  INIT_REPLIES: (state) => {
    state.replies = null;
    state.repliesLoading = false;
    state.repliesError = null;
  },
  INCREMENT_POST_REPLYCOUNT: (state) => {
    if (state.post) {
      state.post.replyCount++;
    }
  },
  INCREMENT_REPLY_REPLYCOUNT: (state, postId) => {
    if (state.replies) {
      const index = state.replies.findIndex((e) => e.postId === postId);

      if (index != -1) {
        state.replies[index].replyCount++;
      }
    }
  },
  INCREMENT_NESTED_REPLY_REPLYCOUNT: (state, payload) => {
    if (state.replies) {
      const replyIndex = state.replies.findIndex(
        (e) => e.postId === payload.replyId
      );

      if (replyIndex > -1 && state.replies[replyIndex].replies) {
        const nestedIndex = state.replies[replyIndex].replies.findIndex(
          (p) => p.postId === payload.nestedReplyId
        );

        if (nestedIndex !== -1) {
          const replies = [...state.replies];
          const nestedReplies = [...replies[replyIndex].replies];

          nestedReplies[nestedIndex].replyCount++;
          replies[replyIndex].replies = nestedReplies;

          state.replies = replies;
        }
      }
    }
  },
  INCREMENT_POST_REPOSTCOUNT: (state) => {
    if (state.post) {
      state.post.repostCount++;
    }
  },
  INCREMENT_REPLY_REPOSTCOUNT: (state, postId) => {
    if (state.replies) {
      const index = state.replies.findIndex((e) => e.postId === postId);

      if (index != -1) {
        state.replies[index].repostCount++;
      }
    }
  },
  INCREMENT_NESTED_REPLY_REPOSTCOUNT: (state, payload) => {
    if (state.replies) {
      const replyIndex = state.replies.findIndex(
        (e) => e.postId === payload.replyId
      );

      if (replyIndex > -1 && state.replies[replyIndex].replies) {
        const nestedIndex = state.replies[replyIndex].replies.findIndex(
          (p) => p.postId === payload.nestedReplyId
        );

        if (nestedIndex !== -1) {
          const replies = [...state.replies];
          const nestedReplies = [...replies[replyIndex].replies];

          nestedReplies[nestedIndex].repostCount++;
          replies[replyIndex].replies = nestedReplies;

          state.replies = replies;
        }
      }
    }
  },
  SET_IS_LOAD_FINISHED: (state, value) => {
    state.isLoadFinished = value;
  },
};
