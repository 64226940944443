
import Vue, { PropType } from 'vue';
import { SearchResult } from '~/types/models/search';
import SearchResultEntry from '~/components/ui/organism/SearchPopup/SearchResultEntry.vue';
import { appURLs as _appURLs } from '~/utils/route-redirects';
// @ts-ignore
import Avatar from 'vue-avatar';
import {
  defaultCompanyPictureUri,
  defaultProfilePictureUri,
} from '@/utils/behavior/images';
import { getWindowInstance } from '~/plugins/windowInstance';
import { mapGetters } from 'vuex';

/**
 * Represents an item from the user history entry.
 *
 * @property id {string} The identifier of the search history entry
 * @property query {string} The user's search query
 * @property searchTypes {string[]} The types of search the user issued
 */
declare type SearchHistoryEntry = {
  id: string;
  query: string;
  searchTypes: string[];
};

/**
 * The search popup that occurs when opening the search menu.
 *
 * Designed to be responsive on mobile.
 */
export default Vue.extend({
  name: 'V2SearchPopup',
  components: {
    SearchResultEntry,
    Avatar,
  },
  props: {
    query: {
      type: String,
      required: true,
    },
    history: {
      type: Array as PropType<SearchHistoryEntry[]>,
      required: true,
    },
    searchResults: {
      required: false,
      type: Object as PropType<SearchResult | null>,
      default: null,
    },
    searchResultsLoaded: {
      required: true,
      type: Boolean,
      default: false,
    },
    shown: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      defaultCompanyPictureUri: defaultCompanyPictureUri,
      defaultProfilePictureUri: defaultProfilePictureUri,
    };
  },
  computed: {
    ...mapGetters('search', {
      _showSearchHistory: 'showSearchHistory',
    }),
    appURLs() {
      return _appURLs;
    },
    // only show search history when the search is empty
    showSearchHistory(): boolean {
      return this._showSearchHistory && this.history.length > 0;
    },
    showEmptySearchHistory(): boolean {
      return this._showSearchHistory && this.history.length === 0;
    },
    showSearchResults(): boolean {
      return (
        !this._showSearchHistory &&
        (this.showMentionsResults || this.showSymbolsResults)
      );
    },
    showEmptySearchResults(): boolean {
      return (
        !this._showSearchHistory &&
        !this.showMentionsResults &&
        !this.showSymbolsResults
      );
    },
    showSymbolsResults(): boolean {
      return (this.searchResults?.symbols?.length ?? 0) > 0;
    },
    showMentionsResults(): boolean {
      return (this.searchResults?.mentions?.length ?? 0) > 0;
    },
    isMobileResponsive(): boolean {
      // @ts-ignore TODO fix typescript not recognizing this
      return getWindowInstance(this).isMobileResponsive();
    },
    renderPopup(): boolean {
      return (
        this.shown &&
        // on mobile, if the popup is supposed to be shown, keep it shown
        (this.isMobileResponsive ||
          this.showEmptySearchHistory ||
          this.showSearchHistory ||
          this.showEmptySearchResults ||
          this.showSearchResults)
      );
    },
  },
  methods: {
    searchResultsByQueryURL(query: string): string {
      return this.localePath(_appURLs.searchResultsByQuery(query));
    },
    async deleteSearchHistory(): Promise<void> {
      // @ts-ignore TODO fix this typescript error
      await this.$api.deleteSearchHistory();
    },
  },
});
