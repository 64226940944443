import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _239a5fd3 = () => interopDefault(import('../pages/communityPortfolios/index.vue' /* webpackChunkName: "pages/communityPortfolios/index" */))
const _65fd9950 = () => interopDefault(import('../pages/crypto/index.vue' /* webpackChunkName: "pages/crypto/index" */))
const _1b92a52e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _f5c905fa = () => interopDefault(import('../pages/leagues/index.vue' /* webpackChunkName: "pages/leagues/index" */))
const _4490f913 = () => interopDefault(import('../pages/market/index.vue' /* webpackChunkName: "pages/market/index" */))
const _64c5d9ba = () => interopDefault(import('../pages/messages/index.vue' /* webpackChunkName: "pages/messages/index" */))
const _085b9f2e = () => interopDefault(import('../pages/notifications-center.vue' /* webpackChunkName: "pages/notifications-center" */))
const _ba135b9c = () => interopDefault(import('../pages/onboarding/index.vue' /* webpackChunkName: "pages/onboarding/index" */))
const _bf5d277c = () => interopDefault(import('../pages/portfolios/index.vue' /* webpackChunkName: "pages/portfolios/index" */))
const _56e60863 = () => interopDefault(import('../pages/saved.vue' /* webpackChunkName: "pages/saved" */))
const _1449d9f0 = () => interopDefault(import('../pages/search-hashtags.vue' /* webpackChunkName: "pages/search-hashtags" */))
const _485c6d3a = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _8e4ea898 = () => interopDefault(import('../pages/stocks/index.vue' /* webpackChunkName: "pages/stocks/index" */))
const _ef12918c = () => interopDefault(import('../pages/iframe/session-check.vue' /* webpackChunkName: "pages/iframe/session-check" */))
const _7255ec63 = () => interopDefault(import('../pages/portfolios/settings.vue' /* webpackChunkName: "pages/portfolios/settings" */))
const _436e1ad1 = () => interopDefault(import('../pages/profile/recommended.vue' /* webpackChunkName: "pages/profile/recommended" */))
const _a1d342f8 = () => interopDefault(import('../pages/portfolios/_portfolioId.vue' /* webpackChunkName: "pages/portfolios/_portfolioId" */))
const _32a78b42 = () => interopDefault(import('../pages/post/_postId.vue' /* webpackChunkName: "pages/post/_postId" */))
const _707267c2 = () => interopDefault(import('../pages/profile/_profileId.vue' /* webpackChunkName: "pages/profile/_profileId" */))
const _13e41d19 = () => interopDefault(import('../pages/searchResult/_index.vue' /* webpackChunkName: "pages/searchResult/_index" */))
const _666286ea = () => interopDefault(import('../pages/portfolios/_portfolio/comments.vue' /* webpackChunkName: "pages/portfolios/_portfolio/comments" */))
const _20db5db1 = () => interopDefault(import('../pages/portfolios/_portfolio/_symbol.vue' /* webpackChunkName: "pages/portfolios/_portfolio/_symbol" */))
const _042beb0c = () => interopDefault(import('../pages/profile/_profile/_symbol.vue' /* webpackChunkName: "pages/profile/_profile/_symbol" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/communityPortfolios",
    component: _239a5fd3,
    name: "communityPortfolios___fr"
  }, {
    path: "/crypto",
    component: _65fd9950,
    name: "crypto___fr"
  }, {
    path: "/en",
    component: _1b92a52e,
    name: "index___en"
  }, {
    path: "/leagues",
    component: _f5c905fa,
    name: "leagues___fr"
  }, {
    path: "/market",
    component: _4490f913,
    name: "market___fr"
  }, {
    path: "/messages",
    component: _64c5d9ba,
    name: "messages___fr"
  }, {
    path: "/notifications-center",
    component: _085b9f2e,
    name: "notifications-center___fr"
  }, {
    path: "/onboarding",
    component: _ba135b9c,
    name: "onboarding___fr"
  }, {
    path: "/portfolios",
    component: _bf5d277c,
    name: "portfolios___fr"
  }, {
    path: "/saved",
    component: _56e60863,
    name: "saved___fr"
  }, {
    path: "/search-hashtags",
    component: _1449d9f0,
    name: "search-hashtags___fr"
  }, {
    path: "/settings",
    component: _485c6d3a,
    name: "settings___fr"
  }, {
    path: "/stocks",
    component: _8e4ea898,
    name: "stocks___fr"
  }, {
    path: "/en/communityPortfolios",
    component: _239a5fd3,
    name: "communityPortfolios___en"
  }, {
    path: "/en/crypto",
    component: _65fd9950,
    name: "crypto___en"
  }, {
    path: "/en/leagues",
    component: _f5c905fa,
    name: "leagues___en"
  }, {
    path: "/en/market",
    component: _4490f913,
    name: "market___en"
  }, {
    path: "/en/messages",
    component: _64c5d9ba,
    name: "messages___en"
  }, {
    path: "/en/notifications-center",
    component: _085b9f2e,
    name: "notifications-center___en"
  }, {
    path: "/en/onboarding",
    component: _ba135b9c,
    name: "onboarding___en"
  }, {
    path: "/en/portfolios",
    component: _bf5d277c,
    name: "portfolios___en"
  }, {
    path: "/en/saved",
    component: _56e60863,
    name: "saved___en"
  }, {
    path: "/en/search-hashtags",
    component: _1449d9f0,
    name: "search-hashtags___en"
  }, {
    path: "/en/settings",
    component: _485c6d3a,
    name: "settings___en"
  }, {
    path: "/en/stocks",
    component: _8e4ea898,
    name: "stocks___en"
  }, {
    path: "/iframe/session-check",
    component: _ef12918c,
    name: "iframe-session-check___fr"
  }, {
    path: "/portfolios/settings",
    component: _7255ec63,
    name: "portfolios-settings___fr"
  }, {
    path: "/profile/recommended",
    component: _436e1ad1,
    name: "profile-recommended___fr"
  }, {
    path: "/en/iframe/session-check",
    component: _ef12918c,
    name: "iframe-session-check___en"
  }, {
    path: "/en/portfolios/settings",
    component: _7255ec63,
    name: "portfolios-settings___en"
  }, {
    path: "/en/profile/recommended",
    component: _436e1ad1,
    name: "profile-recommended___en"
  }, {
    path: "/",
    component: _1b92a52e,
    name: "index___fr"
  }, {
    path: "/en/portfolios/:portfolioId",
    component: _a1d342f8,
    name: "portfolios-portfolioId___en"
  }, {
    path: "/en/post/:postId?",
    component: _32a78b42,
    name: "post-postId___en"
  }, {
    path: "/en/profile/:profileId?",
    component: _707267c2,
    name: "profile-profileId___en"
  }, {
    path: "/en/searchResult/:index",
    component: _13e41d19,
    name: "searchResult___en"
  }, {
    path: "/en/portfolios/:portfolio/comments",
    component: _666286ea,
    name: "portfolios-portfolio-comments___en"
  }, {
    path: "/en/portfolios/:portfolio/:symbol?",
    component: _20db5db1,
    name: "portfolios-portfolio-symbol___en"
  }, {
    path: "/en/profile/:profile?/:symbol?",
    component: _042beb0c,
    name: "profile-profile-symbol___en"
  }, {
    path: "/portfolios/:portfolioId",
    component: _a1d342f8,
    name: "portfolios-portfolioId___fr"
  }, {
    path: "/post/:postId?",
    component: _32a78b42,
    name: "post-postId___fr"
  }, {
    path: "/profile/:profileId?",
    component: _707267c2,
    name: "profile-profileId___fr"
  }, {
    path: "/searchResult/:index",
    component: _13e41d19,
    name: "searchResult___fr"
  }, {
    path: "/portfolios/:portfolio/comments",
    component: _666286ea,
    name: "portfolios-portfolio-comments___fr"
  }, {
    path: "/portfolios/:portfolio/:symbol?",
    component: _20db5db1,
    name: "portfolios-portfolio-symbol___fr"
  }, {
    path: "/profile/:profile?/:symbol?",
    component: _042beb0c,
    name: "profile-profile-symbol___fr"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
