export default {
  CREATE_ADMIN_LEAGUE_INIT: (state) => {
    state.createAdminleague = { data: null, loading: true, error: null };
  },
  CREATE_ADMIN_LEAGUE_SUCCESS: (state, data) => {
    state.createAdminleague.data = data;
    state.createAdminleague.loading = false;
  },
  CREATE_ADMIN_LEAGUE_ERROR: (state, error) => {
    state.createAdminleague.error = error;
    state.createAdminleague.loading = false;
  },
  APPEND_LEAGUES_TO_LEAGUES: (state, payload) => {
    if (state.leagues.data)
      state.leagues.data = [...state.leagues.data, ...payload];
  },
  GET_LEAGUES_INIT: (state) => {
    state.league = { data: [], loading: false, error: null };
  },
  GET_LEAGUES_SUCCESS: (state, data) => {
    state.leagues.data = data;
    state.leagues.loading = false;
  },
  GET_LEAGUES_ERROR: (state, error) => {
    state.leagues.error = error;
    state.leagues.loading = false;
  },
  GET_MORE_LEAGUES_START: (state) => {
    state.moreLeaguesLoading = true;
  },
  GET_MORE_LEAGUES_SUCCESS: (state) => {
    state.moreLeaguesLoading = false;
  },
  GET_MORE_LEAGUES_ERROR: (state) => {
    state.moreLeaguesLoading = false;
  },
  GET_LEADERBOARD_START: (state) => {
    state.leaderboard = { data: [], loading: false, error: null };
  },
  GET_LEADERBOARD_SUCCESS: (state, data) => {
    state.leaderboard.data = data;
    state.leaderboard.loading = false;
  },
  GET_LEADERBOARD_ERROR: (state, error) => {
    state.leaderboard.error = error;
    state.leaderboard.loading = false;
  },
  APPEND_PROFILES_TO_LEADERBOARD: (state, payload) => {
    if (state.leaderboard.data)
      state.leaderboard.data = [...state.leaderboard.data, ...payload];
  },
};
