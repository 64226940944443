import { MutationsCreator } from '@/store/utils';

export default {
  INIT: state => {
    state.companyFollowers = [];
    state.CompanyFollowersPage = 1;
    state.CompanyFollowersError = null;
    state.CompanyFollowersLoading = false;
  },

  // Mutual Follows
  SET_COMPANY_FOLLOWERS: (state, payload) => {
    state.companyFollowers = payload;
  },
  SET_COMPANY_FOLLOWERS_LOADING: (state, isLoading) => {
    state.companyFollowersLoading = isLoading;
  },
  SET_COMPANY_FOLLOWERS_ERROR: (state, er) => {
    state.companyFollowersError = er;
  },
  INCREASE_COMPANY_FOLLOWERS_PAGE: (state, er) => {
    state.companyFollowersPage++;
  },
  RESET_COMPANY_FOLLOWERS_PAGE: (state, er) => {
    state.companyFollowersPage = 0;
  },
  UPDATE_COMPANY_FOLLOWER(state, follower) {
    if (state.companyFollowers) {
      let index = state.companyFollowers.findIndex(
        e => e.profileId === follower.profileId
      );

      if (index !== -1) {
        state.companyFollowers[index].recommendationScore =
          follower.followerCount;
        state.companyFollowers[index].isFollowed = follower.isFollowed;
      }
    }
  }
};
