
export default {
  name: 'DateFromNow',
  computed: {
    fromNow() {
      return this.$dayjs(this.date)
        .subtract('1', 's')
        .startOf('s')
        .fromNow();
    }
  },
  props: {
    date: {
      required: true
    }
  }
};
