
import { inAppRedirect } from '~/utils/route-redirects';

export default {
  name: 'NotificationAddedToPortfolio',
  props: {
    notif: {
      required: true,
      type: Object,
    },
  },
  methods: {
    goToTarget(targetId) {
      inAppRedirect(`/portfolios/${targetId}`);
    },
  },
};
