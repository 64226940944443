
export default {
  name: 'Spinner',
  props: {
    size: {
      type: Number,
      default: 40
    }
  }
};
