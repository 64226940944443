import { blob2base64, optimizeImg } from '@/utils';
import { mapActions, mapGetters } from 'vuex';

import { mapMutations } from 'vuex';

export const topNavHeader = {
  methods: {
    ...mapMutations('ui-messaging', [
      'SET_PAGE_TITLE',
      'SET_PAGE_NESTED',
      'SET_PAGE_MENU_MODE',
      'SET_PAGE_ACTION',
    ]),
    /**
     * Set top nav header. If no props are passed, it will reset the header to the default state.
     * @param {object} props Object containing the following properties:
     * @param {boolean} props.pageNested Whether the page is nested within another page.
     * @param {string} props.pageTitle The title of the page.
     * @param {string} props.pageMenuMode The menu mode of the page.
     * @param {string} props.pageActionRoute The route of the action button.
     * @param {string} props.pageActionIcon The icon of the action button.
     */
    setTopNavHeader(
      props = {
        pageNested: false,
        pageTitle: '',
        pageMenuMode: 'burgerMenu',
        pageActionRoute: undefined,
        pageActionIcon: 'profile',
      }
    ) {
      const {
        pageNested,
        pageTitle,
        pageMenuMode,
        pageActionRoute,
        pageActionIcon,
      } = props;
      this.SET_PAGE_NESTED(pageNested);
      this.SET_PAGE_TITLE(pageTitle);
      this.SET_PAGE_MENU_MODE(pageMenuMode);
      this.SET_PAGE_ACTION({
        route: pageActionRoute,
        icon: pageActionIcon,
      });
    },
  },
};

// get media of post if is images or gif
export const getMedia = {
  methods: {
    getMedia(post) {
      if (post.entityType == 'NEWS') {
        return post.newsArticleDetail.thumbnailUri
          ? [{ src: post.newsArticleDetail.thumbnailUri }]
          : [];
      } else if (post.images && post.images.length) {
        return post.images.map((e) => ({ src: e.uri }));
      } else if (post.gif) {
        //TODO bad methode to get gif
        return [
          {
            // eslint-disable-next-line no-undef
            src: `${process.env.NUXT_ENV_GIPHY_URI}${post.gif}?api_key=${process.env.NUXT_ENV_GIPHY_API_KEY}`,
          },
        ];
      }
    },
  },
};

// get assets uri from api
export const buildAssetUri = {
  methods: {
    buildAssetUri(src, defaultAsset = '') {
      if (!src) {
        return defaultAsset;
      } else if (
        src.indexOf('/_nuxt/assets/') == 0 ||
        src.indexOf('http://') == 0 ||
        src.indexOf('https://') == 0 ||
        src.indexOf('data:image') == 0
      ) {
        return src;
      } else {
        // eslint-disable-next-line no-undef
        return process.env.NUXT_ENV_ASSETS_URL + src;
      }
    },
  },
};

// upload post image
export const uploadPostImage = {
  computed: {
    ...mapGetters('post', ['uploadPostImageRes']),
  },
  methods: {
    ...mapActions('post', ['uploadPostImage']),
    uploadImages(images) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve) => {
        for (const image of images) {
          const optimizedImage = await optimizeImg(image);

          const formData = new FormData();
          formData.append('file', new File([optimizedImage], 'image.jpeg'));

          await this.uploadPostImage(formData);

          if (this.uploadPostImageRes.data) {
            image.preview = await blob2base64(optimizedImage);
            image.id = this.uploadPostImageRes.data.id;
          }
        }

        resolve(images);
      });
    },

    // TODO fix this
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    removeImage(_id) {
      // TODO
    },
  },
};

// search mentions
export const searchMentions = {
  computed: {
    ...mapGetters('search', ['searchRes']),
  },
  methods: {
    ...mapActions('search', ['search']),
    async searchMentions(searchMentions) {
      return new Promise((resolve) => {
        if (!searchMentions) {
          resolve([]);
        } else {
          this.search({ query: searchMentions, type: '@', limit: 5 }).finally(
            () => {
              if (this.searchRes.data && this.searchRes.data.mentions) {
                resolve(
                  this.searchRes.data.mentions.map((e) => ({
                    id: e.profileId,
                    value: e.profileName,
                    displayName: e.displayName,
                    avatarUri: e.avatarUri,
                    char: '@',
                  })) || []
                );
              } else {
                resolve([]);
              }
            }
          );
        }
      });
    },
  },
};

// search hashtags
export const searchHashtags = {
  computed: {
    ...mapGetters('search', ['searchRes']),
  },
  methods: {
    ...mapActions('search', ['search']),
    async searchHashtags(searchMentions) {
      return new Promise((resolve) => {
        if (!searchMentions) {
          resolve([]);
        } else {
          this.search({ query: searchMentions, type: '#', limit: 5 }).finally(
            () => {
              if (this.searchRes.data && this.searchRes.data.hashtags) {
                resolve(
                  this.searchRes.data.hashtags.map((e) => ({
                    id: e.id,
                    value: e.hashtag,
                    char: '#',
                  })) || []
                );
              } else {
                resolve([]);
              }
            }
          );
        }
      });
    },
  },
};
