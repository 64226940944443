
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'CreatePortfolioOperationModalFooter',
  props: {
    buttonState: {
      type: String,
      default: 'disabled',
    },
    buttonTooltip: {
      type: String,
      default: '',
    },
    totalAmount: {
      type: Number,
      default: 0,
    },
    chosenCurrency: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('modals', ['createPortfolioOperationModal']),
  },
  methods: {
    ...mapActions('modals', ['submitPortfolioOperationForm']),
    handleOperationSubmit(multipleTransactions) {
      this.submitPortfolioOperationForm(multipleTransactions);
    },
  },
};
