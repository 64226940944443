/**
 * Generates post URL that is used for sharing on socials
 * @param postId post Id we want to create an URL for
 * @returns URL that leads to the post
 */
export function generatePostUrl(postId: string): string {
  const origin = process.env.NUXT_ENV_ORIGIN;
  const localePath = window.$nuxt.localePath(`/post/${postId}`);

  const url = origin + localePath;

  return url;
}

/**
 * Generates portfolio URL that is used for sharing on socials
 * @param portfolioId portfolio Id we want to create an URL for
 * @returns URL that leads to the portfolio
 */
export function generatePortfolioUrl(portfolioId: string): string {
  const origin = process.env.NUXT_ENV_ORIGIN;
  const localePath = window.$nuxt.localePath(`/portfolios/${portfolioId}`);

  const url = origin + localePath;

  return url;
}
