import { Axios } from 'axios';
import { SearchHistory } from '~/types/api/search';
import fclient from './fclient';

const c = fclient('/search');

export const search = (a: Axios, payload: object) => {
  return c(a).get({ params: payload });
};

export const getSearchHistory = (a: Axios, { count }: { count: number }) =>
  c(a, '/history').get<SearchHistory>({ params: { count } });
