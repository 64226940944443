import * as apiSearch from './search';

import { getAccessToken } from '~/utils/behavior/authService';
import { getLocaleFromCookie } from '~/utils/cookies/getLocaleFromCookie';

export default function ({ $axios, store }, inject) {
  console.debug('Registering API plugin');
  const api = new (class apiClient {
    constructor(token = '', profileId = '') {
      this.token = token;
      this.profileId = profileId;
      this.init();
    }

    init() {
      this.api = $axios.create({
        // eslint-disable-next-line no-undef
        baseURL: process.env.NUXT_ENV_API_URL,
        timeout: 100000,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.token,
          profileId: this.profileId ? this.profileId : '', // TODO check if this is needed or if the interceptor is enough
        },
      });

      this.authApi = $axios.create({
        // eslint-disable-next-line no-undef
        baseURL: process.env.NUXT_ENV_API_OIDC,
        timeout: 1000,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.token,
        },
      });

      this.api.interceptors.request.use(
        async (config) => {
          const jwt = await getAccessToken();

          const locale = getLocaleFromCookie();

          config.headers = {
            Authorization: 'Bearer ' + jwt,
            profileId: localStorage.getItem('profileId'), // TODO check if the configuration above is enough or if both are required
            'Accept-Language': locale ?? undefined, // set the language header. this is used to get the correct language for news and other content
          };
          return config;
        },
        (error) => {
          console.info('Should be an error');
          return Promise.reject(error.response.data);
        }
      );

      this.api.interceptors.response.use(
        (response) => response,
        async (error) => {
          console.error('Failed API response', error);

          if (!error.response) {
            throw Error(
              'Error did not have a response. The error object was:' + error
            );
          }
          throw Error(error.response);
        }
      );

      this.authApi.interceptors.request.use(
        async (config) => {
          const jwt = await getAccessToken();

          config.headers = {
            Authorization: 'Bearer ' + jwt,
            profileId: localStorage.getItem('profileId'),
          };
          return config;
        },
        (error) => {
          return Promise.reject(error.response.data);
        }
      );

      this.authApi.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          console.error('Auth API request failed', error);

          throw Error(error);
        }
      );
    }

    ping() {
      return this.api.get(`/ping`);
    }

    //================================================
    // Authentication
    //================================================
    register(payload) {
      return this.authApi.post('/signup', payload);
    }

    setProfileId(profileId) {
      console.log('Profile ID set in API header', profileId);

      this.profileId = profileId;
      this.init();
    }

    sendValidationMail(userId) {
      return this.authApi.post(`/user/${userId}/emailValidation`);
    }

    getValidationMailStatus(userId) {
      return this.authApi.get(`/user/${userId}/emailValidation`);
    }

    getValidationMail(userId) {
      return this.authApi.post(`/user/${userId}/emailValidation`);
    }

    //================================================
    // Password Reset
    //================================================
    resetPasswordWithOld(data) {
      return this.authApi.post(`/password/change-w-old`, data);
    }
    resetPasswordWithToken(data) {
      return this.authApi.post(`/password/reset`, data);
    }
    sendResetPasswordTokenEmail() {
      return this.authApi.post(`/password/reset-token`);
    }

    //================================================
    // Widget
    //================================================

    getTrendingTickers(query) {
      return this.api.get(
        `/symbols/trending?limit=${query.limit}&type=${query.type}`
      );
    }

    getTrendingIndices(payload) {
      const limit = payload?.limit ?? 5;
      return this.api.get(
        `/symbols/indices/trending?limit=${limit}&page=${payload.page}`
      );
    }

    getAllTrendingIndices() {
      return this.api.get(`/symbols/indices/allTrending`);
    }

    getTrendingCryptos(query) {
      return this.api.get(
        `/symbols/cryptos/trending?limit=${query.limit}&type=${query.type}`
      );
    }

    getPopularCryptos() {
      return this.api.get('/symbols/cryptos/popular');
    }

    getSimilarCryptos(query) {
      return this.api.get(
        `/recommendations/profiles/cryptos/${query.cryptoProfileId}/similar?limit=${query.limit}&page=${query.page}`
      );
    }

    //================================================
    // Feed
    //================================================
    getFeed(payload) {
      return this.api.get('/feed', { params: payload });
    }

    countNewFeed(postId) {
      return this.api.get(`/feed/${postId}/news`);
    }

    //================================================
    // Industry
    //================================================
    getIndustries() {
      return this.api.get('/industries');
    }

    followIndustryToggle(industryIds) {
      let industryIdsString;
      if (typeof industryIds === 'string') {
        industryIdsString = industryIds;
      } else if (Array.isArray(industryIds)) {
        industryIdsString = industryIds.join(',');
      }
      return this.api.post(
        `/industries/follow/toggle?industryIds=${industryIdsString}`
      );
    }

    //================================================
    // News
    //================================================
    getNews(payload) {
      return this.api.get('/news', { params: payload });
    }
    getCryptoNews(payload) {
      return this.api.get('/news/crypto', { params: payload });
    }
    getNewsHeadlines() {
      return this.api.get('/news/headlines');
    }
    //================================================
    // Profile
    //================================================
    getProfileById(id) {
      return this.api.get(`/profiles/${id}`);
    }

    getSymbolProfileById(id) {
      return this.api.get(`/symbols/${id}`);
    }

    createProfile(data) {
      return this.api.post('/profiles', data);
    }

    updateProfile(payload) {
      return this.api.patch(`/profiles/${payload.profileId}`, payload.data);
    }

    uploadProfileAvatar(payload) {
      return this.api.put(
        `/profiles/${payload.profileId}/upload-avatar`,
        payload.formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );
    }

    uploadProfileHeader(payload) {
      return this.api.put(
        `/profiles/${payload.profileId}/upload-header`,
        payload.formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );
    }

    validateProfileProfilename(profileName) {
      return this.api.get(`/profiles/profilename/validate/${profileName}`);
    }

    getProfileIdFromName(profileName) {
      return this.api.get(`/profiles/getId/${profileName}`);
    }

    publicValidateProfileProfilename(profileName) {
      return this.api.get(`/public/profilename/validate/${profileName}`);
    }

    publicValidateUserEmail(email) {
      return this.authApi.get(`${email}/validate`);
    }

    publicGetUserFromProfilename(profileName) {
      return this.api.get(`/public/profilename/user/${profileName}`);
    }

    followProfile(followedId) {
      return this.api.put(`/profiles/${followedId}/follow`).then((res) => {
        store.dispatch('mutualRecommendationWidget/handleFollow', {
          profileId: followedId,
          isFollowed: res.data.isFollowed,
        });
        store.dispatch('profile/setProfileFollow', {
          profileId: followedId,
          isFollowed: res.data.isFollowed,
        });

        store.dispatch(
          'authentication/updateProfileFollowingCount',
          res.data.isFollowed ? 1 : -1
        );
        return res;
      });
    }

    followStockChange(payload) {
      console.log('Payload: ', payload);
      return this.api.put('/profiles/followStockChange', payload);
    }

    followedIdustries(profileId) {
      return this.api.get(`/profiles/${profileId}/followedIndustries`);
    }
    getProfileFollowers(payload) {
      return this.api.get(
        `/profiles/${payload.profileId}/followers?limit=${payload.limit}&page=${payload.page}`
      );
    }
    getProfileFollowed(payload) {
      return this.api.get(
        `/profiles/${payload.profileId}/followed?profileType=${payload.profileType}&limit=${payload.limit}&page=${payload.page}`
      );
    }

    getNewsSuscribedLanguages(profileId) {
      return this.api.get(`/profiles/${profileId}/languages`);
    }

    getCompanyHeadlines(payload) {
      return this.api.get(
        `/profiles/${payload.profileId}/relatedHeadlines?limit=${payload.limit}&page=${payload.page}`
      );
    }

    getTopInvestors(payload) {
      const url =
        'profiles/top-investors?limit=' +
        payload.limit +
        '&page=' +
        payload.page;
      return this.api.get(url);
    }

    setWTPmodalSeen() {
      return this.api.put('/profiles/WTPModal/seen');
    }

    deleteProfile() {
      return this.api.delete('/profiles');
    }
    //================================================
    // Users
    //================================================
    getMe() {
      return this.api.get('/users/me');
    }

    getUserMe() {
      return this.authApi.get('/user/me');
    }

    updateUsersMe(data) {
      return this.api.put('/users/me', data);
    }

    patchUserBirthdate(payload) {
      return this.authApi.patch(`user/birthdate`, payload);
    }
    deleteUser() {
      return this.authApi.delete('/user');
    }

    //================================================
    // Search
    //================================================
    search(payload) {
      return apiSearch.search(this.api, payload);
    }
    getSearchHistory(payload) {
      return apiSearch.getSearchHistory(this.api, payload);
    }

    deleteSearchHistory() {
      return this.api.delete('/search/deleteHistory');
    }

    //================================================
    // Portfolio
    //================================================
    createPortfolio(payload) {
      return this.api.post('/portfolios', payload);
    }

    validatePortfolioName(portfolioName) {
      return this.api.get(
        `/portfolios/validatePortfolioName?` +
          new URLSearchParams({ portfolioName: portfolioName })
      );
    }

    getPortfolioTopGainers(query) {
      return this.api.get(
        '/portfolios/top-gainers/default?limit=' +
          query.limit +
          '&page=' +
          query.page
      );
    }
    getPortfolioTopGainersFollowed(query) {
      return this.api.get(
        '/portfolios/top-gainers/followed?limit=' +
          query.limit +
          '&page=' +
          query.page
      );
    }

    portfolioSearch(query) {
      return this.api.get(
        '/portfolios/search/default?limit=' +
          query.limit +
          '&page=' +
          query.page +
          '&searchQuery=' +
          query.searchString
      );
    }
    getPortfolioComparisonGraphInfo(payload) {
      return this.api.post(
        '/portfolios/comparisonGraphInfo/?date=' + payload.date,
        payload.portfolioIds
      );
    }

    orderPortfolios(payload) {
      return this.api.post('/portfolios/order', payload);
    }

    favoritePortfolio(portfolioId) {
      return this.api.put(`/portfolios/${portfolioId}/favorite`);
    }

    getMyFavoritePortfolios() {
      return this.api.get('/portfolios/favorited/me');
    }

    getFavoritePortfolio(portfolioId) {
      return this.api.get(`/portfolios/favorited/${portfolioId}`);
    }

    getPortfolioById(portfolioId) {
      return this.api.get(`/portfolios/${portfolioId}`);
    }

    getCompanySymbols(companyId) {
      return this.api.get(`/profiles/${companyId}/symbols`);
    }

    getMultipleCompanySymbols(payload) {
      return this.api.post(`profiles/getMultipleSymbols`, payload);
    }

    getCryptoSymbols(cryptoId) {
      return this.api.get(`/profiles/cryptos/${cryptoId}/symbols`);
    }

    getPrimaryTickerFundamentals(companyId) {
      return this.api.get(`/profiles/${companyId}/primary-ticker/fundamentals`);
    }

    getTickerFundamentals(ticker) {
      return this.api.get(`/profiles/${ticker}/fundamentals`);
    }

    removePortfolio(id) {
      return this.api.delete('/portfolios/' + id);
    }
    likePortfolio(id) {
      return this.api.put(`/portfolios/${id}/like`);
    }

    toggleCompleteView(portfolioId) {
      return this.api.put(`/portfolios/${portfolioId}/toggleCompleteView`);
    }

    updatePortfolio(payload) {
      return this.api.patch('/portfolios/' + payload.portfolioId, payload.data);
    }

    getPortfolioGraph(portfolioId) {
      return this.api.get(`/portfolios/${portfolioId}/graph`);
    }

    getMyPortfolios() {
      return this.api.get('/portfolios/me');
    }
    getProfilePortfolios(userId) {
      return this.api.get('/portfolios/get-user-portfolio/' + userId);
    }

    createOperation(payload) {
      return this.api.post('/portfolios/operations', payload);
    }

    createCashOperation(payload) {
      return this.api.post(
        '/portfolios/' + payload.portfolioId + '/cash',
        payload
      );
    }

    addPortfolioItem(payload) {
      return this.api.post(`/portfolios/${payload.portfolioId}/items`, payload);
    }

    removePortfolioItem(payload) {
      return this.api.delete(
        `/portfolios/${payload.portfolioId}/items/${payload.itemId}`
      );
    }
    removePortfolioCashItem(payload) {
      return this.api.delete(
        `/portfolios/${payload.portfolioId}/cash/${payload.currencyCode}`
      );
    }
    getCryptoTickerStockValue(payload) {
      return this.api.get(
        `/symbols/cryptos/${payload.symbol}/stockvalue?date=${payload.date}`
      );
    }
    getTickerStockValue(payload) {
      const symbol = payload.symbol ?? payload.name;
      if ('portfolioId' in payload && payload.portfolioId !== null) {
        let url = `/symbols/${symbol}/${payload.portfolioId}/stockvalue?date=${payload.date}`;
        url += payload.forceEodUpdate ? '&forceEodUpdate=true' : '';
        return this.api.get(url);
      }
      return this.api.get(`/symbols/${symbol}/stockvalue?date=${payload.date}`);
    }

    getSimilarTickers({ portfolioId }) {
      return this.api.get(`/portfolios/${portfolioId}/similartickers`);
    }

    getTickerStockRangeValue(payload) {
      const symbol = payload.symbol ?? payload.name;
      const toDateQuery = payload.toDate ? `&toDate=${payload.toDate}` : '';
      const currency = payload.currency ? `&currency=${payload.currency}` : '';
      const calculateOverallPerformance = payload.calculateOverallPerformance
        ? `&calculateOverallPerformance=${payload.calculateOverallPerformance}`
        : '';
      return this.api.get(
        `/symbols/${symbol}/historical?fromDate=${payload.fromDate}${toDateQuery}${currency}${calculateOverallPerformance}`
      );
    }

    getMultipleTickerStockRangeValue(payload) {
      const toDateQuery = payload.toDate ? `&toDate=${payload.toDate}` : '';
      const calculateOverallPerformance = payload.calculateOverallPerformance
        ? `&calculateOverallPerformance=${payload.calculateOverallPerformance}`
        : '';
      return this.api.post(
        `/symbols/historicalForMultipleSymbols?fromDate=${payload.fromDate}${toDateQuery}&currency=${payload.currency}${calculateOverallPerformance}`,
        payload.symbols
      );
    }

    getTeamPortfolioProfiles(payload) {
      return this.api.get(
        `/portfolios/${payload.portfolioId}/getAllTeamPortfolioUsers`
      );
    }

    updateTeamPortfolioProfile(payload) {
      return this.api.put(`/portfolios/editTeamPortfolioUser`, payload);
    }

    deleteTeamPortfolioProfile(payload) {
      return this.api.delete(
        `/portfolios/${payload.profileId}/${payload.portfolioId}/deleteTeamPortfolioMember`
      );
    }

    createTeamPortfolioProfile(payload) {
      return this.api.post(`portfolios/createTeamPortfolioUser`, payload);
    }

    getCryptoTickerStockRangeValue(payload) {
      const symbol = payload.symbol ?? payload.name;
      return this.api.get(
        `/symbols/cryptos/${symbol}/historical?fromDate=${payload.fromDate}&toDate=${payload.toDate}`
      );
    }

    getPortfolioComments(payload) {
      return this.api.get(`/portfolios/${payload.id}/comments`, {
        params: payload.params,
      });
    }

    //================================================
    // Post
    //================================================
    getPostById(postId) {
      return this.api.get(`/posts/${postId}`);
    }

    getPostReplies(payload) {
      return this.api.get(`/posts/${payload.postId}/replies`, {
        params: payload.params,
      });
    }

    getPosts(payload) {
      return this.api.get('/posts', {
        params: payload,
      });
    }

    createPost(payload) {
      return this.api.post('/posts', payload);
    }

    uploadPostImage(formData) {
      return this.api.post(`/posts/upload-image`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    }

    likePost(postId) {
      return this.api.put(`/posts/${postId}/like`);
    }

    favoritePost(postId) {
      return this.api.put(`/posts/${postId}/favorite`).then((res) => {
        res.data.isFavorited == true &&
          window.$nuxt.$notify.success('Successfully added to your favorites');
        res.data.isFavorited == false &&
          window.$nuxt.$notify.success(
            'Successfully removed from your favorites'
          );
        return res;
      });
    }

    getFavoritesPost(payload) {
      return this.api.get('/posts/favorites', { params: payload });
    }

    getProfilePosts(payload) {
      return this.api.get(`/profiles/${payload.profileId}/posts`, {
        params: payload.params,
      });
    }
    reportPost(payload) {
      return this.api
        .post(`/posts/${payload.postId}/report`, { body: payload.content })
        .then(() => {
          window.$nuxt.$notify.success('Report successfuly sent');
        })
        .catch(() => {
          window.$nuxt.$notify.error('You cant report this post again');
        });
    }

    rateProfile(payload) {
      return this.api.put(`/profiles/${payload.profileId}/rate`, payload.data);
    }

    //================================================
    // Messages
    //================================================
    getMessageNotificationsAll(payload) {
      return this.api.get(`/messages/notifications/${payload.userId}`);
    }

    markAsReadMessageNotificationsAll(payload) {
      return this.api.put(
        `/messages/${payload.conversationId}/markAsReadNotificationsAll/${payload.userId}`
      );
    }

    removeNotification(payload) {
      return this.api.put(`/messages/removeNotification/${payload.id}`);
    }

    //================================================
    // Notifications
    //================================================
    getNotifications(payload) {
      return this.api.get('/notifications', { params: payload });
    }

    handleReadAllNotification() {
      return this.api.put('/notifications/read');
    }

    handleReadNotification(notificationId) {
      return this.api.put(`/notifications/${notificationId}/read`);
    }

    handleReadMultipleNotifications(payload) {
      const { profileId, notificationIds } = payload;

      return this.api.put(
        `/notifications/readNotifications?profileId=${profileId}`,
        notificationIds
      );
    }

    notificationsCount(payload) {
      return this.api.get('/notifications/count', { params: payload });
    }

    //================================================
    // Onboarding
    //================================================
    tutorialPreviousStep() {
      return this.api.put('/profiles/onboarding/tutorial/previousStep');
    }
    tutorialNextStep() {
      return this.api.put('/profiles/onboarding/tutorial/nextStep');
    }
    completeTutorial() {
      return this.api.put('/profiles/onboarding/tutorial/complete');
    }
    tipsNextStep() {
      return this.api.put('/profiles/onboarding/tips/nextStep');
    }
    completeTips() {
      return this.api.put('/profiles/onboarding/tips/complete');
    }
    getUserOnboardingQuestions() {
      return this.api.get('/profiles/onboarding/questions/all');
    }
    getUserOnboardingAnswersByProfileId(profileId) {
      return this.api.get(`/profiles/${profileId}/onboarding/answers`);
    }
    saveUserOnboardingAnswers(payload) {
      return this.api.post('/profiles/onboarding/answers', payload);
    }

    //================================================
    // Recommendations
    //================================================
    getRecommendedProfiles(query) {
      return this.api.get(
        '/recommendations/profiles?limit=' +
          query.limit +
          '&page=' +
          query.page +
          '&withDetails=true' +
          (query.query ? `&query=${query.query}` : '')
      );
    }
    getRecommendedCompanies(query) {
      return this.api.get(
        '/recommendations/companies?limit=' +
          query.limit +
          '&page=' +
          query.page +
          '&withDetails=true' +
          (query.query ? `&query=${query.query}` : '')
      );
    }
    getMutualFollowsRecommendation(query) {
      return this.api.get(
        '/recommendations/profiles/mutualFollows?limit=' +
          query.limit +
          '&page=' +
          query.page
      );
    }
    getMutualIndustriesRecommendation(query) {
      return this.api.get(
        '/recommendations/profiles/mutualIndustries?limit=' +
          query.limit +
          '&page=' +
          query.page
      );
    }
    getCompanyFollowersRecommendation(query) {
      return this.api.get(
        `/recommendations/profiles/${query.companyProfileId}/followers?limit=` +
          query.limit +
          '&page=' +
          query.page
      );
    }
    getSimilarCompaniesRecommendation(query) {
      return this.api.get(
        `/recommendations/companies/${query.companyProfileId}/similar?limit=` +
          query.limit +
          '&page=' +
          query.page
      );
    }

    //================================================
    // Stocks
    //================================================
    searchStocks(payload) {
      return this.api.get(
        `/symbols/search?limit=` +
          payload.pagination.limit +
          '&page=' +
          payload.pagination.page +
          '&orderBy=' +
          payload.query.orderBy +
          '&orderByDirection=' +
          payload.query.orderByDirection +
          '&isFollowed=' +
          payload.query.isFollowed +
          '&isPrimaryTicker=' +
          payload.query.isPrimaryTicker +
          '&query=' +
          payload.query.query +
          '&symbolType=' +
          payload.query.symbolType
      );
    }

    //================================================
    // Link Embedding
    //================================================
    getLinkEmbedding({ url }) {
      return this.api.get(`/embed?url=${encodeURIComponent(url)}`);
    }

    //================================================
    // Leagues
    //================================================
    createAdminLeague(payload) {
      return this.api.post('/league', payload);
    }

    searchLeagues(payload) {
      console.info('IN API : ', payload);
      return this.api.get(
        `/league?limit=` +
          payload.pagination.limit +
          '&page=' +
          payload.pagination.page +
          '&orderBy=' +
          payload.orderBy +
          '&orderByDirection=' +
          payload.orderByDirection +
          '&filter=' +
          payload.filter +
          '&query=' +
          payload.query
      );
    }
  })();

  // Inject to context as $api
  inject('api', api);
}
