
import { mapActions, mapGetters } from 'vuex';
import UIRecommendationWidget from '@/components/ui/widgets/recommendations/UIRecommendationWidget';
import { inAppRedirect } from '~/utils/route-redirects';

export default {
  name: 'CompanyFollowersWidgetWrapper',
  components: { UIRecommendationWidget },
  props: {
    companyProfileId: {
      type: String,
      required: true,
    },
  },
  async fetch() {
    await this.getCompanyFollowers(this.companyProfileId);
  },
  computed: {
    ...mapGetters('companyFollowersWidget', [
      'companyFollowers',
      'companyFollowersLoading',
      'companyFollowersError',
    ]),
  },
  methods: {
    ...mapActions('companyFollowersWidget', [
      'getCompanyFollowers',
      'getNextCompanyFollowers',
      'followRecommendation',
    ]),

    handleLoadMore() {
      this.getNextCompanyFollowers(this.companyProfileId);
    },
    handleGoToProfile(profileId) {
      inAppRedirect('/profile/' + profileId);
    },
    handleFollow(profileId) {
      this.followRecommendation(profileId);
    },
  },
};
