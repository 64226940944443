
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'GiphyModal',
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      apiKey: process.env.NUXT_ENV_GIPHY_API_KEY,
      searchText: '',
      loading: false,
      result: [],
      col1: [],
      col2: [],
      col3: [],
    };
  },
  async fetch() {
    if (
      !this.giphyModal.lastGiphyTrendingSearch ||
      this.$dayjs().diff(
        this.giphyModal.lastGiphyTrendingSearch,
        'hour',
        true
      ) > 0.6
    ) {
      await this.searchGifs('trending');
      this.setGiphyLastTrendingSearch();
    }
  },
  computed: {
    ...mapGetters('modals', ['giphyModal']),
  },
  methods: {
    ...mapActions('modals', ['setGiphyLastTrendingSearch']),
    searchGifs(action = 'search') {
      this.result = [];
      this.col1 = [];
      this.col2 = [];
      this.col3 = [];
      this.loading = true;

      return axios
        .get(
          `https://api.giphy.com/v1/gifs/${action}?api_key=${this.apiKey}&q=${this.searchText}`
        )
        .then((response) => {
          for (const elem of response.data.data) {
            this.result.push(elem);
          }
          const medianLength = parseInt(this.result.length / 3);

          this.col1 = this.result.splice(0, medianLength);
          this.col2 = this.result.splice(0, medianLength);
          this.col3 = this.result;

          this.searchText = '';
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onClickImage(image, id) {
      this.$emit('onGifSelected', { src: image.srcElement.currentSrc, id });
      this.closeGiphyModal();
    },
    closeGiphyModal() {
      this.$emit('close');
    },
  },
};
