// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".create-post-modal{background:var(--color-secondary);border-radius:10px}.create-post-modal__header{border-bottom:1px solid var(--color-gray-light);padding:20px 30px}.create-post-modal__header .title{color:var(--color-text)}.create-post-modal__user{padding:20px 30px 0}.create-post-modal .ui-social-post{border-radius:10px}.create-post-modal .ui-social-post textarea{max-height:35vh;min-height:150px}.create-post-modal .rounded-lg{border-radius:.5rem!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"a": "#5a80fb",
	"b": "#6b88fc",
	"c": "#7b91fc",
	"d": "#8899fd",
	"e": "#95a2fd",
	"f": "#a1abfe",
	"g": "#adb4fe",
	"h": "#b8bdfe",
	"i": "#c3c6ff",
	"j": "#cdcfff",
	"k": "#d8d9ff",
	"l": "#e2e2ff",
	"m": "#ececff",
	"n": "#f5f5ff",
	"o": "#fff"
};
module.exports = ___CSS_LOADER_EXPORT___;
