export default {
  INIT: (state) => {
    state.mutualFollows = [];
    state.topInvestors = [];
    state.MutualFollowsPage = 1;
    state.MutualFollowsError = null;
    state.MutualFollowsLoading = false;
    state.mutualIndustries = [];
    state.MutualIndustriesPage = 1;
    state.MutualIndustriesError = null;
    state.MutualIndustriesLoading = false;
  },

  // Mutual Follows
  SET_MUTUAL_FOLLOWS: (state, payload) => {
    state.mutualFollows = payload;
  },
  SET_TOP_INVESTORS: (state, payload) => {
    state.mutualFollows = payload;
  },
  SET_MUTUAL_FOLLOWS_LOADING: (state, isLoading) => {
    state.mutualFollowsLoading = isLoading;
  },
  SET_MUTUAL_FOLLOWS_ERROR: (state, er) => {
    state.mutualFollowsError = er;
  },
  INCREASE_MUTUAL_FOLLOWS_PAGE: (state) => {
    state.mutualFollowsPage++;
  },
  GET_TOP_INVESTORS_INIT: (state) => {
    state.topInvestors.loading = true;
    state.topInvestors.data = [];
    state.topInvestors.error = null;
  },
  GET_TOP_INVESTORS_SUCCESS: (state, payload) => {
    state.topInvestors.data = payload;
    state.topInvestors.loading = false;
  },
  GET_TOP_INVESTORS_ERROR: (state, er) => {
    state.topInvestors.error = er;
    state.topInvestors.loading = false;
  },
  SET_TOP_INVESTOR_FOLLOW: (state, payload) => {
    state.topInvestors.data[payload.index].isFollowed = payload.isFollowed;
  },
  RESET_MUTUAL_FOLLOWS_PAGE: (state) => {
    state.mutualFollowsPage = 0;
  },
  APPEND_NEW_MUTUAL_FOLLOW: (state, mutualFollow) => {
    state.mutualFollows = [...state.mutualFollows, mutualFollow];
  },
  REMOVE_MUTUAL_FOLLOW: (state, index) => {
    state.mutualFollows.splice(index, 1);
  },

  // Mutual Industries
  SET_MUTUAL_INDUSTRIES: (state, payload) => {
    state.mutualIndustries = payload;
  },
  SET_MUTUAL_INDUSTRIES_LOADING: (state, isLoading) => {
    state.mutualIndustriesLoading = isLoading;
  },
  SET_MUTUAL_INDUSTRIES_ERROR: (state, er) => {
    state.mutualIndustriesError = er;
  },
  INCREASE_MUTUAL_INDUSTRIES_PAGE: (state) => {
    state.mutualIndustriesPage++;
  },
  RESET_MUTUAL_INDUSTRIES_PAGE: (state) => {
    state.mutualIndustriesPage = 0;
  },
  APPEND_NEW_MUTUAL_INDUSTRY: (state, mutualIndustry) => {
    state.mutualIndustries = [...state.mutualIndustries, mutualIndustry];
  },
  REMOVE_MUTUAL_INDUSTRY: (state, index) => {
    state.mutualIndustries.splice(index, 1);
  },
};
