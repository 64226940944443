/**
 * Represents the Nuxt Route object
 */
export interface NuxtRoute {
  name: string | undefined;
}

/**
 * Removes the localization suffix from a route. Useful for checking which route the user is on in a localized nuxt app.
 * @param {NuxtRoute} route The route the user is currently on
 * @returns {string | undefined} The unlocalized route name
 */
export function unlocalizeRouteName(route: NuxtRoute): string | undefined {
  // nuxt route names will have the name of the route in the following format
  // <route_name>__<locale>
  const localeSplitRoute = route.name?.split('__') ?? [];

  // in normal operation of the app, there will be two parts once the route name is split.
  // if there are more than two parts, one of the developers has given a poor name to the route.
  // this should be reported.
  if (localeSplitRoute.length > 2) {
    console.error(
      'This app does not support route names with double underscores. Rename your route',
      { route }
    );
  }

  // the name of the route without the locale at the end
  const unlocalizedRouteName = localeSplitRoute[0];

  return unlocalizedRouteName;
}

/**
 * Returns whether the route the user is currently on belongs to the locale specified.
 * @param {NuxtRoute} route The route the user is currently on
 * @param {string} locale The locale in question
 */
export function routeBelongsToLocale(
  route: NuxtRoute,
  locale: string
): boolean {
  return route.name?.includes('__' + locale) ?? false;
}
