
export default {
  name: 'Button',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    plain: {
      type: Boolean,
      default: false,
    },
    cancelBtn: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'primary',
    },
    border: {
      type: Boolean,
      default: true,
    },
    buttonType: {
      type: String,
      default: 'button',
    },
    slotclass: {
      type: String,
      default: '',
    },
    onboarding: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    btnClass() {
      let res = 'ui-button rounded-xl px-4 py-2.5 focus:outline-none';

      if (this.disabled) {
        res += ' opacity-50 cursor-not-allowed disabled';
      }

      if (this.loading) {
        res += ' cursor-wait';
      }

      if (this.plain) {
        res += ' icon-white bg-' + this.type;
      }

      if (this.border) {
        if (this.cancelBtn) {
          res += ' border border-neutral-6 text-neutral-13';
        } else res += ' border border-' + this.type;
      }

      if (this.cancelBtn) {
        res += ' border-neutral-6 text-neutral-13';
      }

      return res;
    },
    slotClass() {
      let res = this.slotclass;
      res += this.onboarding ? 'text-fs-2' : 'text-fs-13-px';
      if (this.loading) {
        res += ' opacity-0 block';
      }

      if (this.plain) {
        res += ' text-neutral-1';
      } else {
        res += ' text-' + this.type;
      }

      return res;
    },
  },
};
