export default function({ $toast }, inject) {
  // Create a custom axios instance
  const notify = {
    success(
      msg = 'Success !',
      options = {
        className: ['toasted-success']
      }
    ) {
      $toast.success(msg, { ...options, icon: 'task_alt' });
    },
    error(msg = 'Error !', options = { className: ['toasted-error'] }) {
      $toast.error(msg, { ...options, icon: 'info_outlined' });
    },
    info(msg = 'Info !', options = { className: ['toasted-info'] }) {
      $toast.info(msg, { ...options, icon: 'info_outlined' });
    },
    warning(msg = 'Warning !', options = { className: ['toasted-warning'] }) {
      $toast.info(msg, { ...options, icon: 'info_outlined' });
    }
  };

  // Inject to context as $api
  inject('notify', notify);
}
