/**
 * Represents the known currency identifiers.
 */
export enum CurrencyIdentifier {
  CHF = 'CHF',
  CNY = 'CNY',
  EUR = 'EUR',
  GBP = 'GBP',
  INR = 'INR',
  JPY = 'JPY',
  KRW = 'KRW',
  KWD = 'KWD',
  MXN = 'MXN',
  RUB = 'RUB',
  USD = 'USD',
}
