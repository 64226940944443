
import UserImgInfo from '@/components/ui/molecule/UserImgInfo';
import CheckIcon from '~/components/ui/icons/CheckIcon.vue';
import AddIcon from '~/components/ui/icons/AddIcon.vue';
import { inAppRedirect } from '~/utils/route-redirects';

export default {
  name: 'UIMentionCard',
  components: {
    UserImgInfo,
    CheckIcon,
    AddIcon,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    hide: {
      type: Boolean,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    profileId: {
      type: String,
      required: true,
    },
    displayName: {
      type: String,
      required: true,
    },
    profileName: {
      type: String,
      required: true,
    },
    avatarUri: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    followerCount: {
      type: Number,
      default: 0,
    },
    followingCount: {
      type: Number,
      default: 0,
    },
    isFollowed: {
      type: Boolean,
      required: true,
    },
    profileType: {
      type: String,
      default: 'PersonalProfile',
    },
  },
  mounted() {
    this.$emit('mounted', this.$el);
  },

  beforeDestroy() {
    this.$emit('beforeDestroy', this.$el);
  },

  methods: {
    goToProfile() {
      inAppRedirect(`/profile/${this.profileId}`);
    },
  },
};
