
import { mapGetters } from 'vuex';
import MutualRecommendationWidget from '@/components/widgets/MutualRecommendationWidgetWrapper';
import CompanyFollowersWidget from '@/components/widgets/CompanyFollowersWidgetWrapper';
import DataTickerWidgetWrapper from '~/components/widgets/DataTickerWidgetWrapper.vue';
import TrendingLosersTickersWidgetWrapper from '~/components/widgets/TrendingLosersTickersWidgetWrapper.vue';
import TrendingGainersTickersWidgetWrapper from '~/components/widgets/TrendingGainersTickersWidgetWrapper.vue';
import TrendingCryptosWidgetWrapper from '~/components/widgets/TrendingCryptosWidgetWrapper';
import SimilarCompaniesWidgetWrapper from '~/components/widgets/SimilarCompaniesWidgetWrapper.vue';
import TrendingIndicesWidgetWrapper from '~/components/widgets/TrendingIndicesWidgetWrapper.vue';
// import PopularCryptosWidgetWrapper from '~/components/widgets/PopularCryptosWidgetWrapper';
// import PortfolioWidgets from '@/components/ui/widgets/portfolio';
//  import SymbolCardsWidget from '@/components/ui/widgets/symbol/SymbolCards';
// import UIPortfolioSlider from '@/components/ui/organism/UIPortfolioSlider';
import { appURLs, inAppRedirect } from '@/utils/route-redirects';

export default {
  name: 'Widgets',
  components: {
    //UIPortfolioSlider,
    MutualRecommendationWidget,
    CompanyFollowersWidget,
    DataTickerWidgetWrapper,
    TrendingLosersTickersWidgetWrapper,
    TrendingGainersTickersWidgetWrapper,
    TrendingCryptosWidgetWrapper,
    // PopularCryptosWidgetWrapper,
    SimilarCompaniesWidgetWrapper,
    // PortfolioWidgets,
    // SymbolCardsWidget,
    TrendingIndicesWidgetWrapper,
  },
  // data() {
  //   return {
  //     widgetPortfolios: [],
  //   };
  // },
  async fetch() {
    //if (process.client) {
    //  // TODO : uncomment in WAL-742
    //  await this.getPortfoliosWidget(this.profileId);
    //}
  },
  computed: {
    ...mapGetters('authentication', {
      authProfile: 'profile',
      profileId: 'profileId',
    }),
    //...mapGetters('portfolio', ['getPortfoliosWidgetRes']),
    ...mapGetters('profile', ['profile']),
    routeName() {
      return this.$route.name?.split('_')[0];
    },
    routeParams() {
      return this.$route.params;
    },
    //showPortfolioSliderWidget() {
    //  return (
    //    [
    //      appURLs.feed(),
    //      'search-hashtags',
    //     'saved',
    //      'market',
    //      'profile-profileId',
    //      'post-postId',
    //     'portfolios-portfolio-comments',
    //    ].indexOf(this.routeName) != -1
    //  );
    //},
    showGainerLoserTickersWidget() {
      return (
        [appURLs.feed(), 'search-hashtags', 'market'].indexOf(this.routeName) !=
          -1 ||
        this.isCompanyProfile ||
        this.isSearchResults
      );
    },
    showDataTickerWidget() {
      return ['profile-profile-symbol'].indexOf(this.routeName) != -1;
    },
    showIsMarketOpenWidget() {
      return (
        [
          'portfolios-portfolioId',
          'profile-profile-symbol',
          'portfolios-portfolio-symbol',
        ].indexOf(this.routeName) != -1
      );
    },
    showIndiceWidget() {
      return (
        ([appURLs.feed(), 'market'].indexOf(this.routeName) != -1 &&
          this.authProfile &&
          this.routeName !== '') ||
        this.isSearchResults
      );
    },
    showPopularCryptoWidget() {
      return (
        (this.authProfile &&
          this.authProfile.isSuscribedToCrypto &&
          [appURLs.feed(), 'search-hashtags', 'crypto'].indexOf(
            this.routeName
          ) != -1) ||
        this.isSearchResults
      );
    },
    showRecommendationWidget() {
      return (
        [
          appURLs.feed(),
          'search-hashtags',
          'saved',
          'market',
          'post-postId',
          'portfolios-portfolio-comments',
        ].indexOf(this.routeName) != -1 ||
        this.isUserProfile ||
        this.isSearchResults
      );
    },
    showCryptosWidget() {
      return (
        this.authProfile &&
        this.authProfile.isSuscribedToCrypto &&
        ([appURLs.feed(), 'search-hashtags', 'crypto'].indexOf(
          this.routeName
        ) != -1 ||
          this.isCryptoProfile ||
          this.isSearchResults)
      );
    },
    showSimilarCompaniesWidget() {
      return this.isCompanyProfile;
    },
    showCompanyFollowersWidget() {
      return this.isCompanyProfile;
    },
    isCryptoProfile() {
      return (
        ['profile-profileId'].indexOf(this.routeName) != -1 &&
        this.profile &&
        this.profile.profileType == 'CryptocurrencyProfile'
      );
    },
    isCompanyProfile() {
      return (
        ['profile-profileId'].indexOf(this.routeName) != -1 &&
        this.profile &&
        this.profile.profileType == 'CompanyProfile'
      );
    },
    isUserProfile() {
      return (
        ['profile-profileId'].indexOf(this.routeName) != -1 &&
        this.profile &&
        this.profile.profileType == 'PersonalProfile'
      );
    },
    isHomeFeed() {
      return this.routeName === appURLs.feed();
    },
    isSearchResults() {
      return this.routeName === appURLs.searchResults();
    },
    isTickerProfile() {
      return this.routeName === 'profile-profile-symbol';
    },
  },
  //watch: {
  //  'getPortfoliosWidgetRes.data'() {
  //    this.mapWidgetPortfolios();
  //  },
  //},
  methods: {
    //...mapActions('portfolio', ['getPortfoliosWidget']),
    //mapWidgetPortfolios() {
    //  if (this.getPortfoliosWidgetRes.data) {
    //    this.widgetPortfolios = this.getPortfoliosWidgetRes.data.map((e) => ({
    //      id: e.id,
    //      name: e.name,
    //      currency: e.currency,
    //      dailyChange: e.dailyChangePercent,
    //      overallChangePercent: e.overallChangePercent,
    //      overallChange: e.overallChange,
    //      last30Change: e.last30ChangePercent,
    //      last30: e.historicalDatas ? e.historicalDatas : [],
    //    }));
    //  }
    //},
    handleLoadMoreLosers() {
      inAppRedirect({
        path: this.localePath('/stocks'),
        query: { orderBy: 'changePercent', orderByDirection: 'ascending' },
      });
    },
    handleLoadMoreGainers() {
      inAppRedirect({
        path: this.localePath('/stocks'),
        query: { orderBy: 'changePercent', orderByDirection: 'descending' },
      });
    },
  },
};
