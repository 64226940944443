
import { navigateToUrl } from '~/utils/behavior/embed';

export default {
  props: {
    /**
     * Embed object
     * @type {Object}
     * @property {string} domain - Domain of the embed
     * @property {string} title - Title of the embed
     * @property {string} description - Description of the embed
     * @property {Object} openGraphTags - Open graph tags of the embed
     * @property {string} openGraphTags.image - Image of the embed
     * @property {string} openGraphTags.title - Title of the embed
     * @property {string} openGraphTags.description - Description of the embed
     * @property {string} openGraphTags.siteName - Site name of the embed
     * @property {boolean} shouldShow - Should show the embed
     * @default null
     * @required
     */
    embed: {
      type: Object,
      default: () => null,
    },
    navigateOnClick: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether the embed can be hidden
     * @type {Boolean}
     * @default true
     */
    canHide: {
      type: Boolean,
      default: true,
    },
    /**
     * Additional classes for the embed
     * @type {String}
     * @default ''
     */
    additionalClasses: {
      type: String,
      default: '',
    },
  },
  emits: ['embed-hidden'],
  methods: {
    /**
     * Emit embed hidden event
     * @emits embed-hidden
     * @returns {void}
     */
    embedHidden() {
      this.$emit('embed-hidden');
    },
    /**
     * Attempt to navigate to the embed
     * @returns {void}
     */
    attemptNavigate() {
      this.navigateOnClick && navigateToUrl(this.embed.url);
    },
  },
};
