const recommendationLimit = 5;

export default {
  init(context) {
    context.commit('INIT');
  },
  getPopularCryptos(context) {
    context.commit('SET_POPULAR_CRYPTOS_LOADING', true);
    this.dispatch('api/getPopularCryptos')
      .then(res => {
        context.commit('SET_POPULAR_CRYPTOS', res.data);
      })
      .catch(err => {
        context.commit('SET_POPULAR_CRYPTOS_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_POPULAR_CRYPTOS_LOADING', false);
      });
  }
};
