import { MutationsCreator } from '@/store/utils';
import profile from '.';

export default {
  // Profile
  INIT_PROFILE: state => {
    state.symbolProfile = [];
    state.symbolProfileLoading = false;
    state.symbolProfileError = null;
  },
  SET_PROFILE: (state, payload) => {
    state.symbolProfile = payload;
  },
  SET_PROFILE_LOADING: (state, isLoading) => {
    state.symbolProfileLoading = isLoading;
  },
  SET_PROFILE_ERROR: (state, error) => {
    state.symbolProfileError = error;
    window.$nuxt.$notify.error('Error loading symbol profile');
  },
  TOGGLE_PROFILE_FOLLOW: (state, payload) => {
    state.symbolProfile.companyProfileDetailDto.isFollowed = payload.isFollowed;
  },
};
