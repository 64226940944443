import { ActionsCreator } from '@/store/utils';

export default {
  init(context) {
    context.commit('INIT');
  },
  resetPasswordWithOld(context, payload) {
    context.commit('INIT');
    return this.dispatch('api/resetPasswordWithOld', payload)
      .then(res => {
        context.commit('SET_SUCCESS', true);
      })
      .catch(error => {
        context.commit('SET_ERROR', error[0].code); // TODO cleanup
        throw error;
      });
  },
  sendPasswordResetTokenEmail(context, email) {
    context.commit('SET_RESET_PASSWORD_TOKEN_EMAIL_SUCCESS', false);
    return this.dispatch('api/sendResetPasswordTokenEmail')
      .then(res => {
        context.commit('SET_RESET_PASSWORD_TOKEN_EMAIL_SUCCESS', true);
      })
      .catch(err => { });
  },
  setPasswordResetCode(context, code) {
    context.commit('SET_RESET_PASSWORD_CODE', code);
  },
  resetPasswordWithToken(context, payload) {
    return this.dispatch('api/resetPasswordWithToken', {
      password: payload.password,
      confirmPassword: payload.confirmPassword,
      token: context.state.resetPasswordToken,
      email: payload.email
    })
      .then(res => { })
      .catch(error => { });
  },
  resetError(context){
    context.commit('SET_ERROR', null);
  }
};
