export const loginError = (state) => state.loginError;
export const registerError = (state) => state.registerError;
export const me = (state) => state.me;
export const profile = (state) => state.profile;
export const displayName = (state) => state.profile?.displayName;
export const profileId = (state) => state.profileId;
export const showRegister = (state) => state.showRegister;
export const showLogin = (state) => state.showLogin;
export const loginUsername = (state) => state.loginUsername;
export const loginProfileId = (state) => state.loginProfileId;
export const isEmailConfirmed = (state) => state.isEmailConfirmed;
export const oidcUsername = (state) => state.decodedToken?.username;
export const isUserAdmin = (state) => state.decodedToken?.role == 'Admin';
export const apiUsername = (state) => state.me?.username;
export const isLocalAccount = (state) => state.decodedToken?.idp == 'local';
export const shouldRefreshAccessToken = (state) =>
  state.shouldRefreshAccessToken;

// Profile Languages
export const newsSuscribedLanguages = (state) => state.newsSuscribedLanguages;
export const newsSuscribedLanguagesLoading = (state) =>
  state.newsSuscribedLanguagesLoading;
export const newsSuscribedLanguagesError = (state) =>
  state.newsSuscribedLanguagesError;

// Profile avatar
export const uploadProfileAvatarLoading = (state) =>
  state.uploadProfileAvatarLoading;
export const uploadProfileAvatarError = (state) =>
  state.uploadProfileAvatarError;
export const uploadProfileAvatarSuccess = (state) =>
  state.uploadProfileAvatarSuccess;

// Profile header
export const uploadProfileHeaderLoading = (state) =>
  state.uploadProfileHeaderLoading;
export const uploadProfileHeaderError = (state) =>
  state.uploadProfileHeaderError;
export const uploadProfileHeaderSuccess = (state) =>
  state.uploadProfileHeaderSuccess;

// Delete User
export const deleteUserRes = (state) => state.deleteUser;
