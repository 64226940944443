
export default {
  props: {
    color: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: '20px',
    },
  },
  emits: ['back'],
};
