
import Frame7 from '@/assets/icons/Frame 7.svg';
import Frame8 from '@/assets/icons/Frame 8.svg';
import Calendar from '@/assets/icons/calendar.svg';
import CheckCircle from '@/assets/icons/check-circle.svg';
import Checklist from '@/assets/icons/checklist.svg';
import DoubleCases from '@/assets/icons/double-cases.svg';
import Following from '@/assets/icons/following.svg';
import Logo from '@/assets/icons/logo.svg';
import Menu from '@/assets/icons/menu.svg';
import MenuSecondary from '@/assets/icons/menu-secondary.svg';
import Message from '@/assets/icons/message.svg';
import PostAttachment from '@/assets/icons/post-attachment.svg';
import PostEmoji from '@/assets/icons/post-emoji-1.svg';
import PostGif from '@/assets/icons/post-gif-1.svg';
import PostImage from '@/assets/icons/post-image-1.svg';
import Union from '@/assets/icons/union.svg';
import Verified from '@/assets/icons/verified.svg';
import Medal1 from '@/assets/icons/medal-1.svg';
import Medal2 from '@/assets/icons/medal-2.svg';
import Medal3 from '@/assets/icons/medal-3.svg';
import Award from '@/assets/icons/award.svg';
import MedalSports from '@/assets/icons/medal-sports.svg';
import InfoCircle from '@/assets/icons/info-circle.svg';
import TeamPortfolioShare from '@/assets/icons/team-portfolio-share.svg';
import Globe from '@/assets/icons/globe.svg';
import Settings from '@/assets/icons/settings.svg';
import Key from '@/assets/icons/key.svg';
import Portfolio from '@/assets/icons/portfolio.svg';
import CheckSquare from '@/assets/icons/check-square.svg';
import ShieldIcon from '../icons/ShieldIcon.vue';
import CookieIcon from '../icons/CookieIcon.vue';
import SupportIcon from '../icons/SupportIcon.vue';
import ErrorIcon from '../icons/ErrorIcon.vue';
import Cross from '@/assets/icons/cross.svg';

export default {
  name: 'Icon',
  components: {
    Cross,
    Logo,
    CheckCircle,
    Message,
    Frame7,
    Frame8,
    Menu,
    MenuSecondary,
    PostAttachment,
    PostImage,
    PostEmoji,
    PostGif,
    Calendar,
    Verified,
    Following,
    Checklist,
    Union,
    DoubleCases,
    Medal1,
    Medal2,
    Medal3,
    Award,
    MedalSports,
    InfoCircle,
    TeamPortfolioShare,
    Globe,
    Settings,
    Key,
    Portfolio,
    CheckSquare,
    ShieldIcon,
    CookieIcon,
    SupportIcon,
    ErrorIcon,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: null,
    },
    //Width and Height don't look like to be used, should we delete it ?
    width: {
      type: String,
      default: null,
    },
    height: {
      type: String,
      default: null,
    },
    stroke: {
      type: String,
      default: null,
    },
  },
};
