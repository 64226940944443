
export default {
  name: 'HeartIcon',
  props: {
    stroke: {
      type: String,
      default: null,
    },
    fill: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: '16',
    },
  },
  emits: ['back'],
  computed: {
    fillAndStrokeClass() {
      let res = '';
      // In order to trigger css animations we need to set the default stroke and fill here
      // TODO: investigate why animations are not working from/to fill:none or fill:transparent, forcing us to use a white fill instead
      res += 'stroke-' + (this.stroke ? this.stroke : 'neutral-7');
      if (this.fill) res += ' fill-' + (this.fill ? this.fill : 'neutral-1');

      return res;
    },
  },
};
