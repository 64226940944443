
import CloseIcon from '~/components/ui/icons/CloseIcon.vue';
export default {
  name: 'ReportPostModal',
  components: {
    CloseIcon,
  },
  data() {
    return {
      text: '',
    };
  },
};
