export const hasCompletedTutorial = (state) => state.hasCompletedTutorial;
export const hasSeenTips = (state) => state.hasSeenTips;
export const tutorialStep = (state) => state.tutorialStep;
export const tipsStep = (state) => state.tipsStep;
export const tipsTotalStep = (state) => state.tipsTotalStep;
export const recommendedCompanies = (state) => state.recommendedCompanies;
export const recommendedCompaniesLoading = (state) =>
  state.recommendedCompaniesLoading;
export const recommendedCompaniesSearchQuery = (state) =>
  state.recommendedCompaniesSearchQuery;
export const nextCompaniesLoading = (state) => state.nextCompaniesLoading;
export const recommendedProfiles = (state) => state.recommendedProfiles;
export const recommendedProfilesLoading = (state) =>
  state.recommendedProfilesLoading;
export const nextProfilesLoading = (state) => state.nextProfilesLoading;
export const tutorialLoading = (state) => state.tutorialLoading;
export const showMoreCompanies = (state) =>
  state.showMoreCompanies &&
  !state.recommendedCompaniesLoading &&
  !state.nextCompaniesLoading;
export const showMoreProfiles = (state) =>
  state.showMoreProfiles &&
  !state.nextProfilesLoading &&
  !state.recommendedProfilesLoading;
export const recommendedProfilesSearchQuery = (state) =>
  state.recommendedProfilesSearchQuery;
export const birthdateSuccess = (state) => state.birthdateSuccess;
export const userOnboardingQuestions = (state) => state.userOnboardingQuestions;
export const userOnboardingAnswers = (state) => state.userOnboardingAnswers;
