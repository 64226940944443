
import ChevronIcon from '~/components/ui/icons/ChevronIcon.vue';
export default {
  name: 'DropdownPortfolioRoles',
  components: { ChevronIcon },
  props: {
    roles: {
      type: Array,
      default() {
        return [];
      },
    },
    selectedRole: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      showMoreMenu: false,
    };
  },
  methods: {
    hideMoreMenu() {
      this.showMoreMenu = false;
    },
    handleRoleChange(role) {
      this.$emit('onRoleChange', role);
      this.showMoreMenu = false;
    },
    getRoleName(role) {
      switch (role) {
        case 'EDIT':
          return 'team_portfolio_user_role_editor';
        case 'VIEW':
          return 'team_portfolio_user_role_viewer';
      }
    },
  },
};
