// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sidebar{width:100%}@media (min-width:768px){.sidebar{height:auto;left:0;width:80px}}@media (min-width:1024px){.sidebar{left:auto;width:160px}}@media (min-width:1280px){.sidebar{width:195px}}.sidebar-item{align-items:center;border-bottom:1px solid var(--color-neutral-4);color:var(--color-neutral-13);cursor:pointer;display:flex;font-size:var(--fs-2);height:40px;justify-content:space-between;padding:28px 24px}@media (min-width:768px){.sidebar-item{border-bottom:none}}@media (min-width:1024px){.sidebar-item{justify-content:flex-start;margin-top:8px;padding:0 24px}}.sidebar-item.more{display:none}@media (min-width:768px){.sidebar-item.more{display:flex}.sidebar-item.mobile{display:none}}.sidebar-item svg>*{stroke:var(--color-neutral-7)}.sidebar-item .fill-svg svg>*{stroke:none;fill:var(--color-neutral-7)}.sidebar-item:hover{background-color:var(--color-secondary);color:var(--color-primary)}.sidebar-item:hover svg>*{stroke:var(--color-primary)}.sidebar-item:hover .fill-svg svg>*{stroke:none;fill:var(--color-primary)}.sidebar-item.active{background-color:var(--color-neutral-2)}@media (min-width:1024px){.sidebar-item.active{background-color:transparent;box-shadow:inset 4px 0 0 #2f54eb;color:var(--color-primary)}}.sidebar-item.active svg>*{stroke:var(--color-primary)}.sidebar-item.active .fill-svg svg>*{stroke:none}@media (min-width:1024px){.sidebar-item.active .fill-svg svg>*{fill:var(--color-primary)}}.sidebar-item.active .label{color:var(--color-primary)}.sidebar .btn-show-create-post-modal{display:none;margin-top:20px;max-width:190px;width:100%}@media (min-width:1024px){.sidebar .btn-show-create-post-modal{display:block}}@media screen and (max-width:1023px) and (min-width:639px){.sidebar{padding-left:0}.sidebar-item{border-left:5px solid transparent}.sidebar-item .label{display:none}.sidebar-item.active{border-left:5px solid var(--color-primary);border-radius:0}}.sidebar .more-content:hover{background-color:#f0f5ff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"a": "#5a80fb",
	"b": "#6b88fc",
	"c": "#7b91fc",
	"d": "#8899fd",
	"e": "#95a2fd",
	"f": "#a1abfe",
	"g": "#adb4fe",
	"h": "#b8bdfe",
	"i": "#c3c6ff",
	"j": "#cdcfff",
	"k": "#d8d9ff",
	"l": "#e2e2ff",
	"m": "#ececff",
	"n": "#f5f5ff",
	"o": "#fff"
};
module.exports = ___CSS_LOADER_EXPORT___;
