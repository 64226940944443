// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".navbar-mobile{align-items:center;bottom:-1px;display:flex;justify-content:space-between;padding:0 10px 12px;position:fixed;width:100%}.navbar-mobile-item{align-items:center;cursor:pointer;display:flex;flex-direction:column;font-size:10px;justify-content:center;padding:.75rem;position:relative}.navbar-mobile-item span{padding-top:6px}.navbar-mobile-item.active{color:var(--color-primary)}.navbar-mobile-item.active svg>*{stroke:var(--color-primary)}.navbar-mobile-item.active:before{background-color:var(--color-primary);content:\"\";height:3px;left:50%;position:absolute;top:0;transform:translateX(-50%);width:30px}@media screen and (min-width:768px){.navbar-mobile{display:none}}.message-notification-number-mobile{background:#ff173e;transform:translate(-29%,33%)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"a": "#5a80fb",
	"b": "#6b88fc",
	"c": "#7b91fc",
	"d": "#8899fd",
	"e": "#95a2fd",
	"f": "#a1abfe",
	"g": "#adb4fe",
	"h": "#b8bdfe",
	"i": "#c3c6ff",
	"j": "#cdcfff",
	"k": "#d8d9ff",
	"l": "#e2e2ff",
	"m": "#ececff",
	"n": "#f5f5ff",
	"o": "#fff"
};
module.exports = ___CSS_LOADER_EXPORT___;
