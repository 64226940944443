
export default {
  name: 'InvisibleFilePicker',
  mounted() {
    this.$refs.InvisibleFileInput.click();
  },
  methods: {
    initialize() {
      document.body.onfocus = this.backToWebapp;
    },
    backToWebapp() {
      setTimeout(() => {
        if (
          this.$refs.InvisibleFileInput &&
          this.$refs.InvisibleFileInput.files.length === 0
        ) {
          this.$emit('onClose');
          document.body.onfocus = null;
        }
      }, 300);
    },
    handleFiles(event) {
      this.$emit('onFiles', event.target.files);
      this.$emit('onClose');
      document.body.onfocus = null;
    }
  },
  props: {
    accept: {
      type: String,
      default: 'image/png, image/jpeg, image/gif'
    }
  }
};
