/**
 * Background colors used for default avatars
 */
export const backgroundColors: string[] = [
  '#F44336',
  '#FF4081',
  '#9C27B0',
  '#673AB7',
  '#3F51B5',
  '#2196F3',
  '#03A9F4',
  '#00BCD4',
  '#009688',
  '#4CAF50',
  '#8BC34A',
  '#CDDC39',
  '#FFC107',
  '#FF9800',
  '#FF5722',
  '#795548',
  '#9E9E9E',
  '#607D8B',
];

/**
 * Function used for extracting username initials
 * @param username username we want to extract initials from
 * @returns extracted initials
 */
export const getInitials = (username: string): string => {
  const parts = username.split(/[ -]/);
  let initials = '';

  for (let i = 0; i < parts.length; i++) {
    initials += parts[i].charAt(0);
  }

  if (initials.length > 3 && initials.search(/[A-Z]/) !== -1) {
    initials = initials.replace(/[a-z]+/g, '');
  }

  initials = initials.substr(0, 3).toUpperCase();

  return initials;
};

/**
 * Generates random background color
 */
export const randomBackgroundColor = (seed: number): string => {
  return backgroundColors[seed % backgroundColors.length];
};

/**
 * @returns style for profile avatar
 */
export const generateAvatarStyle = (
  item: { avatarUri: string; value: string },
  uri: string,
  isImage: boolean
): string => {
  const backgroundColor = randomBackgroundColor(item.value.length);
  const fontColor = '#FFFFFF';
  const size = 40;
  const style = `
      display: flex;
      width: ${size}px;
      height: ${size}px;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 16px; 
      user-select: none;
      background-color: ${isImage ? 'transparent' : backgroundColor};
      color: ${fontColor};
      background: ${isImage ? `url(${uri}) no-repeat center/cover` : ''};
    `;
  return style;
};
