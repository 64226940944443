
import { appURLs, inAppRedirect } from '~/utils/route-redirects';

const contextualizeLog = (message) => `Error layout. ${message}`;

export default {
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  beforeMount() {
    console.error(
      contextualizeLog('Navigated to Nuxt error page due to error'),
      this.error
    );

    if (this.error?.statusCode === 404) {
      console.debug(
        contextualizeLog(
          'Detected 404 status code in the error, redirecting to feed'
        )
      );

      inAppRedirect(this.localePath(appURLs.feed()));
    }
  },
};
