export const feed = (state) => state.feed;
export const feedLoading = (state) => state.feedLoading;
export const nextPostsLoading = (state) => state.nextPostsLoading;
export const error = (state) => state.error;
export const headlines = (state) => state.headlines;
export const headlinesLoading = (state) => state.headlinesLoading;
export const headlinesError = (state) => state.headlinesError;
export const trendingIndices = (state) => state.trendingIndices;
export const trendingIndicesError = (state) => state.trendingIndicesError;
export const trendingIndicesLoading = (state) => state.trendingIndicesLoading;
export const allTrendingIndices = (state) => state.allTrendingIndices;
export const allTrendingIndicesError = (state) => state.allTrendingIndicesError;
export const allTrendingIndicesLoading = (state) =>
  state.allTrendingIndicesLoading;
