/**
 * Localize an ordinal number
 * @param {string} locale The locale to use
 * @param {number} number The number to localize
 * @returns {string} The localized ordinal number
 */
export default function localizeOrdinal(
  locale: string,
  number: number
): string {
  switch (locale) {
    case 'fr':
      return `${number}${number === 1 ? 'er' : 'e'}`;
    case 'en':
    default:
      return `${number}${
        number === 1 ? 'st' : number === 2 ? 'nd' : number === 3 ? 'rd' : 'th'
      }`;
  }
}
