
import { inAppRedirect } from '~/utils/route-redirects';

export default {
  Name: 'UITrendingIndexWidget',
  props: {
    value: {
      type: Number,
      required: true,
    },
    profileId: {
      type: String,
      required: false,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    change: {
      type: Number,
      required: true,
    },
  },
  methods: {
    inAppRedirect,
  },
};
