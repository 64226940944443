
import Card from '@/components/ui/atom/Card';
import InfoIcon from '@/components/ui/atom/InfoIcon';

export default {
  name: 'PortfolioStatsCard',
  components: {
    Card,
    InfoIcon,
  },
  props: {
    infoType: {
      type: String,
      default: '',
    },
    infoAdditionalData: {
      type: String,
      default: '',
    },
  },
  computed: {
    tooltipText() {
      if (this.infoType == 'unlisted')
        return this.$t('portfolio_ranking_tooltip_unlisted', {
          timer: this.infoAdditionalData,
        });
      else if (this.infoType == 'unranked')
        return this.$t('portfolio_ranking_tooltip_unranked');
      else if (this.infoType === 'canGetListed')
        return this.$t('portfolio_ranking_tooltip_can_get_listed');
      else if (this.infoType === 'def_market_value')
        return this.$t('portfolio_definition_market_value');
      else if (this.infoType === 'def_stock_change')
        return this.$t('portfolio_definition_stock_change');
      else if (this.infoType === 'def_return')
        return this.$t('portfolio_definition_return');
      else return '';
    },
  },
};
