import { NSRootGetterTree } from '~/store/types';
import { SearchHistory } from '~/types/api/search';
import { SearchResult } from '~/types/models/search';
import { SearchState } from './types';
import { StoreValue } from '~/store/types';
import { uniqBy } from 'lodash';

export default {
  /**
   * Returns whether the search history should be shown.
   * @param state The app state
   * @returns Whether the search history should be shown.
   */
  showSearchHistory: (state: SearchState): boolean => state.showSearchHistory,
  /**
   * Returns the search popup state.
   * @param state
   * @returns The search popup state.
   */
  searchPopupShown: (state: SearchState): boolean => state.searchPopupShown,

  /**
   * Returns the search results for the current search query.
   * @param state The app state
   * @returns The search results for the current search query.
   */
  userSearchResults: (state: SearchState): StoreValue<SearchResult | null> =>
    state.userSearch,
  /**
   * Returns the search history of the user which matches the specified filter.
   */
  filteredUserSearchHistoryRes:
    (state: SearchState) =>
    (_query: string, count = 5): SearchHistory => {
      return uniqBy(state.searchHistory.data, 'query').slice(0, count);
    },

  /**
   * @returns The raw search stored value.
   * @deprecated This does nothing for you and should not be used. If you are
   * absolutely sure you want the `search` state property (which is also
   * deprecated), use `mapState` as this getter merely returns the underlying
   * value.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  searchRes: (state: SearchState): any => state.search,
} as NSRootGetterTree<SearchState>;
