export const usersRes = (state) => state.users;
export const companiesRes = (state) => state.companies;
export const cryptosRes = (state) => state.cryptos;
export const tickersRes = (state) => state.tickers;
export const hashtagsRes = (state) => state.hashtags;
export const indicesRes = (state) => state.indices;
export const symbolsRes = (state) => state.symbols;
export const isSearchLoading = (state) => state.searchLoading;

export const v2Tickers = (state) => state.v2.tickers;
export const v2HasMoreTickers = (state) => state.v2.hasMoreTickers;
export const v2Users = (state) => state.v2.users;
export const v2HasMoreUsers = (state) => state.v2.hasMoreUsers;
export const v2Page = (state) => state.v2.page;
export const v2ResultsPerPage = (state) => state.v2.resultsPerPage;
