import { render, staticRenderFns } from "./GiphyModal.vue?vue&type=template&id=50465cc0"
import script from "./GiphyModal.vue?vue&type=script&lang=js"
export * from "./GiphyModal.vue?vue&type=script&lang=js"
import style0 from "./GiphyModal.vue?vue&type=style&index=0&id=50465cc0&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/skade-pwa/components/ui/atom/buttons/Button.vue').default,Loader: require('/skade-pwa/components/ui/atom/Loader.vue').default,Img: require('/skade-pwa/components/ui/atom/Img.vue').default,Card: require('/skade-pwa/components/ui/atom/Card.vue').default,Modal: require('/skade-pwa/components/ui/atom/Modal.vue').default})
