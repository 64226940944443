import { render, staticRenderFns } from "./UserImgInfo.vue?vue&type=template&id=be83fc68&scoped=true"
import script from "./UserImgInfo.vue?vue&type=script&lang=js"
export * from "./UserImgInfo.vue?vue&type=script&lang=js"
import style0 from "./UserImgInfo.vue?vue&type=style&index=0&id=be83fc68&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be83fc68",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProfileImg: require('/skade-pwa/components/ui/atom/ProfileImg.vue').default,Truncate: require('/skade-pwa/components/ui/atom/Truncate.vue').default})
