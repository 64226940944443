import { MutationsCreator } from '@/store/utils';

export default {
  INIT: (state) => {
    state.industries = null;
    state.loading = false;
    state.error = false;
  },
  SET_INDUSTRIES: (state, payload) => {
    state.industries = payload;
  },
  SET_FOLLOWED_INDUSTRIES: (state, payload) => {
    state.industries = payload;
  },
  UPDATE_INDUSTRY: (state, industry) => {
    if (state.industries) {
      let index = state.industries.findIndex(
        (e) => e.industryId === industry.industryId
      );

      if (index !== -1) {
        let res = [...state.industries];

        res[index] = industry;
        state.industries = res;
      }
    }
  },
  TOGGLE_FOLLOW_INDUSTRY: (state, industries) => {
    if (state.industries) {
      let res = [...state.industries];

      industries.forEach((industry) => {
        let index = state.industries.findIndex(
          (e) => e.industryId === industry.industryId
        );
        if (index !== -1) {
          res[index].isFollowed = !res[index].isFollowed;
        }
      });
      state.industries = res;
    }
  },
  SET_LOADING: (state, isLoading) => {
    state.loading = isLoading;
  },
  SET_FOLLOWED_INDUSTRY_LOADING: (state, isLoading) => {
    state.loading = isLoading;
  },
  SET_ERROR: (state, err) => {
    state.error = err;
    window.$nuxt.$notify.error('Error when loading industries');
  },
  SET_FOLLOWED_INDUSTRY_ERROR: (state, err) => {
    state.error = err;
    window.$nuxt.$notify.error('Error when following an industry');
  },
};
