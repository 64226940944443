
import CloseIcon from '~/components/ui/icons/CloseIcon.vue';
export default {
  name: 'UISimplePortfolioCard',
  components: { CloseIcon },
  props: {
    portfolio: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
