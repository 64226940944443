export default {
  INIT(state, payload) {
    state.hasCompletedTutorial = payload.hasCompletedTutorial;
    state.hasSeenTips = payload.hasSeenTips;
    state.tutorialStep = payload.tutorialStep;
    state.tipsStep = payload.tipsStep;
    state.companiesOffset = 1;
    state.profilesOffset = 1;
  },
  COMPLETE_TUTORIAL: (state) => {
    state.hasCompletedTutorial = true;
  },
  COMPLETE_TIPS: (state) => {
    state.hasSeenTips = true;
  },
  TUTORIAL_NEXT_STEP: (state) => {
    state.tutorialStep++;
  },
  TUTORIAL_PREVIOUS_STEP: (state) => {
    state.tutorialStep--;
  },
  SET_TUTORIAL_LOADING(state, isLoading) {
    state.tutorialLoading = isLoading;
  },
  TIPS_NEXT_STEP: (state) => {
    state.tipsStep++;
  },
  SET_COMPANIES_LOADING(state, isLoading) {
    state.recommendedCompaniesLoading = isLoading;
  },
  SET_NEXT_COMPANIES_LOADING(state, isLoading) {
    state.nextCompaniesLoading = isLoading;
  },
  SET_COMPANIES(state, companies) {
    if (!companies) {
      state.recommendedCompanies = null;
      return;
    }

    // Deduplication strategy - use profileId as key since it's unique and is used as a key to display companies

    const profileIdMap = new Map();
    companies.forEach((company) =>
      profileIdMap.set(company.profileId, company)
    );

    state.recommendedCompanies = [...profileIdMap.values()];
  },
  SET_COMPANIES_SEARCH_QUERY(state, query) {
    state.recommendedCompaniesSearchQuery = query;
  },
  APPEND_COMPANIES(state, companies) {
    const profileIdMap = new Map();

    // Deduplication strategy - use profileId as key since it's unique and is used as a key to display companies

    state.recommendedCompanies.forEach((company) =>
      profileIdMap.set(company.profileId, company)
    );

    companies?.forEach((company) =>
      profileIdMap.set(company.profileId, company)
    );

    state.recommendedCompanies = [...profileIdMap.values()];
  },
  INCREASE_COMPANIES_OFFSET(state) {
    state.companiesOffset++;
  },
  RESET_COMPANIES_OFFSET(state) {
    state.companiesOffset = 1;
  },
  SET_PROFILES_LOADING(state, isLoading) {
    state.recommededProfilesLoading = isLoading;
  },
  SET_NEXT_PROFILES_LOADING(state, isLoading) {
    state.nextProfilesLoading = isLoading;
  },
  SET_PROFILES(state, profiles) {
    state.recommendedProfiles = profiles;
  },
  APPEND_PROFILES(state, profiles) {
    state.recommendedProfiles = [...state.recommendedProfiles, ...profiles];
  },
  INCREASE_PROFILES_OFFSET(state) {
    state.profilesOffset++;
  },
  RESET_PROFILES_OFFSET(state) {
    state.profilesOffset = 1;
  },
  UPDATE_PROFILE_IS_FOLLOWED(state, profile) {
    if (state.recommendedProfiles) {
      const index = state.recommendedProfiles.findIndex(
        (p) => p.profileId === profile.profileId
      );

      if (index != -1) {
        state.recommendedProfiles[index].isFollowed = profile.isFollowed;
      }
    }
  },
  UPDATE_COMPANY_IS_FOLLOWED(state, company) {
    if (state.recommendedCompanies) {
      const index = state.recommendedCompanies.findIndex(
        (c) => c.profileId === company.profileId
      );

      if (index != -1) {
        state.recommendedCompanies[index].isFollowed = company.isFollowed;
      }
    }
  },
  SET_SHOW_MORE_COMPANIES(state, value) {
    state.showMoreCompanies = value;
  },
  SET_SHOW_MORE_PROFILES(state, value) {
    state.showMoreProfiles = value;
  },
  SET_BIRTHDATE_SUCCESS(state, value) {
    state.birthdateSuccess = value;
  },
  SET_PROFILES_SEARCH_QUERY(state, query) {
    state.recommendedProfilesSearchQuery = query;
  },
  SET_USER_ONBOARDING_QUESTIONS(state, value) {
    state.userOnboardingQuestions = value;
  },
  SET_USER_ONBOARDING_ANSWERS: (state, payload) => {
    state.userOnboardingAnswers = payload;
  },
  APPEND_USER_ONBOARDING_ANSWER: (state, payload) => {
    // Steps are defined by the Order field in UserOnboardingQuestions.
    const step = state.userOnboardingQuestions.find(
      (q) => q.id == payload.userOnboardingQuestionId
    ).order;

    /* When in step 0, user can select only one option, while when in step 1, 
    user can select multiple options. Which also practically means, when in step 0, user 
    deselects an option by selecting another one, while when in step 1, they 
    can deselect an option without selecting another one. */
    if (step === 0) {
      state.userOnboardingAnswers = state.userOnboardingAnswers.filter(
        (a) =>
          !(a.userOnboardingQuestionId === payload.userOnboardingQuestionId)
      );
    } else if (step === 1) {
      const lengthBefore = state.userOnboardingAnswers.length;
      state.userOnboardingAnswers = state.userOnboardingAnswers.filter(
        (a) =>
          !(
            a.userOnboardingQuestionId == payload.userOnboardingQuestionId &&
            a.choice == payload.choice
          )
      );
      const lengthAfter = state.userOnboardingAnswers.length;
      if (lengthBefore != lengthAfter) {
        return;
      }
    }
    state.userOnboardingAnswers.push(payload);
  },
};
