
import Vue from 'vue';
import { buildAssetUri } from '@/utils/mixins';
import { inAppRedirect } from '~/utils/route-redirects';
import { getWindowInstance } from '~/plugins/windowInstance';

export default Vue.extend({
  name: 'SearchResultEntry',
  components: {},
  mixins: [buildAssetUri],
  props: {
    link: {
      type: [String, Object],
      required: true,
    },
    imageSrc: {
      type: String,
      required: true,
    },
    defaultImageSrc: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: true,
    },
  },
  computed: {
    hasCustomImageSlot(): boolean {
      return !!this.$slots.customImage;
    },
    isMobileResponsive(): boolean {
      // @ts-ignore TODO fix typescript not recognizing this
      return getWindowInstance(this).isMobileResponsive();
    },
  },
  methods: {
    redirectToLink(): void {
      inAppRedirect(this.link);
    },
  },
});
