
import { mapActions, mapGetters } from 'vuex';
import UIMentionCard from '@/components/ui/organism/UIMentionCard';
import { isDesktop } from '~/utils/screenSizes';

export default {
  name: 'MentionCardWrapper',
  components: {
    UIMentionCard,
  },
  computed: {
    ...mapGetters('modals', [
      'currentMentionCard',
      'isMentionHovered',
      'isMentionCardHovered',
    ]),
    isDesktopSize() {
      return isDesktop();
    },
  },
  methods: {
    ...mapActions('modals', [
      'mouseEnterMentionCard',
      'mouseLeaveMentionCard',
      'followMentionCard',
    ]),
    handleShowMentionCard() {
      this.mouseEnterMentionCard();
    },
    handleHideMentionCard() {
      this.mouseLeaveMentionCard();
    },
    bindMentionCardEvent(node) {
      node.addEventListener('mouseenter', this.handleShowMentionCard);
      node.addEventListener('mouseleave', this.handleHideMentionCard);
    },
    removeMentionCardEvent(node) {
      node.removeEventListener('mouseenter', this.handleShowMentionCard);
      node.removeEventListener('mouseleave', this.handleHideMentionCard);
    },
  },
};
