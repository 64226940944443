import { SignalR, SignalRHubs } from './types';

import { Context } from '@nuxt/types/app';
import { HubConnection } from '@microsoft/signalr';
import { getAccessToken } from '~/utils/behavior/authService';
import messageNotificationsHub from './message-notifications';
import messagingHub from './messaging';
import notificationsHub from './notifications';
import searchHistoryHub from './search-history';

export interface ContainsHubConnection {
  hub: HubConnection | null;
}

/**
 * Exposes the main interface for interacting with signalr.
 *
 * This will inject a `signalr` variable within the global nuxt context and you
 * may access it within your components.
 *
 * Internal:
 *
 * Do not register this as a plugin in `nuxt.config.js` since it relies on the user being authenticated for this plugin to run.
 * Since users aren't authenticated at the time when plugins run, this plugin needs to be dynamically injected at runtime, client-side.
 * Where do I inject this dynamically? In one of the layout components or its direct children most likely - select a component level where it's ensured that the user is authenticated and
 * at the very least has a JWT and the app has access to the user's profileId.
 *
 *   Feel free to edit this function to add more hubs as necessary. See the
 *   implementation of the notification hub as an example.
 */
export default async function (context: Context) {
  console.debug('Starting SignalR plugin');

  // @ts-ignore TODO Figure out why this is unset
  const token = await getAccessToken();

  if (!token) {
    console.debug(
      'SignalR plugin failed to obtain access token. Not registering SignalR.'
    );

    return;
  }

  const hubs: SignalRHubs = {
    notification: notificationsHub(context.store, token),
    'search-history': searchHistoryHub(context.store, token),
    messaging: messagingHub(context.store, token),
    'message-notifications': messageNotificationsHub(context.store, token),
  };

  Object.entries(hubs).forEach(([, containsHub]) => {
    if (containsHub) {
      containsHub.hub?.start();
      console.debug(containsHub.hub?.baseUrl + ' connection started!');
    }
  });

  window.$signalR = { hubs } as SignalR;
}

/**
 * Returns an initialized instance of our signalR service.
 * @returns
 */
export function getSignalR(): SignalR {
  return window.$signalR as SignalR;
}

// Extend the definition of the window object to include our signalR hubs.
declare global {
  interface Window {
    $signalR: SignalR;
  }
}
