
import UISimpleProfileCard from '@/components/ui/organism/UISimpleProfileCard';
import DropDownPortfolioRoles from '@/components/ui/molecule/DropDownPortfolioRoles';
import { debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'UIProfileSearchWIthProfileCards',
  components: { UISimpleProfileCard, DropDownPortfolioRoles },
  props: {
    profilesToAdd: {
      type: Array,
      default() {
        return [];
      },
    },
    roles: {
      type: Array,
      default() {
        return [];
      },
    },
    selectedRole: {
      type: Object,
      default() {
        return {};
      },
    },
    portfolioUsers: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      query: '',
      pickerVisible: false,
      currentIndex: 0,
    };
  },
  computed: {
    ...mapGetters('search', ['searchRes']),
  },
  watch: {
    'searchRes.data.mentions'() {
      this.filterAlreadyAddedUsersFromSearch();
    },
  },
  methods: {
    ...mapActions('search', ['search']),
    handleSearchDebounce: debounce(function (query) {
      if (query) {
        const query = this.query;
        this.search({ type: '@', query, limit: 10 });
      }
    }, 300),
    handleSearch() {
      this.handleSearchDebounce(this.query);
    },
    handleClickOutside() {
      this.pickerVisible = false;
    },
    handleRoleChange(role) {
      this.$emit('onRoleChange', role);
    },
    filterAlreadyAddedUsersFromSearch() {
      if (this.searchRes.mentions?.length === 0) {
        return this.searchRes.mentions;
      }

      const filteredSearchRes = this.searchRes.data.mentions;

      // Filtering out users that are already added to the portfolio
      this.portfolioUsers.forEach((profile) => {
        const profileIndex = filteredSearchRes.findIndex(
          (existingProfile) => existingProfile.profileId === profile.profileId
        );

        if (profileIndex !== -1) {
          filteredSearchRes.splice(profileIndex, 1);
        }
      });
      // Filtering out users that we want to add to the portfolio
      this.profilesToAdd.forEach((profile) => {
        const profileIndex = filteredSearchRes.findIndex(
          (existingProfile) => existingProfile.profileId === profile.profileId
        );

        if (profileIndex !== -1) {
          filteredSearchRes.splice(profileIndex, 1);
        }
      });
      return filteredSearchRes;
    },
  },
};
