import { render, staticRenderFns } from "./PortfolioSearchResultCard.vue?vue&type=template&id=53bc5c94"
import script from "./PortfolioSearchResultCard.vue?vue&type=script&lang=js"
export * from "./PortfolioSearchResultCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProfileImg: require('/skade-pwa/components/ui/atom/ProfileImg.vue').default,Truncate: require('/skade-pwa/components/ui/atom/Truncate.vue').default,ChangePercent: require('/skade-pwa/components/ui/atom/ChangePercent.vue').default})
