import actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

const state = () => ({
  success: false,
  error: null,
  // Reset Password Modal
  resetPasswordTokenEmailSuccess: false,
  resetPasswordToken: null
});

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
