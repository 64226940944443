// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media (min-width:1024px){.ui-post:hover{background-color:var(--color-neutral-4);cursor:pointer}.ui-post:focus{background-color:transparent}.ui-post.disable-hover:hover{background-color:var(--color-secondary)}.ui-post.disable-hover:focus{background-color:transparent}}.ui-post .rounded-lg{border-radius:.5rem!important}.ui-post .img-rounded{border-radius:15px!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"a": "#5a80fb",
	"b": "#6b88fc",
	"c": "#7b91fc",
	"d": "#8899fd",
	"e": "#95a2fd",
	"f": "#a1abfe",
	"g": "#adb4fe",
	"h": "#b8bdfe",
	"i": "#c3c6ff",
	"j": "#cdcfff",
	"k": "#d8d9ff",
	"l": "#e2e2ff",
	"m": "#ececff",
	"n": "#f5f5ff",
	"o": "#fff"
};
module.exports = ___CSS_LOADER_EXPORT___;
