export const topGainerPortfolios = (state) => state.topGainerPortfolios.data;
export const topGainerPortfoliosShowMore = (state) =>
  state.topGainerPortfoliosPagination.showMore;
export const topGainerPortfoliosLoading = (state) =>
  state.topGainerPortfolios.loading;
export const nextTopGainerPortfoliosLoading = (state) =>
  state.nextTopGainerPortfoliosLoading;
export const followedUsersTopGainerPortfolios = (state) =>
  state.followedUsersTopGainerPortfolios.data;
export const followedUsersTopGainerPortfoliosShowMore = (state) =>
  state.followedUsersTopGainerPortfoliosPagination.showMore;
export const followedUsersTopGainerPortfoliosLoading = (state) =>
  state.followedUsersTopGainerPortfolios.loading;
export const nextFollowedUsersTopGainerPortfoliosLoading = (state) =>
  state.nextFollowedUsersTopGainerPortfoliosLoading;
