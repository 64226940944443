import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { Store } from 'vuex';
import { SearchHistory } from '~/types/messages/search-history';
import { RootState } from '~/store';
import { ContainsHubConnection } from '..';

export default function searchHistoryHub(
  store: Store<RootState>,
  jwt: string
): ContainsHubConnection {
  const profileId = store.state.authentication.profileId;
  if (!profileId) {
    console.error(
      'No profileId set. Cannot setup the searchHistoryHub connection.'
    );
    return { hub: null };
  }

  const hub = new HubConnectionBuilder()
    .withUrl(
      `${process.env.NUXT_ENV_API_URL}/subscribe/search-history?` +
        `profileId=${profileId}`,
      {
        accessTokenFactory: () => jwt,
      }
    )
    .configureLogging(
      ['local', 'development'].includes(process.env.NODE_ENV ?? '')
        ? LogLevel.Debug
        : LogLevel.None
    )
    .build();

  hub.on('ReceiveSearchHistory', (not: SearchHistory) => {
    console.debug('Received new search history', not);
    store.commit('search/SEARCH_HISTORY_SET', not);
  });

  return { hub };
}
