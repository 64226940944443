import actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

const state = () => ({
  stocks: null,
  getStocks: {
    loading: false,
    error: null,
  },
  getNextStocks: {
    loading: false,
    error: null,
  },
  followStock: {
    loading: false,
    error: null,
  },
});

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
