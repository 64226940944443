import actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

const state = () => ({
  post: null,
  replies: null,
  postLoading: false,
  repliesLoading: false,
  nextRepliesLoading: false,
  nestedRepliesLoading: false,
  nextNestedRepliesLoading: false,
  postError: null,
  repliesError: null,
  isLoadFinished: false,
});

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
