
import PortfolioIcon from '~/components/ui/icons/PortfolioIcon.vue';
import ChevronIcon from '~/components/ui/icons/ChevronIcon.vue';
export default {
  name: 'UIPortfoliosDropdown',
  components: { PortfolioIcon, ChevronIcon },
  props: {
    portfolios: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isPickerVisible: false,
      selectedItem: null,
    };
  },
  created() {
    this.selectedItem = this.portfolios[0];
  },
  methods: {
    handleClickOutside() {
      this.isPickerVisible = false;
    },
    handlePortfolioCardClick(selectedPortfolio) {
      this.selectedItem = selectedPortfolio;
      this.isPickerVisible = false;
      this.$emit('onPortfolioChange', selectedPortfolio);
    },
  },
};
