import {
  OIDCClient,
  OIDCHost,
  OIDCRedirect,
  OIDCScope,
  PostLogoutRedirectURL,
} from '@/utils/env-vars';
import { UserManager, WebStorageStateStore } from 'oidc-client-ts';

const contextualizedLogMessage = (message: string) => `authService. ${message}`;

// TODO remove the export here
export const userManager = new UserManager({
  authority: OIDCHost,
  client_id: OIDCClient,
  redirect_uri: OIDCRedirect,
  scope: OIDCScope,
  // TODO this could be a configuration parameter. We could choose between localStorage, sessionStorage, cookies, ...
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  // this will ensure the access token gets refreshed automatically in the background, 60s prior to expiration
  automaticSilentRenew: true,
});

// TODO document return types

export const getUser = () => userManager.getUser();

export const loginByRedirect = async () => {
  // TODO save existing route or query parameters, so that we can redirect the user back to where they were

  return userManager.signinRedirect();
};

export const loginRedirectCallback = () => userManager.signinRedirectCallback();

/**
 * This function will attempt to refresh the user's session and it will not handle errors that may be thrown.
 * Please use carefully and with clear intent. Otherwise use the refreshSession function.
 *
 * @returns {Promise}
 * @throws
 */
export const unhandledRefreshSession = () => userManager.signinSilent({});

// TODO add the function call from
// layouts/SharedLayout.vue for updateDecodedToken here
// the updateDecodedToken function could be declared here and used there, though it requires access to the store
// we want to always have a freshly decoded access token in the state, so it would be good to do this
export const refreshSession = async () => {
  try {
    const user = await userManager.signinSilent({});

    if (!user || user.expired) {
      if (!user)
        console.error(
          contextualizedLogMessage('Failed to refresh user session.')
        );
      else if (user.expired)
        console.error(
          contextualizedLogMessage('User expired despite refresh attempt')
        );
      else
        console.error(
          contextualizedLogMessage(
            'Unexpected user state detected after refresh attempt'
          )
        );

      console.debug(
        contextualizedLogMessage('User session is expired. Logging out')
      );

      await logout();
      return undefined;
    }

    console.debug(contextualizedLogMessage('Access token refresh successful'));

    return user;
  } catch (ex) {
    console.error(
      contextualizedLogMessage('Failed to refresh session. Logging out'),
      ex
    );

    await logout();
    return undefined;
  }
};

export const logout = () =>
  userManager.signoutRedirect({
    // redirect the user to this URL once logout is complete on the OIDC side
    post_logout_redirect_uri: PostLogoutRedirectURL,
  });

export const logoutRedirectCallback = () =>
  userManager.signoutRedirectCallback();

export const getAccessToken = async () => {
  const user = await userManager.getUser();

  if (!user) {
    console.debug('No user found. Returning empty access token');

    return null;
  }

  // TODO what if the token is being requested as its being renewed?
  // potential race condition that requires a lock

  return user.access_token;
};

// this callback gets called just before the app is about to refresh the access token
async function accessTokenExpiringCallback(context: unknown) {
  console.debug(
    contextualizedLogMessage(
      'Access token expiring event handler. About to refresh the access token'
    ),
    context
  );
}

// TODO maybe make this opt-in, for pages that don't require auth
// logout the user if silent renew fails
async function silentRenewErrorCallback(context: unknown) {
  console.debug(
    contextualizedLogMessage(
      'Failed to refresh access token. addSilentRenewError callback will logout the user'
    ),
    context
  );

  await logout();
}

export const stopAutomaticSessionRefresh = () => {
  userManager.stopSilentRenew();
};

export const removeUserManagerCallbacks = () => {
  userManager.events.removeAccessTokenExpiring(accessTokenExpiringCallback);
  userManager.events.removeSilentRenewError(silentRenewErrorCallback);
};

userManager.events.addAccessTokenExpiring(accessTokenExpiringCallback);
userManager.events.addSilentRenewError(silentRenewErrorCallback);
