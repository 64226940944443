import actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

const state = () => ({
  similarCryptos: [],
  similarCryptosPage: 1,
  similarCryptosError: null,
  similarCryptosLoading: false,
  isViewMoreVisible: true
});

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
