
import Vue from 'vue';
import { mapGetters, mapState, mapActions } from 'vuex';
import WIcon from '~/components/ui/icons/WIcon.vue';
// TODO: Refactor these three components...
import ProfileImg from '~/components/ui/atom/ProfileImg.vue';
import Portfolios from '~/components/shared/nav/header/portfolios/index.vue';
import Notifications from '~/components/shared/nav/header/notifications/index.vue';
import Help from '~/components/shared/nav/header/help/index.vue';
import { getWindowInstance } from '~/plugins/windowInstance';

export default Vue.extend({
  components: {
    Notifications,
    Portfolios,
    ProfileImg,
    WIcon,
    Help,
  },
  props: {
    showHelpCenter: {
      type: Boolean,
      required: true,
    },
  },
  async fetch() {
    await this.getMyPortfolios();
  },
  computed: {
    profilePicAlt(): string {
      return this.$t('posession', {
        subj: this.profile.displayName,
        obj: this.$t('profile_picture'),
      }) as string;
    },
    actionIcon(): string {
      if (!['sm'].includes(this.$mq)) {
        return 'profile';
      }

      return this.pageActionIcon;
    },
    actionRoute(): string {
      return this.pageActionRoute || `/profile/${this.profile.profileId}`;
    },
    ...mapState('ui-messaging', ['pageActionIcon', 'pageActionRoute']),
    ...mapGetters('authentication', ['profile', 'uploadProfileAvatarSuccess']),
    isAboveXlResponsive(): boolean {
      // @ts-ignore TODO fix typescript not recognizing this
      return getWindowInstance(this).isAboveXlResponsive();
    },
  },
  methods: {
    ...mapActions('portfolio', ['getMyPortfolios']),
  },
});
