import { GetterTree, ActionTree, Module, Store } from 'vuex';

export type RootState = Record<never, never>;
export type GlobalStore = Store<RootState>;

/**
 * Represents the states through which a promise may go.
 */
export enum EPromiseState {
  /** The promise has never been started */
  None,
  /** The promise is pending */
  Loading,
  /** The promise is done. */
  Done,
  /** The promise has failed */
  Error,
}

export interface StoreValue<T, E = unknown | null> {
  data: T;
  state: EPromiseState;
  error: E;
}

/** Defines a getter tree namespaced within the global store. */
export type NSRootGetterTree<ModuleState> = GetterTree<ModuleState, RootState>;
/** Defines an action tree namespaced within the global store. */
export type NSRootActionTree<ModuleState> = ActionTree<ModuleState, RootState>;
/** Defines a module namespaced within the global store. */
export type NSRootModule<ModuleState> = Module<ModuleState, RootState>;
