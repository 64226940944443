
export default {
  name: 'SendCodeStep',
  computed: {
    hashEmail() {
      var hintStart = this.email.substring(0, 3),
        split = this.email.split('@'),
        hintProvider = split[1].substring(0, 1),
        splitExtension = split[1].split('.');
      return (
        hintStart +
        this.repeat('*', split[0].length - 3) +
        '@' +
        hintProvider +
        this.repeat('*', splitExtension[0].length - 1) +
        '.' +
        this.repeat('*', splitExtension[1].length)
      );
    },
    hashPhone() {
      var l = this.phone.length;
      return (
        this.phone.substring(0, 3) +
        this.repeat('*', l - 5) +
        this.phone.substring(l - 2, l)
      );
    }
  },
  methods: {
    repeat(pattern, count) {
      if (count < 1) return '';
      var result = '';
      while (count > 1) {
        if (count & 1) result += pattern;
        (count >>= 1), (pattern += pattern);
      }
      return result + pattern;
    }
  },
  props: {
    email: {
      type: String,
      required: true
    },
    phone: {
      type: String,
      default: '+33667731867'
    }
  },
  data() {
    return {
      value: null
    };
  }
};
