export default {
  init(context) {
    context.commit('INIT');
  },
  getTrendingTickers(context,payload) {
    context.commit('SET_TRENDING_TICKERS_LOADING', true);
    this.dispatch('api/getTrendingTickers',payload)
      .then(res => {
        
        if(payload.trendingType === 'GAINERS') 
        {
          context.commit('SET_TRENDING_GAINERS_TICKERS', res.data);
        }
        if(payload.trendingType === 'LOSERS')
        {
          context.commit('SET_TRENDING_LOSERS_TICKERS', res.data);
        }
        if(payload.trendingType === 'ALL')
        {
          context.commit('SET_TRENDING_TICKERS', res.data);
        }
      })
      .catch(err => {
        context.commit('SET_TRENDING_TICKERS_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_TRENDING_TICKERS_LOADING', false);
      });
  }
};
