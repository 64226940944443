import { render, staticRenderFns } from "./SymbolCards.vue?vue&type=template&id=b1d1d2b4"
import script from "./SymbolCards.vue?vue&type=script&lang=js"
export * from "./SymbolCards.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PriceCardSkeleton: require('/skade-pwa/components/ui/atom/skeleton/PriceCardSkeleton.vue').default})
