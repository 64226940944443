import { render, staticRenderFns } from "./OnboardingTourCard.vue?vue&type=template&id=9480a274"
import script from "./OnboardingTourCard.vue?vue&type=script&lang=js"
export * from "./OnboardingTourCard.vue?vue&type=script&lang=js"
import style0 from "./OnboardingTourCard.vue?vue&type=style&index=0&id=9480a274&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/skade-pwa/components/ui/atom/buttons/Button.vue').default,Card: require('/skade-pwa/components/ui/atom/Card.vue').default})
