const recommendationLimit = 5;

export default {
  init(context) {
    context.commit('INIT');
  },

  // Mutual Follows
  getMutualFollows(context) {
    context.commit('SET_MUTUAL_FOLLOWS_LOADING', true);
    return this.dispatch('api/getMutualFollowsRecommendation', {
      limit: recommendationLimit,
      page: context.state.mutualFollowsPage,
    })
      .then((res) => {
        context.commit('SET_MUTUAL_FOLLOWS', res.data.recommendations);
        res.data.reset && context.commit('RESET_MUTUAL_FOLLOWS_PAGE');
      })
      .catch((err) => {
        context.commit('SET_MUTUAL_FOLLOWS_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_MUTUAL_FOLLOWS_LOADING', false);
      });
  },
  getNextMutualFollows(context) {
    context.commit('INCREASE_MUTUAL_FOLLOWS_PAGE');
    return context.dispatch('getMutualFollows');
  },
  getTopInvestors(context, payload) {
    context.commit('GET_TOP_INVESTORS_INIT');
    return this.dispatch('api/getTopInvestors', payload)
      .then((res) => {
        context.commit('GET_TOP_INVESTORS_SUCCESS', res.data);
      })
      .catch((err) => {
        context.commit('GET_TOP_INVESTORS_ERROR', err);
      });
  },

  // Mutual Industries
  getMutualIndustries(context) {
    context.commit('SET_MUTUAL_INDUSTRIES_LOADING', true);
    return this.dispatch('api/getMutualIndustriesRecommendation', {
      limit: recommendationLimit,
      page: context.state.mutualIndustriesPage,
    })
      .then((res) => {
        context.commit('SET_MUTUAL_INDUSTRIES', res.data.recommendations);
        res.data.reset && context.commit('RESET_MUTUAL_INDUSTRIES_PAGE');
      })
      .catch((err) => {
        context.commit('SET_MUTUAL_INDUSTRIES_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_MUTUAL_INDUSTRIES_LOADING', false);
      });
  },
  getNextMutualIndustries(context) {
    context.commit('INCREASE_MUTUAL_INDUSTRIES_PAGE');
    return context.dispatch('getMutualIndustries');
  },

  // General
  followRecommendation(context, profileId) {
    return this.dispatch('api/followProfile', profileId);
  },
  handleFollow(context, payload) {
    if (context.state.mutualFollows) {
      const mutualFollowsIndex = context.state.mutualFollows.findIndex(
        (p) => p.profileId === payload.profileId
      );
      if (mutualFollowsIndex > -1) {
        if (context.state.mutualFollows.length == 5) {
          this.dispatch('api/getMutualFollowsRecommendation', {
            limit: recommendationLimit,
            page: context.state.mutualFollowsPage,
          })
            .then((res) => {
              context.commit('REMOVE_MUTUAL_FOLLOW', mutualFollowsIndex);

              res.data.recommendations.length == 5 &&
                context.commit(
                  'APPEND_NEW_MUTUAL_FOLLOW',
                  res.data.recommendations[4]
                );
              res.data.reset && context.commit('RESET_MUTUAL_FOLLOWS_PAGE');
            })
            .catch((err) => {
              context.commit('SET_MUTUAL_FOLLOWS_ERROR', err);
            });
        } else context.commit('REMOVE_MUTUAL_FOLLOW', mutualFollowsIndex);
      }
    }

    if (context.state.mutualIndustries) {
      const mutualIndustriesIndex = context.state.mutualIndustries.findIndex(
        (p) => p.profileId === payload.profileId
      );

      if (mutualIndustriesIndex > -1) {
        if (context.state.mutualIndustries.length == 5) {
          this.dispatch('api/getMutualIndustriesRecommendation', {
            limit: recommendationLimit,
            page: context.state.mutualIndustriesPage,
          })
            .then((res) => {
              context.commit('REMOVE_MUTUAL_INDUSTRY', mutualIndustriesIndex);

              res.data.recommendations.length == 5 &&
                context.commit(
                  'APPEND_NEW_MUTUAL_INDUSTRY',
                  res.data.recommendations[4]
                );
              res.data.reset && context.commit('RESET_MUTUAL_INDUSTRIES_PAGE');
            })
            .catch((err) => {
              context.commit('SET_MUTUAL_INDUSTRIES_ERROR', err);
            });
        } else context.commit('REMOVE_MUTUAL_INDUSTRY', mutualIndustriesIndex);
      }
    }

    if (
      context.state.topInvestors.data &&
      context.state.topInvestors.data.length > 0
    ) {
      const topInvestorIndex = context.state.topInvestors.data.findIndex(
        (p) => p.profileId === payload.profileId
      );
      if (topInvestorIndex > -1) {
        context.commit('SET_TOP_INVESTOR_FOLLOW', {
          index: topInvestorIndex,
          isFollowed: payload.isFollowed,
        });
      }
    }
  },
};
