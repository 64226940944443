import actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

const state = () => ({
  recommendedCompanies: null,
  recommendedCompaniesLoading: false,
  nextCompaniesLoading: false,
  companiesOffset: 1,
  showMoreCompanies: true,
  recommendedProfiles: null,
  recommendedProfilesLoading: false,
  nextProfilesLoading: false,
  profilesOffset: 1,
  showMoreProfiles: true,
});

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
