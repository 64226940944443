
import { mapActions, mapGetters } from 'vuex';
import OnboardingTourCard from '@/components/ui/molecule/OnboardingTourCard';
import HomeIcon from '~/components/ui/icons/HomeIcon.vue';
import ProfileIcon from '~/components/ui/icons/ProfileIcon.vue';
import PortfolioIcon from '~/components/ui/icons/PortfolioIcon.vue';
import MarketIcon from '~/components/ui/icons/MarketIcon.vue';
import BookmarkIcon from '~/components/ui/icons/BookmarkIcon.vue';
import MoreIcon from '~/components/ui/icons/MoreIcon.vue';
import AlertIcon from '~/components/ui/icons/AlertIcon.vue';
import CryptoIcon from '~/components/ui/icons/CryptoIcon.vue';
import ChevronIcon from '~/components/ui/icons/ChevronIcon.vue';
// import AwardIcon from '~/components/ui/icons/AwardIcon.vue';
import NewsIcon from '~/components/ui/icons/NewsIcon.vue';
import {
  tosUrl,
  aboutUsUrl,
  legalNoticeUrl,
  privacyPolicyUrl,
} from '~/utils/links';
import { copyrightText } from '~/utils/behavior/copyright';
import Link from '~/components/ui/atom/Link.vue';
import MessageIcon from '~/components/ui/icons/MessageIcon.vue';
import SettingsIcon from '~/components/ui/icons/SettingsIcon.vue';
import SupportIcon from '~/components/ui/icons/SupportIcon.vue';
import LogoutIcon from '~/components/ui/icons/LogoutIcon.vue';
import { logout } from '~/utils/behavior/authService';
import { isMobile } from '~/utils/screenSizes';
import { appURLs, inAppRedirect } from '@/utils/route-redirects';

export default {
  name: 'Sidebar',
  components: {
    OnboardingTourCard,
    HomeIcon,
    ProfileIcon,
    PortfolioIcon,
    MarketIcon,
    BookmarkIcon,
    MoreIcon,
    AlertIcon,
    CryptoIcon,
    ChevronIcon,
    // AwardIcon,
    NewsIcon,
    Link,
    MessageIcon,
    SettingsIcon,
    SupportIcon,
    LogoutIcon,
  },
  data() {
    return {
      showMoreMenu: false,
      tosUrl,
      aboutUsUrl,
      legalNoticeUrl,
      privacyPolicyUrl,
      copyrightText,
    };
  },
  computed: {
    ...mapGetters('shared', ['showMobileSidebarRes']),
    ...mapGetters('authentication', ['profileId', 'isEmailConfirmed']),
    ...mapGetters('onboarding', [
      'hasSeenTips',
      'tipsStep',
      'tipsTotalStep',
      'hasCompletedTutorial',
    ]),
    ...mapGetters('modals', ['helpCenterObjectRes']),
    ...mapGetters('messaging', ['messageNotificationCount']),
    sanitizedNotificationCount() {
      return this.messageNotificationCount ?? 0;
    },
    appURLs() {
      return appURLs;
    },
  },
  methods: {
    ...mapActions('modals', [
      'showCreatePostModal',
      'showLegalDisclaimerModal',
    ]),
    ...mapActions('onboarding', ['tipsNextStep']),
    hideMoreMenu() {
      this.showMoreMenu = false;
    },
    async handleLogout() {
      await logout();
    },
    handleSupportRedirect() {
      window.open(
        'https://skade.atlassian.net/servicedesk/customer/portal/1',
        '_blank'
      );
    },
    isMobileSize() {
      return isMobile();
    },
    redirectToSettings() {
      inAppRedirect(appURLs.settings());
    },
  },
};
