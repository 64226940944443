
import { mapGetters, mapActions } from 'vuex';

import SearchSymbolWrapper from '@/components/stocks/SearchSymbolWrapper';
import StockOperationCard from '~/components/ui/molecule/StockOperationCard';
import UITickersDropdown from '~/components/ui/organism/UITickersDropdown';
import UIPortfoliosDropdown from '~/components/ui/organism/UIPortfoliosDropdown';
import { getListedPortfolioEarliestOperationDate } from '@/utils/portfolio-dates/';

export default {
  name: 'CreatePortfolioAssetOperationModal',
  components: {
    SearchSymbolWrapper,
    StockOperationCard,
    UITickersDropdown,
    UIPortfoliosDropdown,
  },
  mixins: [getListedPortfolioEarliestOperationDate],
  props: {
    isCreateOperationLoading: {
      type: Boolean,
      default: false,
    },
    isAddItemLoading: {
      type: Boolean,
      default: false,
    },
    tickerToAdd: {
      type: Object,
      default: () => ({
        low: 0,
        high: 0,
      }),
      validator: (v) => ['low', 'high'].every((k) => k in v),
    },
    portfolios: {
      type: Array,
      default: () => [],
    },
    defaultPortfolio: {
      type: Object,
      default: () => null,
    },
    portfolioType: {
      type: String,
      required: true,
      validator: (v) => ['Crypto', 'Stock'].includes(v),
    },
    showTickerSearch: {
      type: Boolean,
      default: false,
    },
    showPortfolioSearch: {
      type: Boolean,
      default: false,
    },
    defaultOperationType: {
      type: String,
      required: true,
      validator: (v) => ['Buy', 'Sell'].includes(v),
    },
    priceLoading: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: String,
      required: true,
    },
    cashAvailable: {
      type: Object,
      default: null,
    },
    daysToSubstract: {
      type: Number,
      default: 2,
    },
    transactionError: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'onAddSymbol',
    'onClose',
    'onDateChange',
    'onPortfolioChange',
    'onSubmit',
  ],
  data() {
    return {
      error: '',
      price: 0,
      quantity: null,
      maxDate: this.$dayjs()
        .subtract(this.daysToSubstract, 'day')
        .format('YYYY-MM-DD'),
      formValues: {
        operationType: 'Buy',
        portfolioName: '',
        date: this.maxDate, // this.$dayjs().subtract(2, 'day').format('YYYY-MM-DD'),  // this.getMaxDate()
        quantity: 1,
        price: 0,
        chosenCurrency: '', // For STOCK TRANSACTION
        chosenCurrencyCash: '', // For CASH imports
      },
      total: 0,
      totalText: '0',
      conversionRate: 1,
      formIsMultiple: false,
      cashAvailableText: {},
      reset: false,
      symbolToSell: null,
      focusTickerSearchField: false,
    };
  },
  computed: {
    ...mapGetters('modals', ['createPortfolioOperationModal']),
    min() {
      return this.tickerToAdd ? parseFloat(this.tickerToAdd.low.toFixed(2)) : 0;
    },
    max() {
      // TODO: Bug prone. tickerToAdd.high is a float which might be actually
      // "Infinity", so, in reality, typeof TickerToAdd.high === string |
      // number, meaning that you need special care for this. Also poorly
      // internationalized -- see display/index.ts for the function you should
      // use.
      return this.tickerToAdd
        ? parseFloat(this.tickerToAdd.high.toFixed(2))
        : 0;
    },
    // TODO: Split into a library we inject and call. yesterday is agnostic of
    // the component.
    disabled() {
      if (this.formValues.operationType == 'Sell') {
        return (
          this.error ||
          !this.quantity ||
          this.quantity > this.maxQuantityToSell ||
          (this.min === 0 && this.max === 0) ||
          !this.withinPriceRange()
        );
      }
      return (
        this.error ||
        !this.quantity ||
        (this.min === 0 && this.max === 0) ||
        !this.withinPriceRange()
      );
    },
    buttonState() {
      if (
        this.formValues.date > this.maxDate ||
        this.formValues.date <= this.minDate
      ) {
        this.$emit('buttonStateChange', 'disabled');
        return 'disabled';
      }
      if ((this.total <= 0 && this.quantity) || this.disabled) {
        this.$emit('buttonStateChange', 'disabled');
        return 'disabled';
      }
      if (this.isCreateOperationLoading || this.isAddItemLoading) {
        this.$emit('buttonStateChange', 'loading');
        return 'loading';
      }
      this.$emit('buttonStateChange', 'enabled');
      return 'enabled';
    },
    minDate() {
      const listedPortfolioEarliestDate = this.$dayjs(
        this.getListedPortfolioEarliestOperationDate()
      );
      const minDate = this.defaultPortfolio.isEligibleToLadder
        ? listedPortfolioEarliestDate.subtract(1, 'day').format('YYYY-MM-DD')
        : this.$dayjs().subtract(10, 'years').format('YYYY-MM-DD');
      if (!this.createPortfolioOperationModal?.symbol) {
        return minDate;
      }
      const item = this.defaultPortfolio.items.find(
        (i) => i.symbol == this.createPortfolioOperationModal.symbol.name
      );
      if (!item || !item.operations || item.operations.length == 0) {
        return minDate;
      }
      item.operations.sort((a, b) =>
        this.$dayjs(a.OperationDate).isAfter(this.$dayjs(b.OperationDate))
          ? 1
          : -1
      );
      const lastOperationDate = this.$dayjs(item.operations[0].operationDate)
        .subtract(1, 'day')
        .format('YYYY-MM-DD');
      if (this.defaultPortfolio.isEligibleToLadder) {
        return this.$dayjs(minDate).isAfter(lastOperationDate)
          ? minDate
          : lastOperationDate;
      } else {
        return lastOperationDate;
      }
    },
    originalCurrency() {
      if (this.tickerToAdd === null) {
        return '';
      }
      if (
        !(
          'currencySymbol' in this.tickerToAdd || 'currency' in this.tickerToAdd
        )
      ) {
        return '';
      }
      if (this.tickerToAdd.currencySymbol !== null) {
        return this.tickerToAdd.currencySymbol;
      }
      if (this.tickerToAdd.currency !== null) {
        return this.tickerToAdd.currency;
      }
      return '';
    },
    shouldShowTickerSearchComponent() {
      return (
        this.createPortfolioOperationModal.symbol === undefined ||
        this.createPortfolioOperationModal.symbol === null
      );
    },
    shouldSubmit() {
      return this.createPortfolioOperationModal.isSubmitting;
    },
    maxQuantityToSell() {
      let quantity = 0;
      let operations = [];
      let sellOperations = [];
      const formDate = this.$dayjs(this.formValues.date);
      if (this.symbolToSell) {
        operations = this.symbolToSell.operations.filter((o) => {
          return (
            o.operationType == 'Buy' &&
            (this.$dayjs(o.operationDate).isBefore(formDate) ||
              this.$dayjs(o.operationDate).isSame(formDate, 'day'))
          );
        });
        sellOperations = this.symbolToSell.operations.filter((o) => {
          return (
            o.operationType == 'Sell' &&
            (this.$dayjs(o.operationDate).isBefore(formDate) ||
              this.$dayjs(o.operationDate).isSame(formDate, 'day'))
          );
        });
      } else if (this.createPortfolioOperationModal.symbol) {
        operations =
          this.createPortfolioOperationModal.symbol.operations.filter((o) => {
            return (
              o.operationType == 'Buy' &&
              (this.$dayjs(o.operationDate).isBefore(formDate) ||
                this.$dayjs(o.operationDate).isSame(formDate, 'day'))
            );
          });
        sellOperations = this.symbolToSell.operations.filter((o) => {
          return (
            o.operationType == 'Sell' &&
            (this.$dayjs(o.operationDate).isBefore(formDate) ||
              this.$dayjs(o.operationDate).isSame(formDate, 'day'))
          );
        });
      }
      if (!operations.length) {
        return 0;
      }
      quantity += operations?.reduce((n, { quantity }) => n + quantity, 0) ?? 0;
      quantity -=
        sellOperations?.reduce((n, { quantity }) => n + quantity, 0) ?? 0;
      return quantity;
    },
    quantityValidation() {
      return `min:1|max:${this.maxQuantityToSell}`;
    },
  },
  watch: {
    quantity(newVal) {
      // TODO: Just do a CTRL+F(toFixed) and find all the bad toFixed. See my
      // other comment in this file.
      this.total = (newVal * this.price * this.conversionRate).toFixed(2);
      this.buttonState;
    },
    price(newVal) {
      this.total = this.round(newVal * this.quantity * this.conversionRate);
    },
    priceLoading(newVal) {
      if (!newVal) this.mitigatePriceCallback();
    },
    'formValues.chosenCurrency'() {
      this.getConversionRate();
      this.total = (this.quantity * this.price * this.conversionRate).toFixed(
        2
      );
      this.$emit('chosenCurrency', this.getChosenCurrencyLabel());
    },
    total() {
      this.getCashAvailableText();
      this.total = this.round(this.total);
      this.$emit('totalAmountChanged', this.total);
      if (this.defaultPortfolio) {
        this.buttonState;
        this.$emit('chosenCurrency', this.getChosenCurrencyLabel());
      }
    },
    tickerToAdd() {
      this.getConversionRate();
      this.buttonState;
    },
    'formValues.operationType'() {
      this.getCashAvailableText();
    },
    shouldSubmit(newVal) {
      if (newVal === true) {
        this.submit(this.createPortfolioOperationModal.multipleTransactions);
        this.buttonState;
      }
    },
    defaultPortfolio() {
      this.getCashAvailableText();
      this.getDefaultChosenCurrency();
    },
  },
  created() {
    this.getDefaultChosenCurrency();
    this.getConversionRate();
    this.formValues.date = this.maxDate;
    this.formValues.operationType = this.defaultOperationType;
    this.mitigatePriceCallback();
    this.getCashAvailableText();
  },

  methods: {
    ...mapActions('modals', ['removeSymbolFromPortfolioOperation']),
    submit(multipeTransactions) {
      // Regular stock transaction
      this.$emit(
        'onSubmit',
        {
          ...this.formValues,
        },
        multipeTransactions,
        () => {
          this.error = '';
          this.total = (
            this.price *
            this.quantity *
            this.conversionRate
          ).toFixed(2);
        },
        false
      );
      if (multipeTransactions) {
        this.quantity = null;
        // Vue formulate doesn't provide api to reset form to pristine state
        // So this is just a trick to reset validation state on wanted fields
        // Unfortunately it does create a short "bumb" in the UI as the field disapears for a moment
        // So any better solution would be welcome
        this.reset = true;
        setTimeout(() => {
          this.reset = false;
        }, 1);
      }
    },
    dateChange(context) {
      this.$emit('onDateChange', context.model, () =>
        this.mitigatePriceCallback()
      );
      this.getCashAvailableText();
      this.buttonState;
    },
    handleAddSymbol(symbol) {
      this.symbolToSell = symbol;
      this.$emit('onAddSymbol', symbol, () => this.mitigatePriceCallback());
    },
    mitigatePriceCallback() {
      // Because of how float precision vary this has become hell
      // But basically this doubious bit is to handle cases chen close=low or close=high
      // And where conversions and approximations make the validation be incoherent
      // TODO : That only handle the default value and not the actual issue
      const basePrice = this.tickerToAdd ? this.tickerToAdd.close : 0;
      const shift = this.tickerToAdd
        ? this.tickerToAdd.close == this.tickerToAdd.low
          ? 0.02
          : this.tickerToAdd.close == this.tickerToAdd.high
          ? -0.02
          : 0
        : 0;
      const res = Math.floor((basePrice + shift) * 100) / 100;
      this.formValues.price = res;
    },
    resetModalState() {
      this.price = 0;
      this.quantity = null;
      this.symbolToSell = null;
    },
    getDefaultChosenCurrency() {
      if (!this.defaultPortfolio) {
        this.formValues.chosenCurrency = this.currency;
        return;
      }
      if (this.currency in this.cashAvailable) {
        this.formValues.chosenCurrency = this.currency;
      } else {
        const keys = Object.keys(this.cashAvailable);
        if (keys.length > 0) {
          this.formValues.chosenCurrency = keys[0];
        }
      }
    },
    getOriginalCurrencyLabel() {
      if (this.tickerToAdd === null) {
        return '';
      }
      if (
        !(
          'currencySymbol' in this.tickerToAdd || 'currency' in this.tickerToAdd
        )
      ) {
        return '';
      }
      if (this.tickerToAdd.currencySymbol !== null) {
        return ' (' + this.tickerToAdd.currencySymbol + ')';
      }
      if (this.tickerToAdd.currency !== null) {
        return ' (' + this.tickerToAdd.currency + ')';
      }
      return '';
    },
    getChosenCurrencyLabel() {
      const currency = this.formValues.chosenCurrency;
      if (
        currency === null ||
        currency === undefined ||
        currency.length !== 3
      ) {
        return '';
      }
      const symbol = this.$display.money.getCurrencySymbol(currency);
      if (symbol !== '') {
        return symbol;
      }
      return currency;
    },
    getConversionRate() {
      if (!this.tickerToAdd || !this.formValues.chosenCurrency) {
        this.conversionRate = 1;
        return;
      }
      if (
        this.tickerToAdd.currency === this.formValues.chosenCurrency ||
        !('conversions' in this.tickerToAdd || 'conversion' in this.tickerToAdd)
      ) {
        this.conversionRate = 1;
      } else {
        if (
          this.tickerToAdd.conversions !== null &&
          this.tickerToAdd.conversions.length > 0
        ) {
          const conversionObject = this.tickerToAdd.conversions.find(
            (c) => c.currency === this.formValues.chosenCurrency
          );
          this.conversionRate = conversionObject.rate;
        } else if (
          this.tickerToAdd.conversion !== null &&
          this.tickerToAdd.conversion.currency ===
            this.formValues.chosenCurrency
        ) {
          this.conversionRate = this.tickerToAdd.conversion.rate;
        } else {
          this.conversionRate = 1;
        }
      }
    },
    getToolTip() {
      if (this.formValues.date > this.maxDate) {
        this.$emit(
          'buttonTooltipChange',
          this.$t('before_date', {
            date: this.maxDate,
          })
        );
        return this.$t('before_date', {
          date: this.maxDate,
        });
      }
      this.$emit('buttonTooltipChange', '');
      return '';
    },
    /**
     * Calculate the options for cash available, with text (symbol + conversions)
     */
    getCashAvailableText() {
      const toReturn = {};
      const chosenCurrency = this.formValues.chosenCurrency;
      let text;
      let defaultCurrencySymbol = this.$display.money.getCurrencySymbol(
        this.currency
      );
      if (defaultCurrencySymbol === '') {
        defaultCurrencySymbol = this.currency;
      }
      for (const [key, value] of Object.entries(this.cashAvailable)) {
        text =
          key +
          ' ' +
          this.$display.money.money(value.value, value.symbol ?? 'USD', 0, 2);
        // Conversion
        if (this.currency !== key) {
          text +=
            ' (' +
            this.$display.money.money(
              value.conversion,
              defaultCurrencySymbol,
              0,
              2
            ) +
            ')';
        }
        // If not enough cash
        if (
          this.formValues.operationType === 'Buy' &&
          chosenCurrency === key &&
          value.value < this.total
        ) {
          text +=
            ' (' +
            this.$t('cash_will_be_added', {
              amount: this.$display.money.money(
                this.total - value.value,
                value.symbol ?? 'USD',
                0,
                2
              ),
            }) +
            ')';
        }
        toReturn[key] = text;
      }
      this.cashAvailableText = toReturn;
    },
    withinPriceRange() {
      // Individual price written outside range
      if (this.price < this.min || this.max < this.price) {
        return false;
      }
      // Total cost outside range
      if (
        this.total < this.min * this.quantity * this.conversionRate ||
        this.max * this.quantity * this.conversionRate < this.total
      ) {
        return false;
      }
      return true;
    },
    round(num) {
      return Math.trunc(num * 100) / 100;
    },
    getPriceError() {
      if (!this.withinPriceRange()) {
        return this.$t('price_between', {
          min: this.$display.money.money(this.min, this.originalCurrency, 2),
          max: this.$display.money.money(this.max, this.originalCurrency, 2),
        });
      }
    },
    getPriceHelp() {
      if (!this.withinPriceRange()) {
        return '';
      }
      return this.$t('price_between', {
        min: this.$display.money.money(this.min, this.originalCurrency, 2),
        max: this.$display.money.money(this.max, this.originalCurrency, 2),
      });
    },
    getQuantityHelp() {
      return this.$t('asset_quantity_available', {
        quantity: this.maxQuantityToSell,
      });
    },
    handleSymbolRemove() {
      this.$emit('onSymbolRemove');
      this.resetModalState();
      this.focusTickerSearchField = true;
    },
    getOperationButtonStyle(operationType) {
      if (this.formValues.operationType == operationType) {
        return 'bg-primary-1 border-primary text-primary border-2';
      } else {
        return 'border';
      }
    },
    handleOperationSwitch(operation) {
      if (operation === 'Buy') {
        this.formValues.operationType = 'Buy';
        this.resetModalState();
        this.removeSymbolFromPortfolioOperation();
      } else if (operation === 'Sell') {
        this.formValues.operationType = 'Sell';
        this.resetModalState();
        this.removeSymbolFromPortfolioOperation();
      }
    },
    handlePortfolioChange(portfolioName) {
      this.$emit('onPortfolioChange', portfolioName, () =>
        this.mitigatePriceCallback()
      );
    },
  },
};
