
import ChevronIcon from '~/components/ui/icons/ChevronIcon.vue';
import HelpIcon from '~/components/ui/icons/HelpIcon.vue';
import MessageIcon from '~/components/ui/icons/MessageIcon.vue';

export default {
  name: 'HelpLink',
  components: {
    ChevronIcon,
    HelpIcon,
    MessageIcon,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    unwrap: {
      // Whether this is a direct link or text to unwrap
      type: Boolean,
      default: false,
    },
    unwrapText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      opened: false,
      unwrapTextP1: '',
      unwrapTextP2: '',
    };
  },
  created() {
    if (this.unwrap) {
      if (this.unwrapText.includes(this.link)) {
        const split = this.unwrapText.split(this.link);
        this.unwrapTextP1 = split[0];
        this.unwrapTextP2 = split[1];
      } else {
        this.unwrapTextP1 = this.unwrapText;
        this.unwrapTextP2 = '';
      }
    }
  },
  methods: {
    onClick() {
      if (!this.unwrap) {
        window.open(this.link, '_blank');
      } else {
        this.opened = !this.opened;
      }
    },
    getChevronStyle() {
      if (!this.unwrap) {
        return '';
      } else {
        if (this.opened) {
          return 'transform: rotateZ(-90deg)';
        } else {
          return 'transform: rotateZ(90deg)';
        }
      }
    },
  },
};
