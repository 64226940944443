export default {
  // Top Gainers
  getTopGainerPortfolios(context) {
    context.commit('GET_TOP_GAINER_PORTFOLIOS_INIT');
    return this.dispatch('api/getPortfolioTopGainers', {
      limit: context.state.topGainerPortfoliosPagination.limit,
      page: context.state.topGainerPortfoliosPagination.page,
    })
      .then((res) => {
        context.commit('GET_TOP_GAINER_PORTFOLIOS_SUCCESS', res.data.data);
      })
      .catch((err) => {
        context.commit('GET_TOP_GAINER_PORTFOLIOS_ERROR', err);
      });
  },
  getNextTopGainerPortfolios(context) {
    context.commit('GET_NEXT_GAINER_PORTFOLIOS_INIT');
    return this.dispatch('api/getPortfolioTopGainers', {
      limit: context.state.topGainerPortfoliosPagination.limit,
      page: context.state.topGainerPortfoliosPagination.page,
    })
      .then((res) => {
        context.commit('APPEND_TOP_GAINER_PORTFOLIOS', res.data.data);
      })
      .catch((err) => {
        context.commit('GET_NEXT_TOP_GAINER_PORTFOLIOS_ERROR', err);
      });
  },
  likeTopGainerPortfolio(context, payload) {
    return this.dispatch('api/likePortfolio', payload).then((res) => {
      context.commit('LIKE_TOP_GAINER_PORTFOLIO_SUCCESS', {
        id: payload,
        socials: res.data,
      });
    });
    // TODO : handle error
  },

  favoriteTopGainerPortfolio(context, payload) {
    return this.dispatch('api/favoritePortfolio', payload).then((res) => {
      context.commit('FAVORITE_TOP_GAINER_PORTFOLIO_SUCCESS', {
        id: payload,
        socials: res.data,
      });
    });
    // TODO : handle error
  },

  // Followed Users Top Gainers
  getFollowedUsersTopGainerPortfolios(context) {
    context.commit('GET_FOLLOWED_USERS_TOP_GAINER_PORTFOLIOS_INIT');
    return this.dispatch('api/getPortfolioTopGainersFollowed', {
      limit: context.state.followedUsersTopGainerPortfoliosPagination.limit,
      page: context.state.followedUsersTopGainerPortfoliosPagination.page,
    })
      .then((res) => {
        context.commit(
          'GET_FOLLOWED_USERS_TOP_GAINER_PORTFOLIOS_SUCCESS',
          res.data.data
        );
      })
      .catch((err) => {
        context.commit('GET_FOLLOWED_USERS_TOP_GAINER_PORTFOLIOS_ERROR', err);
      });
  },
  getNextFollowedUsersTopGainerPortfolios(context) {
    context.commit('GET_NEXT_FOLLOWED_USERS_TOP_GAINER_PORTFOLIOS_INIT');
    return this.dispatch('api/getPortfolioTopGainersFollowed', {
      limit: context.state.followedUsersTopGainerPortfoliosPagination.limit,
      page: context.state.followedUsersTopGainerPortfoliosPagination.page,
    })
      .then((res) => {
        context.commit(
          'APPEND_NEXT_FOLLOWED_USERS_TOP_GAINER_PORTFOLIOS',
          res.data.data
        );
      })
      .catch((err) => {
        context.commit(
          'GET_NEXT_FOLLOWED_USERS_TOP_GAINER_PORTFOLIOS_ERROR',
          err
        );
      });
  },
  likeFollowedUsersTopGainerPortfolio(context, payload) {
    return this.dispatch('api/likePortfolio', payload).then((res) => {
      context.commit('LIKE_FOLLOWED_USERS_TOP_GAINER_PORTFOLIO_SUCCESS', {
        id: payload,
        socials: res.data,
      });
    });
    // TODO : handle error
  },

  favoriteFollowedUsersTopGainerPortfolio(context, payload) {
    return this.dispatch('api/favoritePortfolio', payload).then((res) => {
      context.commit('FAVORITE_FOLLOWED_USERS_TOP_GAINER_PORTFOLIO_SUCCESS', {
        id: payload,
        socials: res.data,
      });
    });
    // TODO : handle error
  },
};
